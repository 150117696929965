import React from 'react';
import styles from '../StaffForm.module.scss';
import ButtonMTS from '../../../_ui/ButtonMTS/ButtonMTS';

type Props = {
  isValid: boolean;
};

const BottomMenuAddDealer = (props: Props): JSX.Element => {
  return (
    <div
      className={styles.StaffForm__ButtonMenu}
      data-testid="add-dealer-button-save"
    >
      <ButtonMTS type="submit" additionalstyle={styles.StaffForm__ButtonSave}>
        Добавить сотрудника
      </ButtonMTS>
    </div>
  );
};

export default BottomMenuAddDealer;
