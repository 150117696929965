import React from 'react';
import styles from './ButtonClear.module.scss';

type Props = {
  onClick: () => void;
  size: 'small' | 'medium' | 'big';
  color?: 'white';
  testid?: string;
  disable?: boolean;
};

const ButtonClear = (props: Props): JSX.Element => {
  const chooseCrossSize = (size) => {
    switch (size) {
      case 'small':
        return styles.cross_S;
      case 'medium':
        return styles.cross_M;
      case 'big':
        return styles.cross_L;
      default:
        return styles.cross_M;
    }
  };

  const chooseColor = (color) => {
    switch (color) {
      case 'white':
        return styles.color_white;
      default:
        return styles.color_black;
    }
  };

  return (
    <button
      className={styles.button}
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
      tabIndex={-1}
      type="button"
      data-testid={props.testid ? props.testid : 'buttonClear'}
      disabled={props.disable}
    >
      <i
        className={`i-dp-close  ${chooseCrossSize(props.size)} ${chooseColor(
          props.color
        )} ${styles.cross} ${props.disable ? styles.color_disabled : ''} `}
      />
    </button>
  );
};

export default ButtonClear;
