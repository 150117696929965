export const checkSameDigits = (value: string) => {
  const phone = value
    .toString()
    .replace(/[^0-9]/g, '')
    .substring(1)
    .split('');
  const duplicates = phone.filter((number, index, numbers) => {
    return numbers.indexOf(number) !== index;
  });
  if (duplicates.length > 8) {
    return 'Номер не может состоять из одинаковых цифр';
  }
};
