import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './StaffFormPortal.module.scss';
import { getConfig } from './../../../api/ConfigApi';

import { Props } from '../../_forms/StaffForm/StaffFormOld';
import StaffFormOld from '../../_forms/StaffForm/StaffFormOld';
import StaffFormNew from '../../_forms/StaffForm/StaffFormNew';

const StaffFormPortal = (props: Props): JSX.Element => {
  const [useNewRoleModel, setUseNewRoleModel] = useState(false);
  void getConfig().then((res) =>
    setUseNewRoleModel(res.useNewRoleModel === true)
  );

  const staffFormPortal = document.getElementById('staffForm');

  if (!staffFormPortal) return <div />;

  return ReactDOM.createPortal(
    <div
      className={`${styles.StaffFormPortal} ${
        props.status ? styles.StaffFormPortal_Relative : ''
      }`}
    >
      {useNewRoleModel ? (
        <StaffFormNew
          reload={props.reload}
          closeForm={props.closeForm}
          status={props.status}
          dealerLogin={props.dealerLogin}
          organization={props.organization}
          dealers={props.dealers}
        />
      ) : (
        <StaffFormOld
          reload={props.reload}
          closeForm={props.closeForm}
          status={props.status}
          dealerLogin={props.dealerLogin}
          organization={props.organization}
          dealers={props.dealers}
        />
      )}
    </div>,
    staffFormPortal
  );
};

export default StaffFormPortal;
