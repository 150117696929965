import { unstore } from '../../api/StorageService';
import { SellPoint, Organization } from '../../types/Organization';
import { UserState, UserReducerActions, UserActions } from './types/userTypes';

const initialState: UserState = {
  user: undefined,
  authorized: false,
  userSellPoint: unstore(UserReducerActions.USER_SELL_POINT)
    ? (JSON.parse(
        unstore(UserReducerActions.USER_SELL_POINT) || ''
      ) as SellPoint)
    : null,
  userOrganization: unstore(UserReducerActions.USER_ORGANIZATION)
    ? (JSON.parse(
        unstore(UserReducerActions.USER_ORGANIZATION) || ''
      ) as Organization)
    : null,
};

export const userReducer = (
  state = initialState,
  action: UserActions
): UserState => {
  switch (action.type) {
    case UserReducerActions.USER:
      return { ...state, user: action.payload };

    case UserReducerActions.AUTHORIZED:
      return { ...state, authorized: action.payload };

    case UserReducerActions.USER_SELL_POINT:
      return { ...state, userSellPoint: action.payload };

    case UserReducerActions.USER_ORGANIZATION:
      return { ...state, userOrganization: action.payload };

    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return state;
  }
};
