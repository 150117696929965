import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './HeaderLogin.module.scss';
import { useOnClickOutside } from '../../../hooks';
import { GlobalModalContext } from '../../../context/GlobalModalContext';
import { HandbookContext } from '../../../context/HandbookContext';
import { useAxios } from '../../../api/useAxios';
import Rejection from '../../_modals/Rejection/Rejection';
import { getConfig } from '../../../api/ConfigApi';
import { storeDP } from '../../../store/store';
import { UserReducerActions } from '../../../store/reducer/types/userTypes';
import { AxiosError } from 'axios';
import { logout as oidcLogout } from '../ProtectApp/ProtectApp';
import { OrderListReducerActions } from '../../../store/reducer/types/orderListTypes';

const HeaderLogin = (): JSX.Element => {
  const [rejectionButtonSwitch, setRejectionButtonSwitch] =
    useState<boolean>(false);
  const [newLogoutSwitch, setNewLogoutSwitch] = useState<boolean>(false);
  const [useNewRoleModel, setUseNewRoleModel] = useState<boolean>(false);

  void getConfig().then((config) => {
    setRejectionButtonSwitch(config.rejectionButtonSwitch === true);
    setNewLogoutSwitch(config.newLogout === true);
    setUseNewRoleModel(config.useNewRoleModel === true);
  });

  const { user, userSellPoint } = storeDP.getState().userReducer;

  const { sellPoints: organizationSellPoints } = useContext(HandbookContext);
  const { openModal } = useContext(GlobalModalContext);
  const [opened, setOpened] = useState(false);
  const [problemOpened, setProblemOpened] = useState(false);
  const { send: logout, ...logoutAxios } = useAxios<{ result: true }>(
    '/account/logout',
    'post'
  );
  const logout2Axios = useAxios<{ result: true }>('../auth/account/login');

  const logoutClick = (local = false) => {
    newLogoutSwitch
      ? oidcLogout(local).catch((ae: AxiosError) => {
          if (ae.response?.status === 404) logout();
        })
      : logout();
  };

  useEffect(() => {
    if (logout2Axios.error?.status === 404) {
      logout();
    }
  }, [logout2Axios]);

  useEffect(() => {
    if (!logoutAxios?.error && logoutAxios?.fullResponse) {
      const url = `${
        (logoutAxios.fullResponse.headers as Record<string, string>)['location']
      }`;
      window.location.replace(url);
    }
  }, [logoutAxios]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (opened) setOpened(false);
  });

  const openSellPointModal = () => {
    openModal({ type: 'sellPoint' });
    setOpened(false);
  };

  const userAvailableSellPoints =
    organizationSellPoints?.filter(
      (sp) => user?.sellPoints.indexOf(sp.id) !== -1
    ) ?? [];

  const hasSeveralAvailableSellPoints = userAvailableSellPoints.length > 1;
  const hasSeveralAccounts =
    user && Number(sessionStorage.getItem(user?.login)) > 1;

  const hasRoles = (roles: string[]): boolean => {
    const usersRoles =
      (useNewRoleModel && user?.rights ? user?.rights : user?.roles) || [];

    return roles.some((role) => usersRoles.includes(role));
  };

  const addSingleSellpoint = () => {
    if (hasRoles(['ViewerOrgList', 'ViewerSellpointList', 'ViewerOwnList'])) {
      storeDP.dispatch({
        type: UserReducerActions.USER_SELL_POINT,
        payload: userAvailableSellPoints[0],
      });
    }
  };

  useEffect(() => {
    if (userAvailableSellPoints.length > 0) {
      hasSeveralAvailableSellPoints
        ? openSellPointModal()
        : addSingleSellpoint();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSellPoints]);

  useEffect(() => {
    if (
      userAvailableSellPoints.length === 1 &&
      !storeDP.getState().orderListReducer.sellPoint &&
      !hasSeveralAvailableSellPoints
    ) {
      if (hasRoles(['ViewerOrgList', 'ViewerSellpointList', 'ViewerOwnList'])) {
        storeDP.dispatch({
          type: OrderListReducerActions.SET_SELLPOINT,
          payload: userAvailableSellPoints[0].id,
        });
      }

      if (
        user &&
        !hasRoles(['ViewerOrgList', 'ViewerSellpointList']) &&
        hasRoles(['ViewerOwnList'])
      ) {
        storeDP.dispatch({
          type: OrderListReducerActions.SELECT_USER,
          payload: user?.login,
        });
      }
    }
  }, [userAvailableSellPoints, user]);

  return (
    <div className={styles.Login__Wrapper} ref={ref}>
      <div className={styles.Login__Main}>
        {rejectionButtonSwitch && (
          <button
            className={styles.Login__Denied}
            onClick={() => setProblemOpened(true)}
            data-testid="login-button-reject"
          >
            <span className={styles.Login__Text}>
              Отказ
              <i className="i-dp-reject" style={{ fontSize: '130%' }} />
            </span>
          </button>
        )}

        <button
          className={styles.Login__Login}
          onClick={() => setOpened(!opened)}
          data-testid="login-button-info"
        >
          <span className={styles.Login__Text}>
            {user?.login}{' '}
            <i className="i-dp-user" style={{ fontSize: '130%' }} />
          </span>
        </button>
      </div>

      {opened && (
        <div className={styles.Login__Menu}>
          {hasSeveralAvailableSellPoints && (
            <button
              className={styles.Login__Element}
              onClick={openSellPointModal}
              data-testid="login-button-sell-point"
            >
              Точка продаж ({userSellPoint?.name})
            </button>
          )}

          {hasSeveralAccounts && (
            <button
              className={styles.Login__Element}
              onClick={() => logoutClick(true)}
              data-testid="login-button-change-account"
            >
              Сменить УЗ
            </button>
          )}

          <button
            className={styles.Login__Element}
            onClick={() => logoutClick(false)}
            data-testid="login-button-logout"
          >
            Выйти
          </button>
        </div>
      )}

      {problemOpened && (
        <Rejection onCloseClick={() => setProblemOpened(false)} />
      )}
    </div>
  );
};

export default HeaderLogin;
