import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import MySales from '../MySales/MySales';
import styles from './Navbar.module.scss';
import { getConfig } from '../../../api/ConfigApi';
import { links } from '../../../router/RoutesOld';
import { storeDP } from '../../../store/store';

type Props = {
  onClose?: () => void;
};

const NavbarOld = (props: Props): JSX.Element => {
  //INFO: Switch - MySales
  const [mySalesSwitch, setMySalesSwitch] = useState(false);

  void getConfig().then((config) => {
    setMySalesSwitch(config.mySalesSwitch === true);
  });

  const user = storeDP.getState().userReducer.user;

  const authorizedLinks = links.filter((link) => {
    for (const role of link.roles) {
      if (user?.roles.includes(role)) return true;
    }
    return false;
  });

  return (
    <div className={styles.Navbar}>
      <nav data-testid="navbar">
        <ul className={styles.Navbar__List}>
          {authorizedLinks.map((item) => (
            <NavLink
              key={item.label}
              to={item.path}
              className={styles.Navbar__Link}
              activeClassName={styles.Navbar__Link_active}
              exact={item.exact}
              onClick={props.onClose}
              data-testid="navbar-close"
            >
              {item.label}
              <i className={`i-dp-${item.icon}`} />
            </NavLink>
          ))}
          {mySalesSwitch && <MySales />}
        </ul>
      </nav>
    </div>
  );
};

export default NavbarOld;
