import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoaderMatrix } from '../components/_layout';
import { Roles } from '../types/Roles';
import styles from './Routes.module.scss';
import { storeDP } from '../store/store';

const StaffPage = React.lazy(() => import('../pages/StaffPage'));
const OrderListPage = React.lazy(
  () => import('../pages/OrderListPage/OrderListPage')
);
const NewOrderPage = React.lazy(() => import('../pages/NewOrderPage'));

export const links = [
  {
    path: '/new_order',
    label: 'Создание заявки',
    icon: 'boxadd',
    exact: false,
    roles: [
      Roles.SellerChangeTarifCreate,
      Roles.SellerMultiSaleCreate,
      Roles.SellerSaleCreate,
      Roles.SellerSupplyServiceSaleCreate,
      Roles.SellerTechChangeCreate,
      Roles.SellerUpsaleCreate,
    ],
    component: <NewOrderPage />,
  },
  {
    path: '/orders',
    label: 'Список заявок',
    icon: 'list',
    exact: false,
    roles: [
      Roles.ViewerOwnList,
      Roles.ViewerOrgList,
      Roles.ViewerSellpointList,
    ],
    component: <OrderListPage />,
  },
  {
    path: '/staff',
    label: 'Сотрудники',
    icon: 'staff',
    exact: true,
    roles: [Roles.DealerAdministrator],
    component: <StaffPage />,
  },
];

export const RoutesOld = (): JSX.Element => {
  const user = storeDP.getState().userReducer.user;
  const authorizedLinks = links.filter((link) => {
    for (const role of link.roles) {
      if (user) {
        if (user.roles.includes(role)) return true;
      } else {
        return false;
      }
    }
    return false;
  });

  return (
    <Suspense
      fallback={
        <div className={styles.loader}>
          <LoaderMatrix />
        </div>
      }
    >
      <Switch>
        {authorizedLinks.map((link, index) => {
          return (
            <Route key={index} path={link.path}>
              {link.component}
            </Route>
          );
        })}
        <Redirect to={authorizedLinks[0].path} />
      </Switch>
    </Suspense>
  );
};
