/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { storeDP } from './store/store';

export type Apps = {
  name: string;
  url: string;
  activeWhen: string;
  readyToMount?: boolean;
  props?: any;
}[];

ReactDOM.render(
  <React.StrictMode>
    <Provider store={storeDP}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
