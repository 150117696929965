/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './widget.module.scss';

type Props = {
  openWidget: boolean;
  setOpenWidget: (data: boolean) => void;
  token: string;
};

const Widget = (props: Props): JSX.Element => {
  const modalRoot = document.getElementById('modal-root') as HTMLElement;

  const { openWidget, setOpenWidget, token } = props;

  window.addEventListener(
    'message',
    (event) => {
      if (event.data.error) console.log(event.data.error);
      if (event.data.orderNumber) console.log(event.data.Number);
      return;
    },
    false
  );

  const widgetLink = process.env.REACT_APP_VG_LINK;
  const wigetToken = token;

  useEffect(() => {
    const iframe = document.getElementById('widgetMTS') as HTMLIFrameElement;

    const message = {
      token: wigetToken,
      actions: [{ type: 'close_widget', value: false }],
      address: 'Энгельс',
      partnerName: 'Виджет с формы ДП',
      useGeolocation: true,
    };

    const loadIframe = () =>
      iframe.contentWindow?.postMessage(
        `${JSON.stringify(message)}`,
        `${widgetLink ? `${window.location.origin}${widgetLink}` : ''}`
      );
    iframe && setTimeout(() => loadIframe(), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWidget]);

  const readMessage = (event: MessageEvent) => {
    if (event.data === false) setOpenWidget(event.data as boolean);
  };
  window.addEventListener('message', readMessage, false);

  return createPortal(
    <>
      {openWidget && widgetLink && (
        <iframe
          className={styles.iframe}
          src={`${widgetLink}`}
          title="Widget-MTS"
          allowFullScreen
          id="widgetMTS"
          allow="geolocation"
        />
      )}
    </>,
    modalRoot
  );
};

export default Widget;
