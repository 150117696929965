import { Agreement } from '../../../types/Agreements';

export enum MicrofrontReducerActions {
  SET_AGREEMENTS = 'SET_AGREEMENTS',
}

interface SetAgreements {
  type: MicrofrontReducerActions.SET_AGREEMENTS;
  payload: Agreement[];
}

export type MicrofrontActions = SetAgreements;

export interface MicrofrontState {
  agreements: Agreement[];
}
