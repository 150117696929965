import React, { useState, useEffect, useRef } from 'react';
import styles from './IncidentMessage.module.scss';
import { ApiErrorData } from '../../../../../types';
import { storeDP } from '../../../../../store/store';
import { logErrorMatomo } from '../../../../../utils/matomo';

type Props = {
  url: string;
  error: ApiErrorData;
  size: 'Small' | 'Medium';
  showTraceId?: boolean;
  showError?: boolean;
};

export const copyToClipboard = (text: string) => {
  void window.navigator.clipboard.writeText(text);
};

const IncidentMessage = (props: Props): JSX.Element => {
  const { error, url, size, showTraceId, showError } = props;

  const { user } = storeDP.getState().userReducer;

  const selectMessageSize = (size: string) => {
    switch (size) {
      case 'Small':
        return styles.IncidentMessage__UserMessages_Small;

      case 'Medium':
        return styles.IncidentMessage__UserMessages_Medium;

      default:
        return '';
    }
  };

  const errorMessages = error.errors && Object.values(error.errors)[0];

  const errors =
    errorMessages && errorMessages.length > 0 ? errorMessages.join(', ') : '';

  const [copyTraceIdComplete, setCopyTraceIdComplete] = useState(false);
  const [copyHelpdeskURL, setCopyHelpdeskURL] = useState(false);
  const [errorTime, setErrorTime] = useState<string>('');

  const copyTraceId = () => {
    copyToClipboard(
      `Ошибка: ${errors}
  TraceID: ${props.error.traceId}
  Login пользователя: ${user ? user?.login : ''}
  Время ошибки: ${errorTime}
        `
    );
    setCopyTraceIdComplete(true);
  };

  const copyHelpURL = () => {
    copyToClipboard(url);
    setCopyHelpdeskURL(true);
  };

  const debounceTimer = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );

  useEffect(() => {
    logErrorMatomo(
      props.error.traceId,
      `${props.error.status}: ${props.error.title} (${
        props.error.detail || ''
      })`
    );

    setErrorTime(new Date().toString());
  }, [props.error]);

  useEffect(() => {
    if (copyTraceIdComplete) {
      debounceTimer.current = setTimeout(
        () => setCopyTraceIdComplete(false),
        1500
      );
    }

    if (copyHelpdeskURL) {
      debounceTimer.current = setTimeout(() => setCopyHelpdeskURL(false), 1500);
    }
  }, [copyTraceIdComplete, copyHelpdeskURL]);
  return (
    <div
      className={`${styles.IncidentMessage__UserMessages} ${selectMessageSize(
        size
      )}`}
      data-testid="incidentMessage"
    >
      <div className={styles.IncidentMessage__Header}>
        {showTraceId && (
          <span
            className={styles.IncidentMessage__Header__TraceID}
            data-testid="incidentMessage-traceId"
          >
            TraceId: {error.traceId}
          </span>
        )}
        <div className={styles.IncidentMessage__Error}>
          <i className={`i-dp-warning ${styles.IncidentMessage__Warning}`} />
          {showError && (
            <span
              className={styles.IncidentMessage__Error__Text}
              data-testid="incidentMessage-word_error"
            >
              Ошибка
            </span>
          )}
        </div>
      </div>

      <p className={styles.IncidentMessage__UserMessage}>
        Оформите инцидент по ссылке
        <div className={styles.IncidentMessage__Copy}>
          <a
            className={styles.IncidentMessage__Link}
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            HelpDesk
          </a>
          {copyHelpdeskURL ? (
            <i
              className={`i-dp-done ${styles.IncidentMessage__Complete__Icon}`}
            />
          ) : (
            <button data-testid="copy-request-id" onClick={copyHelpURL}>
              <i className={`i-dp-copy ${styles.order__icon}`} />
            </button>
          )}
        </div>
      </p>
      <p
        className={`${styles.IncidentMessage__UserMessage} ${
          size === 'Small' ? styles.IncidentMessage__UserMessage_Small : ''
        }`}
      >
        При создании инцидента скопируйте и вставьте <span>в поле </span>
        <span className={styles.IncidentMessage__UserMessages__TraceID}>
          Описание{' '}
        </span>
        данные ошибки:
      </p>
      <div
        className={`${styles.IncidentMessage__Copy} ${styles.IncidentMessage__Copy__Error}`}
      >
        <span
          className={`${styles.IncidentMessage__UserMessages__TraceID}`}
          onClick={copyTraceId}
          data-testid="incidentMessage-copyButton"
        >
          Содержимое ошибки
          {copyTraceIdComplete ? (
            <i
              className={`i-dp-done ${styles.IncidentMessage__Complete__Icon}`}
              data-testid="incidentMessage-copyButton_doneIcon"
            />
          ) : (
            <i
              className={`i-dp-copy ${styles.IncidentMessage__Copy__Icon}`}
              data-testid="incidentMessage-copyButton_copyIcon"
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default IncidentMessage;
