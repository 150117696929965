/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import { ApiErrorData } from '../types';
import { v4 as uuidv4 } from 'uuid';

type UseAxiosReturn<R, E> = {
  send: (data?: Record<string, any>) => void;
  response: R | undefined;
  fullResponse: AxiosResponse<R> | undefined;
  error: AxiosResponse<E> | undefined;
  loading: boolean;
  cancel: () => void | undefined;
};

export type UseAxiosState<R, E> = {
  response: R | undefined;
  fullResponse: AxiosResponse<R> | undefined;
  error: AxiosError<E> | undefined;
  loading: boolean;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DEV,
  paramsSerializer: {
    indexes: null,
  },
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
  timeout: 180000,
});

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig<any>) => {
    config.headers = Object.assign(config['headers'] || {}, {
      'X-Request-Id': uuidv4(),
    }); //!!!!
    return config;
  },
  (err) => {
    console.error('Axios request:', err);
    return Promise.reject(err);
  }
);

export const tokenStore = {} as { token?: string };

axiosInstance.interceptors.request.use((config: AxiosRequestConfig<any>) => {
  if (tokenStore.token) {
    config.headers = Object.assign(config['headers'] || {}, {
      Authorization: `Bearer ${tokenStore.token}`,
    });
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => {
    if (!res.data && res.config.url === '/account/login') {
      // FIXME нужен ответ бекенда
      return { ...res, data: { result: true } };
    }

    return res;
  },
  (err) => {
    console.error('Axios:', err);
    return Promise.reject(err);
  }
);

export const useAxios = <R, E = ApiErrorData>(
  url: string,
  method: Method = 'get',
  abortRequest?: (data: AbortController) => void,
  timeout?: number
): UseAxiosReturn<R, E> => {
  const params = useRef({ url, method, timeout });
  const [axiosState, setAxiosState] = useState<UseAxiosState<R, E>>({
    response: undefined,
    fullResponse: undefined,
    error: undefined,
    loading: false,
  });

  const axiosSource = useRef<AbortController | undefined>(undefined);

  const fetchData = async (data?: Record<string, unknown>) => {
    const controller = new AbortController();
    axiosSource.current = controller;
    const config: AxiosRequestConfig = {
      ...params.current,
      signal: controller.signal,
    };
    try {
      abortRequest && abortRequest(controller);
      setAxiosState((prev) => ({ ...prev, loading: true, error: undefined }));

      if (params.current.method === 'get') {
        if (data) {
          if (data.url) {
            config.url = data.url as string;
            const { url, ...newData } = data;
            config.params = newData;
          } else {
            config.params = data;
          }
        }
      } else {
        config.data = data;
      }
      const result = await axiosInstance.request<
        Record<string, any>,
        AxiosResponse<R>
      >(config);
      const response = result.data;
      const fullResponse = result as AxiosResponse;
      setAxiosState((prev) => ({ ...prev, response, fullResponse }));
    } catch (err) {
      console.error(err);

      const error = err as AxiosError<E>;

      setAxiosState((prev) => ({ ...prev, error }));
    } finally {
      setAxiosState((prev) => ({ ...prev, loading: false }));
    }
  };

  const send = useCallback(fetchData, []);

  useEffect(() => {
    return () => {
      void axiosSource.current?.abort();
    };
  }, []);

  return useMemo(
    () => ({
      ...axiosState,
      send,
      cancel: () => axiosSource.current?.abort(),
      error: generateError<E>(axiosState.error),
    }),
    [axiosState, send]
  );
};

function generateError<E>(err: AxiosError<E> | undefined) {
  if (!err) return;
  if (err.response) {
    return err.response;
  } else {
    return {
      status: 0,
      statusText: 'Unknown Error',
      headers: undefined,
      config: err.config,
      data: {
        title: err.message,
        status: 0,
        type: '',
        traceId: '',
        message: err.message,
      },
    } as unknown as AxiosResponse<E>;
  }
}
