import { createContext, useEffect, useState } from 'react';
import { useAxios } from '../api/useAxios';
import { Organization, SellPoint } from '../types/Organization';
import { getConfig } from '../api/ConfigApi';
import { storeDP } from '../store/store';
import { UserReducerActions } from '../store/reducer/types/userTypes';

export type UserOrganization = Organization | null;

interface Handbook {
  name: string;
  sellPoints: SellPoint[];
  roles: string[];
  roles2?: string[];
}

type Props = {
  children: JSX.Element;
};

const initialHandbook = {
  name: '',
  sellPoints: [],
  roles: [],
};

const HandbookContext = createContext<Handbook>(initialHandbook);

const HandbookProvider = (props: Props): JSX.Element => {
  const [useUseNewRoleModel, setUseNewRoleModel] = useState(false);

  void getConfig().then((res) =>
    setUseNewRoleModel(res.useNewRoleModel === true)
  );

  const axiosOrganization = useAxios<Organization>('/account/organization');
  const { send: sendOrganization, response: responseOrganization } =
    axiosOrganization;

  useEffect(() => {
    sendOrganization();
  }, [sendOrganization]);

  useEffect(() => {
    const organization = axiosOrganization?.response;

    if (organization) {
      storeDP.dispatch({ type: UserReducerActions.USER_ORGANIZATION, payload: organization });
    }

  }, [axiosOrganization.response]);

  const handbook = {
    name: responseOrganization?.name,
    sellPoints: responseOrganization?.sellPoints,
    roles:
      useUseNewRoleModel && responseOrganization?.roles2
        ? responseOrganization.roles2
        : responseOrganization?.roles,
  } as Handbook;

  const contextValue = {
    ...handbook,
  };

  return (
    // @ts-ignore
    <HandbookContext.Provider value={contextValue}>
      {props.children}
    </HandbookContext.Provider>
  );
};

export { HandbookContext, HandbookProvider };
