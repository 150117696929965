import { SelectCurrency, SelectPeriod } from '../types/Currency&Period';

export const selectCurrency = (data: SelectCurrency): void => {
  switch (true) {
    case data.currency.toLocaleUpperCase() === 'RUB':
    case data.currency.toString() === '810':
      data.setCurrency('₽');
      break;
  }
};

/**
 * @deprecated
 */
export const selectPeriodFull = (data: SelectPeriod): void => {
  if (data.periodLength === 1)
    switch (data.period) {
      case 'month':
        data.setPeriod('месяц');
        break;
      case 'year':
        data.setPeriod('год' as string);
        break;
      case 'day':
        data.setPeriod('день');
        break;
    }
  if (data.periodLength && data.periodLength >= 2 && data.periodLength <= 4)
    switch (data.period) {
      case 'month':
        data.setPeriod('месяца');
        break;
      case 'year':
        data.setPeriod('год');
        break;
      case 'day':
        data.setPeriod('дня');
        break;
    }
  if (data.periodLength && data.periodLength >= 5)
    switch (data.period) {
      case 'month':
        data.setPeriod('месяцев');
        break;
      case 'year':
        data.setPeriod('лет');
        break;
      case 'day':
        data.setPeriod('дней');
        break;
    }
};

export const selectPeriod = (data: SelectPeriod): void => {
  switch (true) {
    case data.period.toLocaleUpperCase() === 'MONTH':
      data.setPeriod('мес.');
      break;
    case data.period.toLocaleUpperCase() === 'YEAR':
      data.setPeriod('год' as string);
      break;
    case data.period.toLocaleUpperCase() === 'DAY':
      data.setPeriod('дн.');
      break;
    case data.period === '':
      data.setPeriod('');
  }
};

export const translatePeriod = (period?: string): string | undefined => {
  return (
    (
      {
        day: 'дн.',
        month: 'мес.',
        year: 'год',
      } as Record<string, string>
    )[period?.toLocaleLowerCase() ?? 'month'] || period
  );
};

export const translateFullPeriod = (
  period?: string,
  periodLength?: number,
  firstPeriodApplied?: boolean,
  isNextPeriod?: boolean,
  periodIndex?: number
): string | undefined => {
  const translatedPeriod = translatePeriod(period);

  let correctedPeriodLength = '1 ';
  if (periodLength && periodLength !== 1) {
    if (!firstPeriodApplied && isNextPeriod) {
      correctedPeriodLength = `${periodLength} `;
    } else if (!firstPeriodApplied && !isNextPeriod) {
      correctedPeriodLength = `1 - ${periodLength} `;
    } else if (firstPeriodApplied) {
      correctedPeriodLength = `2 - ${periodLength} `;
    }
  } else if(periodLength === 1 && periodIndex) {
    correctedPeriodLength = `${periodLength + periodIndex} `;
  }
  return translatedPeriod
    ? `${correctedPeriodLength}${translatedPeriod}`
    : undefined;
};

export const translateFullNextPeriod = (
  period?: string,
  periodLength?: number,
  firstPeriodApplied?: boolean
): string | undefined => {
  return translateFullPeriod(
    period,
    periodLength ? periodLength + 1 : periodLength,
    firstPeriodApplied,
    true
  );
};
