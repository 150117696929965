import React, { useEffect, useState } from 'react';
import { useAxios, tokenStore } from '../../../api/useAxios';
import AuthError from '../AuthError/AuthError';
import { ApiErrorData, User } from '../../../types';
import LoaderMatrix from '../LoaderMatrix/LoaderMatrix';
import { initMatomo } from '../../../utils/matomo';
import { storeDP } from '../../../store/store';
import { UserReducerActions } from '../../../store/reducer/types/userTypes';
import { AxiosResponse } from 'axios';
import { getLoginUrl } from '../../../api/authClient';
import { getConfig } from '../../../api/ConfigApi';
import {
  UserManager,
  UserManagerSettings,
  User as OidcUser,
  ErrorResponse,
} from 'oidc-client-ts';

type Props = {
  children: JSX.Element;
};

type OidcState = {
  location: string;
};

const hasAuthParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get('code') && searchParams.get('state')) {
    return 'code';
  }
  if (
    (searchParams.get('error') && searchParams.get('state')) ||
    searchParams.get('error_code')
  ) {
    return 'error';
  }
};

const oidcSettings = {
  authority: process.env.REACT_APP_AUTH_DEV,
  client_id: 'dp',
  response_type: 'code',
  scope: 'openid dealerPortal email offline_access profile',
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTimeInSeconds: 30,
  redirect_uri: new URL('/', window.location.href).toString(),
  post_logout_redirect_uri: new URL('/', window.location.href).toString(),
} as UserManagerSettings;

const userManager = new UserManager(oidcSettings);

export const logout = async function (local = false) {
  const user = await userManager.getUser();

  if (user) {
    const extraQueryParams: Record<string, string> = {};
    if (local) {
      extraQueryParams['logoutType'] = 'local';
    }

    await userManager.signoutRedirect({
      id_token_hint: user.id_token,
      extraQueryParams,
    });
  }
};

const toArray = function(value: any) : Array<any> {
  if (Array.isArray(value)) {
    return value;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [value];
};

const storeTokenAndUpdateAccount = function (u: OidcUser) {
  tokenStore.token = u?.id_token;

  const profile = u?.profile;
  if (profile) {
    const user =
      {
        login: profile['sub'],
        email: profile['email'],
        phone: profile['phone_number'],
        name: profile['urmd:FIO'],
        roles: toArray(profile['role']) as string[],
        roles2: toArray(profile['urmd:Role']),
        rights: toArray(profile['role']),
        sellPoints: toArray(profile['urmd:SellPointId']) as string[]
      } as User;

    storeDP.dispatch({ type: UserReducerActions.USER, payload: user });
  }
};

userManager.events.addUserLoaded(storeTokenAndUpdateAccount);

const self = { oidcAuthFlow: () => Promise.resolve() };

//  при ошибке обновления токена, сбрасываем юзера и запускаем вход заново
userManager.events.addSilentRenewError(async (e) => {
  storeDP.dispatch({ type: UserReducerActions.AUTHORIZED, payload: false });
  await userManager.removeUser();
  await self.oidcAuthFlow();
});

const ProtectApp = (props: Props): JSX.Element => {
  const [oidcLoginSwitch, setOidcLoginSwitch] = useState<boolean | null>(null);
  const { send: loadLogin, ...loginAxios } = useAxios<{ result: true }>(
    '/account/login',
    'post'
  );
  const { send: loadAccount, ...accountAxios } = useAxios<User>('/account');
  const [wssoUrl, setWssoUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [oidcError, setOidcError] = useState<ApiErrorData>();

  void getConfig().then((config) => {
    setOidcLoginSwitch(config.newLogout === true);
  });

  const oidcAuthFlow = (self.oidcAuthFlow = async () => {
    const authParams = hasAuthParams();
    if (authParams === 'error') {
      await userManager.removeUser();
      const params = new URLSearchParams(window.location.search);
      const errorCode = params.get('error_code') ?? 'UnknownErr';
      const errorDescr = params.get('error_description') ?? 'Unknown error';
      const errorId = params.get('error_id') ?? 'empty';
      setOidcError({
        traceId: errorId,
        title: errorCode,
        detail: errorDescr,
        status: 200,
        type: errorCode,
      });
      return;
    }

    let user: OidcUser | null = null;
    if (authParams === 'code') {
      user = (await userManager.signinCallback()) as OidcUser;
      if (typeof user !== 'undefined') {
        window.location.replace((user.state as OidcState).location);
      }
    } else {
      user = await userManager.getUser();
      if (user?.expired === true) {
        try {
          user = await userManager.signinSilent();
        } catch (errorResponse) {
          if ((errorResponse as ErrorResponse).message === 'invalid_grant') {
            await userManager.removeUser();
            user = null;
          }
        }
      }

      if (!user) {
        setErrorMessage('Требуется выполнить вход');

        if (
          !window.location.href.includes('authorization') &&
          !window.location.href.includes('widget')
        ) {
          storeDP.dispatch({
            type: UserReducerActions.AUTHORIZED,
            payload: false,
          });

          void userManager.signinRedirect({
            state: { location: window.location.href } as OidcState,
            extraQueryParams: {
              selectUserUrl: `${window.location.origin}/authorization`,
            },
          });
        }
      }
    }

    if (user) {
      storeTokenAndUpdateAccount(user);
      void loadAccount();
    }
  });

  useEffect(() => {
    oidcLoginSwitch && void oidcAuthFlow();
  }, [oidcLoginSwitch]);

  const auth = function (
    responseError: AxiosResponse<ApiErrorData, any>,
    location?: string
  ) {
    if (responseError?.status === 401) {
      const url = location || getLoginUrl();

      setErrorMessage('Требуется выполнить вход');

      if (process.env.NODE_ENV === 'development') {
        setWssoUrl(url);
      }

      if (!window.location.href.includes('widget')) {
        storeDP.dispatch({
          type: UserReducerActions.AUTHORIZED,
          payload: false,
        });

        goToAuthPage(url);
      }
    }

    if (responseError?.status > 401) {
      setErrorMessage('Отказано в доступе, либо сервис недоступен');
      storeDP.dispatch({ type: UserReducerActions.AUTHORIZED, payload: false });
    }
  };

  useEffect(() => {
    if (oidcLoginSwitch !== false) return;

    void loadAccount();
    storeDP.dispatch({ type: UserReducerActions.AUTHORIZED, payload: true });
  }, [loadAccount, oidcLoginSwitch]);

  // old auth flow
  useEffect(() => {
    if (loginAxios.error) {
      let location =
        accountAxios.error &&
        accountAxios.error.headers &&
        (accountAxios.error.headers as Record<string, string>)['location'];
      location += `&goto=${window.location.href}`;
      void auth(loginAxios.error, location);
    } else if (loginAxios.response) {
      void loadAccount();
    }
  }, [loginAxios.error, loginAxios.response]);

  useEffect(() => {
    if (!accountAxios.error || oidcLoginSwitch !== false) return;

    const location =
      accountAxios.error &&
      accountAxios.error.headers &&
      (accountAxios.error.headers as Record<string, string>)['location'];
    if (accountAxios.error.status === 401 && location) {
      // old auth flow
      void loadLogin(); // goto loginAxios effect
    } else {
      void auth(accountAxios.error, location);
    }
  }, [loadAccount, accountAxios.error, oidcLoginSwitch]);

  useEffect(() => {
    const user = accountAxios.response;
    if (user) {
      storeDP.dispatch({ type: UserReducerActions.USER, payload: user });
      storeDP.dispatch({ type: UserReducerActions.AUTHORIZED, payload: true });

      void initMatomo(user.login);
    }
  }, [accountAxios.response]);

  if (loginAxios.loading || accountAxios.loading)
    return (
      <div style={fullPageStyle}>
        <LoaderMatrix />
      </div>
    );

  const { user, authorized } = storeDP.getState().userReducer;

  if (!user || !authorized)
    return (
      <div style={fullPageStyle}>
        {(loginAxios.error || accountAxios.error || oidcError) && (
          <AuthError
            errorData={
              loginAxios.error?.data || accountAxios.error?.data || oidcError
            }
            errorMessage={errorMessage || oidcError?.detail}
            url={wssoUrl}
          />
        )}
      </div>
    );

  return props.children;
};

const goToAuthPage = (url: string) => {
  if (process.env.NODE_ENV === 'development') {
    window.open(url, '_blank');
  } else {
    window.location.replace(url);
  }
};

export default ProtectApp;

const fullPageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  background: '#f4f1e8',
};
