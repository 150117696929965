import {
  ProductOfferingOption,
  CharacteristicOption,
  OptionAvalibleActions,
} from '../types/Basket';

export const isProductOffering = (option: {
  type: string;
}): option is ProductOfferingOption => option?.type === 'ProductOfferingOption';

export const isCharacteristic = (option: {
  type: string;
}): option is CharacteristicOption => option?.type === 'CharacteristicOption';

export const isEquipment = (option: ProductOfferingOption): boolean => {
  if (option.offeringRef) {
    return (
      Object.keys(option.offeringRef.categories ?? {}).find(
        (c) => c === 'b2c_special_fix_device'
      ) !== undefined
    );
  }
  return false;
};

export const isEquipmentAdd = (option: ProductOfferingOption): boolean => {
  if (
    Object.keys(option.offeringRef.categories ?? {}).includes(
      'b2c_special_fix_device'
    )
  ) {
    return false;
  }

  if (
    Object.keys(option.offeringRef.categories ?? {}).includes(
      'b2c_special_fix_device_add'
    )
  ) {
    return true;
  }

  return false;
};

export const isCinemaOption = (option: ProductOfferingOption): boolean => {
  return (
    Object.keys(option.offeringRef.categories ?? {}).find(
      (c) => c === 'b2c_content_cinema'
    ) !== undefined
  );
};

export const isSmartDevice = (
  option: ProductOfferingOption | CharacteristicOption
): boolean => {
  if (!isProductOffering(option)) {
    return false;
  }
  return (
    Object.keys(option.offeringRef.categories ?? {}).find(
      (c) => c === 'b2c_smart_devices'
    ) !== undefined
  );
};

export const isPremium = (
  option: ProductOfferingOption | CharacteristicOption
): boolean => {
  if (!isProductOffering(option)) {
    return false;
  }

  return (
    Object.keys(option.offeringRef.categories ?? {}).find(
      (c) => c === 'b2c_mobile_premium'
    ) !== undefined
  );
};

export const isNoPremium = (
  option: ProductOfferingOption | CharacteristicOption
): boolean => {
  if (!isProductOffering(option)) {
    return false;
  }

  return (
    Object.keys(option.offeringRef.categories ?? {}).find(
      (c) => c.toLowerCase() === 'nopremiuminproduct'
    ) !== undefined
  );
};

export const filterEquipment = (
  option: ProductOfferingOption | CharacteristicOption
): boolean => {
  if (isProductOffering(option)) {
    if (isEquipment(option)) {
      return true;
    }
  }
  return false;
};

export const filterNotEquipment = (
  option: ProductOfferingOption | CharacteristicOption
): boolean => {
  return (
    (isProductOffering(option) &&
      !isEquipment(option) &&
      !isEquipmentAdd(option)) ||
    isEditableCharacteristic(option)
  );
};

export const isEditableCharacteristic = (
  option: ProductOfferingOption | CharacteristicOption
): boolean => {
  return (
    isCharacteristic(option) &&
    option.characteristic.visible &&
    option.characteristic.isRequired &&
    !option.characteristic.isReadOnly
  );
};

export const isReadOnlyCharacteristic = (
  option: ProductOfferingOption | CharacteristicOption
): boolean => {
  return (
    isCharacteristic(option) &&
    option.characteristic.visible &&
    option.characteristic.isRequired &&
    option.characteristic.isReadOnly
  );
};

export const filterServices = (
  option: ProductOfferingOption | CharacteristicOption
): boolean => {
  if (isProductOffering(option)) {
    if (!isEquipment(option) && option.optional) {
      return true;
    }

    if (isEquipmentAdd(option)) {
      return true;
    }
  }
  if (
    isCharacteristic(option) &&
    option.characteristic.visible &&
    option.characteristic.isRequired
  ) {
    return true;
  }
  return false;
};

export const checkOptionsState = (option: ProductOfferingOption): boolean => {
  let result = false;
  // eslint-disable-next-line array-callback-return
  option.options.map((singleOption) => {
    if (
      isCharacteristic(singleOption) &&
      !singleOption.characteristic.isReadOnly &&
      singleOption.characteristic.visible &&
      singleOption.characteristic.isRequired
    ) {
      result = true;
    }
  });
  return result;
};

export const hasMultiroomQuantity = (
  option: ProductOfferingOption | CharacteristicOption
) => {
  return isProductOffering(option)
    ? option.options
        .find(
          (x) => x.id === 'dp_MultiroomQuantity' || x.id === 'MultiroomQuantity'
        )
        ?.value.toString() !== '0'
    : true;
};

export function hasProductOfferingOptionAvailableAction(
  action: OptionAvalibleActions,
  avalibleActions?: string
): boolean {
  return avalibleActions ? avalibleActions.includes(action) : false;
}
