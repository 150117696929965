import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../../../../../hooks';
import { MySales } from '../../../../../types/MySales';
import ToolTip from '../../../../_ui/ToolTip/ToolTip';
import styles from '../MySalesModal/MySalesModal.module.scss';
import MySalesResultRunny from '../MySalesResultRunny/MySalesResultRunny';

type Props = {
  salesPlan: MySales;
};

const MySalesResults = (props: Props): JSX.Element => {
  const { salesPlan } = props;

  const [openTooltip, setOpenTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useOnClickOutside(tooltipRef, () => {
    setOpenTooltip(false);
  });

  const status = [
    styles.lastMonth__count_green,
    styles.lastMonth__count_yellow,
    styles.lastMonth__count_red,
  ];

  const resultStatus = (value: number, max: number, status: string[]) => {
    if (value >= max) return status[0];
    if (value >= max * 0.9 && value < max) return status[1];
    if (value < max * 0.9) return status[2];
    return '';
  };

  const lastSales = salesPlan.previousMonthsDatas;
  const currentSale = salesPlan.currentMonthData;
  return (
    <>
      <div className={styles.results}>
        {salesPlan.approximateFee > 0 && (
          <div className={styles.monthReward}>
            <div className={styles.monthReward__header}>
              <h4 className={styles.monthReward__header__name}>
                Вознаграждение за месяц
              </h4>
              <span className={styles.header__date}>
                Приблизительно{' '}
                <div className={styles.header__info}>
                  <i
                    className={`i-dp-info ${styles.header__icon}`}
                    onClick={() =>
                      window.screen.width < 1024 && setOpenTooltip(true)
                    }
                  />
                  <div
                    className={`${styles.header__tooltip} ${
                      openTooltip ? styles.header__tooltip_open : ''
                    }`}
                    ref={tooltipRef}
                  >
                    <ToolTip
                      children={`${'Расчёт является приблизительным. Сумма вознаграждения указана до вычета налогов.'}
${'Ставка вознаграждения за продажу тарифных планов выплачивается только в том случае,'} 
${'если:'}

    ${'1. В CRM есть подтверждение об оплате денежных средств в размере не менее 90% АП за первый целый месяц пользования услугой.'}

    ${'2. Абонент является активным на конец отчётного периода.'}`}
                      position={'top'}
                    />
                  </div>
                </div>
              </span>
            </div>
            <div className={styles.monthReward__reward}>
              <i
                className={`i-dp-currency ${styles.monthReward__reward__icon}`}
              />
              <span className={styles.monthReward__reward__count}>
                {Math.floor(salesPlan?.approximateFee)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </span>
            </div>
          </div>
        )}

        <div className={styles.monthReward}>
          <div className={styles.monthReward__header}>
            <h4 className={styles.monthReward__header__name}>
              {currentSale.displayMonthName}
            </h4>
            <span className={styles.header__date}>
              До окончания месяца {currentSale.displayDaysLeftInCurrentMonth}
            </span>
          </div>
          <MySalesResultRunny
            resultStatus={resultStatus}
            currentValue={currentSale.actualSalesDisplayPercent}
            maxValue={currentSale.plannedSalesDisplayPercent}
          />
          <span
            className={`${styles.monthReward__count} ${resultStatus(
              currentSale.actualSalesDisplayPercent,
              currentSale.plannedSalesDisplayPercent,
              status
            )}`}
          >
            {currentSale.actualNumberOfSales} из{' '}
            {currentSale.plannedNumberOfSales}
          </span>
        </div>
      </div>
      <div className={styles.lastMonths}>
        {lastSales
          .slice(-3)
          .reverse()
          .map((sale) => {
            return (
              <div className={styles.lastMonth}>
                <span className={styles.lastMonth__month}>
                  {sale.displayMonthName}
                </span>
                <MySalesResultRunny
                  resultStatus={resultStatus}
                  currentValue={sale.actualSalesDisplayPercent}
                  maxValue={sale.plannedSalesDisplayPercent}
                />
                <span
                  className={`${styles.lastMonth__count} ${resultStatus(
                    sale.actualSalesDisplayPercent,
                    sale.plannedSalesDisplayPercent,
                    status
                  )}`}
                >
                  {sale.actualNumberOfSales} из {sale.plannedNumberOfSales}
                </span>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MySalesResults;
