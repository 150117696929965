/* eslint-disable no-debugger */
import React, { useState } from 'react';

import styles from './Auth.module.scss';
import Select from '../../_ui/Select/Select';
import ButtonMTS from '../../_ui/ButtonMTS/ButtonMTS';
import logo from './../../../assets/images/logo.svg';

type Login = {
  l: string;
  o: string;
  fn: string;
  code: string;
};

const Auth = () => {
  const url = new URL(window.location.href);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const logins: Login[] = url.searchParams.get('d')
    ? JSON.parse(url.searchParams.get('d') as string)
    : [];

  const link: string = url.searchParams.get('h')
    ? (url.searchParams.get('h') as string)
    : '';

  const list =
    logins &&
    logins
      .sort((_, item) => !item.code ? 0 : -1)
      .map((item) => {
        const userBlocked = !item.code;
        return {
          value: item.l,
          label: item.l,
          unit: userBlocked ? '(УЗ заблокирована)' : '',
        };
      });

  const [selectedUser, setSelectedUser] = useState<Login>(logins[0]);

  if (logins.length === 1) {
    window.location.replace(`${link}?code=${selectedUser.code}`);
    return <></>;
  } else {
    return (
      <form action={link} className={`${styles.container}`}>
        <img src={logo} className={styles.logo} alt="logo" />
        <h2 className={styles.header}>Выберите пользователя:</h2>
        <Select
          list={list}
          value={`${selectedUser.l} ${
            !selectedUser.code ? '(УЗ заблокирована)' : ''
          }`}
          onChange={(data) => {
            if (typeof data === 'string') {
              const user = logins.find((item) => item.l === data);

              user && setSelectedUser(user);
            }
          }}
          className={styles.select}
        />
        <ButtonMTS
          type="submit"
          name="code"
          disabled={!selectedUser.code}
          onClick={() =>
            sessionStorage.setItem(`${selectedUser.l}`, `${logins.length}`)
          }
          additionalstyle={styles.submitButton}
          value={selectedUser.code}
        >
          Подтвердить
        </ButtonMTS>
        {!selectedUser.code &&
          <div className={styles.userBlocked} data-testid="warning-auth-block">
            <div className={styles.warning}>
              <i className="i-dp-report" />
            </div>
            Учетная запись заблокирована. Обратитесь к руководителю или администратору для разблокировки
          </div>
        }
      </form>
    );
  }
};

export default Auth;