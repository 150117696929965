import React, { useEffect } from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';

import CheckboxMTS from '../../../_ui/CheckboxMTS/CheckboxMTS';

import styles from '../StaffForm.module.scss';
import { Dealer, User } from '../../../../types';
import { Organization } from '../../../../types/Organization';
import { Constants } from '../../../../constants';
import { storeDP } from '../../../../store/store';

type Props = {
  watch: UseFormWatch<Dealer>;
  isUser: boolean;
  user?: User;
  register?: UseFormRegister<Dealer>;
  dealerInfo?: Dealer;
  organization: Organization;
  errors: Record<string, unknown>;
  isCreateForm: boolean;
  isDisableConditions: boolean;
  setIsDisableConditions: (data: boolean) => void;
};

const CheckboxesOld = (props: Props): JSX.Element => {
  const { isDisableConditions, setIsDisableConditions, isCreateForm } = props;
  const user = storeDP.getState().userReducer.user;
  const isAdmin = user?.roles.includes('DealerAdministrator');

  useEffect(() => {
    const disable = !isAdmin && !isCreateForm;
    setIsDisableConditions(disable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const availableSellPoints = isCreateForm ?
    props.organization.sellPoints.filter(point => props.user?.sellPoints.find(p => p === point.id)) :
    props.organization.sellPoints;

  return (
    <div className={styles.StaffForm__Checkboxes}>
      <div className={styles.StaffForm__Roles}>
        <h3
          className={`${styles.StaffForm__RolesHeader} ${
            props.errors['roles'] ? styles.StaffForm__RolesHeader_error : ''
          }`}
        >
          Роли*
        </h3>
        <div className={styles.StaffForm__RolesList}>
          {props.organization.roles.map((role) => {
            return (
              <div key={role} className={styles.StaffForm__Checkbox}>
                <CheckboxMTS
                  boxSize="big"
                  disabled={props.isUser || isDisableConditions}
                  name="roles"
                  value={role}
                  register={props.register}
                  checked={
                    props.dealerInfo?.roles &&
                    props.dealerInfo?.roles.some((item) => item === role)
                  }
                  data-testid={`role-${role}`}
                  {...(props.isUser
                    ? {}
                    : {
                        required: {
                          required: Constants.MESSAGE_INPUT_REQUIRED,
                        },
                      })}
                >
                  {role}
                </CheckboxMTS>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.StaffForm__Roles}>
        <h3
          className={`${styles.StaffForm__RolesHeader} ${
            props.errors['sellPoints']
              ? styles.StaffForm__RolesHeader_error
              : ''
          }`}
        >
          Точки продаж*
        </h3>
        <div className={styles.StaffForm__RolesList}>
          {availableSellPoints.map((point) => {
            return (
              <div key={point.id} className={styles.StaffForm__Checkbox}>
                <CheckboxMTS
                  boxSize="big"
                  disabled={isDisableConditions}
                  name="sellPoints"
                  value={point.id}
                  register={props.register}
                  required={{
                    required: Constants.MESSAGE_INPUT_REQUIRED,
                  }}
                  checked={props.dealerInfo?.sellPoints.some(
                    (item) => item === point.id
                  )}
                  data-testid={`sell-point-${point.id}`}
                >
                  {point.name}
                </CheckboxMTS>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CheckboxesOld;
