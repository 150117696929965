import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import Modal from '../_modals/Modal/Modal';
import styles from './Help.module.scss';
import HelpReview from './components/HelpReview/HelpReview';
import LoaderWithOverlay from '../_ui/LoaderWithOverlay/LoaderWithOverlay';

type Props = {
  onHelpCloseClick: () => void;
};

const Help = (props: Props): JSX.Element => {
  const modalRoot = document.getElementById('modal-root') as HTMLElement;

  const [loader, setLoader] = useState<boolean>(false);

  return createPortal(
    <Modal
      onCloseClick={props.onHelpCloseClick}
      modalStyle={`${styles.ModalBlock}`}
      closeButtonStyle={styles.ButtonClose}
    >
      <div className={styles.container}>
        <HelpReview
          setLoader={(bool) => setLoader(bool)}
          onCloseClick={props.onHelpCloseClick}
        />
      </div>
      {loader && <LoaderWithOverlay />}
    </Modal>,
    modalRoot
  );
};

export default Help;
