import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../_modals';
import { createPortal } from 'react-dom';
import styles from './widgetModal.module.scss';
import InputMTS from '../../../../_ui/InputMTS/InputMTS';
import ButtonMTS from '../../../../_ui/ButtonMTS/ButtonMTS';
import Widget from '../../widget';

type Props = {
  closeModal: () => void;
};

const WidgetModal = (props: Props): JSX.Element => {
  const widgetTokenLocal = process.env.REACT_APP_WG_TOKEN;

  const { closeModal } = props;
  const [token, setToken] = useState(widgetTokenLocal ? widgetTokenLocal : '');
  const [disableButton, setDisableButton] = useState(true);
  const [openWidget, setOpenWidget] = useState(false);

  useEffect(() => {
    if (token.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [token]);

  const modalRoot = document.getElementById('modal-root') as HTMLElement;

  return createPortal(
    <Modal onCloseClick={closeModal} modalStyle={styles.widgetOpen}>
      <div className={styles.widgetOpen__container}>
        <InputMTS
          value={widgetTokenLocal ? widgetTokenLocal : ''}
          label="Введите свой токен"
          onChange={(value) => setToken(value as React.SetStateAction<string>)}
          containerStyle={styles.widgetOpen__input}
          buttonClear={token.length > 0}
        />

        <ButtonMTS onClick={() => setOpenWidget(true)} disabled={disableButton}>
          Далее
        </ButtonMTS>

        {openWidget && (
          <Widget
            openWidget={openWidget}
            setOpenWidget={(data) => setOpenWidget(data)}
            token={token}
          />
        )}
      </div>
    </Modal>,
    modalRoot
  );
};

export default WidgetModal;
