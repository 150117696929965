import React, { useEffect, useRef, useState } from 'react';
import styles from './DateRangePicker.module.scss';
import DateRangeBlock from '../DateBlock/DateRangeBlock';
import { useOnClickOutside } from '../../../hooks';
import DateRangeCalendar from './components/DateRangeCalendar/DateRangeCalendar';
import { DoubleDate } from '../../../types';
import { addDay } from '../../../utils/datetime';

type Props = {
  placeholder?: string | null;
  dates?: DoubleDate;
  onChange?: (dates: DoubleDate) => void;
  onClose?: () => void;
  disabled?: boolean;
  disabledBlock?: boolean;
  periodSet?: true | undefined;
  disableClearButton?: boolean;
  singleDateSelect?: boolean;
  previosMonth?: boolean;
  periodRestrictionTimeslot?: null | number;
};

const DateRangePicker = (props: Props): JSX.Element => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (opened) {
      setOpened(false);
    }
  });
  const [opened, setOpened] = useState<boolean>();
  const [openedIndex, setOpenedIndex] = useState<0 | 1>(0);
  const [ownDates, setOwnDates] = useState<DoubleDate>([]);
  const [targetDate, setTargetDate] = useState<Date | null>(null);

  const dates = props.dates || ownDates;
  const hasAllDates = !!(dates[0] && dates[1]);
  let fromDate = !dates[0] ? targetDate : dates[0];
  let toDate = !dates[1] ? targetDate : dates[1];

  const oneDayMiliseconds = 24 * 60 * 60 * 1000;
  const today = new Date();
  const dayLimit = props.periodRestrictionTimeslot
    ? new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ).getTime() +
      (props.periodRestrictionTimeslot - 1) * oneDayMiliseconds
    : new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ).getTime() +
      3 * oneDayMiliseconds;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const daysPeriod = props.periodRestrictionTimeslot
    ? targetDate &&
      Math.floor((dayLimit - targetDate.getTime()) / (23 * 59 * 59 * 999))
    : 3;

  if (dates[0] && dates[1] && targetDate) {
    if (!props.periodSet) {
      const beforeFrom = targetDate?.getTime() <= dates[0]?.getTime();
      const afterTo = targetDate?.getTime() >= dates[1]?.getTime();
      if (beforeFrom) fromDate = targetDate;
      if (afterTo) toDate = targetDate;
      if (!beforeFrom && !afterTo) {
        if (openedIndex) toDate = targetDate;
        else fromDate = targetDate;
      }
    } else {
      fromDate = targetDate;
    }
  }

  useEffect(() => {
    if (hasAllDates && !props.periodSet) setOpened(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, hasAllDates]);

  useEffect(() => {
    if (
      targetDate != null &&
      props.dates &&
      props.dates[0] === undefined &&
      props.dates[1] === undefined
    ) {
      clearDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dates]);

  useEffect(() => {
    if (props.dates && props.onClose) props.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dates]);

  const getExpectedIndex = (expectedDate: Date | null): 0 | 1 => {
    if (!dates[1]) return 1;
    if (!(dates[0] && dates[1] && expectedDate)) return 0;
    const beforeFrom = expectedDate?.getTime() <= dates[0]?.getTime();
    const afterTo = expectedDate?.getTime() >= dates[1]?.getTime();
    if (beforeFrom) return 0;
    if (afterTo) return 1;
    return openedIndex;
  };
  const expectedIndex = getExpectedIndex(targetDate);

  const chooseDate = () => {
    if (!props.periodSet) {
      const emptyIndex = dates[0] ? 1 : 0;
      const index = !hasAllDates ? emptyIndex : expectedIndex;
      const newState = [...dates] as DoubleDate;

      newState[index] =
        targetDate &&
        (index
          ? new Date(targetDate.setHours(23, 59, 59, 999))
          : new Date(targetDate.setHours(0, 0, 0, 0)));

      if (props.onChange) props.onChange(newState);
      if (!props.dates) setOwnDates(newState);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

      const fourDaysPeriod =
        daysPeriod && daysPeriod > 0
          ? ([
              targetDate,

              addDay(
                daysPeriod > 3 ? 3 : daysPeriod,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                new Date(targetDate!.setHours(23, 59, 59, 999))
              ),
            ] as DoubleDate)
          : ([
              targetDate,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              new Date(targetDate!.setHours(23, 59, 59, 999)),
            ] as DoubleDate);
      if (props.onChange) props.onChange(fourDaysPeriod);
    }
  };

  const onHoverDate = (date: Date | null) => setTargetDate(date);

  const toggleOpened = (index: 0 | 1) => () => {
    setOpenedIndex(index);
    setOpened(!opened);
  };

  const clearDates = () => {
    if (props.onChange) props.onChange([]);
    if (!props.dates) setOwnDates([]);
    setTargetDate(null);
  };

  const mainMonthDate = dates[0] || new Date();

  return (
    <div
      className={`${styles.container} ${
        props.disabledBlock ? styles.container_disabled : ''
      }`}
      ref={ref}
    >
      <DateRangeBlock
        placeholder={props.placeholder}
        ontToggle={toggleOpened(0)}
        onClear={clearDates}
        dateFrom={fromDate}
        dateTo={toDate}
        disabled={props.disabled}
        disableClearButton={props.disableClearButton}
      />
      {opened && (
        <div className={styles.calendar}>
          <DateRangeCalendar
            mainDate={mainMonthDate}
            dates={dates}
            close={() => {
              props.singleDateSelect && setOpened(false);
            }}
            chooseDate={() => {
              chooseDate();
            }}
            onHoverDate={onHoverDate}
            expectedIndex={expectedIndex}
            targetDate={targetDate}
            periodSet={props.periodSet}
            previosMonth={props.previosMonth}
            periodRestrictionTimeslot={props.periodRestrictionTimeslot}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
