import { IGetMonthInfo } from '../types';

export function toZeroHour(date?: Date | null): Date | null {
  if (!date) return null;
  return new Date(date.setHours(0, 0, 0, 0));
}

export function addMonth(value: number, date?: Date | null): Date {
  date = date ? new Date(date) : new Date();
  if (date.getDate() > 28) {
    if (value === -1) {
      date.setMonth(date.getMonth() + value);
    } else {
      date.setDate(
        Number(date.getDate()) + Number(getMonthInfo(date).daysInNext)
      );
    }
  } else {
    date.setMonth(date.getMonth() + value);
  }
  return date;
}

export function addDay(value: number, date?: Date | null): Date {
  date = date ? new Date(date) : new Date();
  return new Date(date.setDate(date.getDate() + value));
}

export function datesDiff(d1: Date, d2: Date): number {
  const time1 = d1.getTime();
  const time2 = d2.getTime();
  let diff = time1 - time2;
  diff = diff < 0 ? diff * -1 : diff;
  return Math.floor(diff / (1000 * 3600 * 24));
}

export function getMonthInfo(date: Date): IGetMonthInfo {
  date = date ? date : new Date();
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const firstDateMonth = new Date(new Date(date).setDate(1));
  const firstWeekDay =
    firstDateMonth.getDay() === 0 ? 6 : firstDateMonth.getDay() - 1;
  const daysInMonth = 32 - new Date(year, monthIndex, 32).getDate();
  const daysInNext = 32 - new Date(year, monthIndex + 1, 32).getDate();
  return {
    year,
    monthIndex,
    firstWeekDay,
    daysInMonth,
    daysInNext,
  };
}

export const monthPeriods = [
  { value: '1', label: '1 месяц' },
  { value: '2', label: '2 месяца' },
  { value: '3', label: '3 месяца' },
  { value: '4', label: '4 месяца' },
  { value: '5', label: '5 месяцев' },
  { value: '6', label: '6 месяцев' },
  { value: '12', label: '12 месяцев' },
];
