import React, { useState } from 'react';
import styles from './SideMenu.module.scss';
import HeaderLogin from '../HeaderLogin/HeaderLogin';
import Notes from '../../Notes/Notes';
import Help from '../../../components/Help/Help';
import { getConfig } from '../../../api/ConfigApi';
import WidgetModal from '../Widget/components/widgetModal/widgetModal';
import NavbarOld from '../NavBar/NavbarOld';
import NavbarNew from '../NavBar/NavbarNew';

type Props = {
  opened: boolean;
  close: () => void;
};

const SideMenu = ({ opened, close }: Props): JSX.Element => {
  const [openHelp, setOpenHelp] = useState(false);
  const [openWidget, setOpenWidget] = useState(false);

  const [widgetSwitch, setWidgetSwitch] = useState<boolean>(false);
  const [helpSwitch, setHelpSwitch] = useState<boolean>(false);
  const [useNewRoleModel, setUseNewRoleModel] = useState(false);

  void getConfig().then((config) => {
    setHelpSwitch(config.helpSwitch === true);
    setWidgetSwitch(config.widgetSwitch === true);
    setUseNewRoleModel(config.useNewRoleModel === true);
  });
  return (
    <div className={opened ? styles.Opened : styles.Closed}>
      <div className={styles.Header}>
        <HeaderLogin />
        <button
          onClick={close}
          tabIndex={-1}
          className={`i-dp-close ${styles.ButtonClose}`}
          data-testid="side-menu-close"
        />
      </div>

      {useNewRoleModel ? (
        <NavbarNew onClose={close} />
      ) : (
        <NavbarOld onClose={close} />
      )}

      <Notes notesStyle={styles.Notes_SideMenu} />

      {widgetSwitch && (
        <button
          className={styles.ButtonHelp}
          data-testid="side-menu-widget"
          onClick={() => setOpenWidget(true)}
        >
          Виджет
          <i className={`i-dp-list ${styles.ButtonHelp__Icon}`} />
        </button>
      )}

      {helpSwitch && (
        <button
          className={styles.ButtonHelp}
          onClick={() => setOpenHelp(true)}
          data-testid="side-menu-help"
        >
          <span>Оставить отзыв</span>
          <i className={`i-dp-question ${styles.ButtonHelp__Icon}`} />
        </button>
      )}

      {openWidget && <WidgetModal closeModal={() => setOpenWidget(false)} />}

      {openHelp && <Help onHelpCloseClick={() => setOpenHelp(false)} />}
    </div>
  );
};

export default SideMenu;
