import React, { ChangeEvent, useState } from 'react';
import { UseFormRegister, Path } from 'react-hook-form';

import styles from './CheckboxMTS.module.scss';

import { Dealer } from '../../../types';

type Props = {
  name?: Path<Dealer>;
  value?: string;
  children?: string;
  onChange?: (val: boolean) => void;
  onClick?: (args?: any) => void;
  disabled?: boolean;
  error?: boolean;
  register?: UseFormRegister<Dealer>;
  required?: {
    required: string;
  };
  defaultChecked?: boolean;
  checked?: boolean;
  addBoxStyle?: string;
  addIconStyle?: string;
  boxSize?: 'middle' | 'big';
  testid?: string;
  radio?: boolean;
};

const CheckboxMTS = (props: Props): JSX.Element => {
  const { boxSize } = props;

  const [checked, setChecked] = useState(props.checked);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.checked;
    if (props.onChange) props.onChange(inputValue);
  };

  const disabled = !!props.disabled;
  const error = props.error ? styles.Checkbox__Box_Error : styles.Checkbox__Box;
  const addBoxStyle = props.addBoxStyle ? props.addBoxStyle : '';
  const addIconStyle = props.addIconStyle ? props.addIconStyle : '';

  const setSizeBox = (value: string) => {
    switch (value) {
      case 'middle':
        return styles.Checkbox__Box_Middle;
      case 'big':
        return styles.Checkbox__Box_Big;
      default:
        return '';
    }
  };

  return (
    <label className={styles.Checkbox} data-testid="checkbox-container">
      <input
        className={styles.Checkbox__Input}
        disabled={disabled}
        type={props.radio ? 'radio' : 'checkbox'}
        id={`checkbox-${props.children ?? 'noName'}`}
        onClick={props.onClick ? props.onClick : () => setChecked(!checked)}
        onChange={handleChange}
        checked={props.register ? checked : props.checked}
        defaultChecked={props.defaultChecked}
        value={props.value}
        data-testid={props.testid ? props.testid : 'checkbox'}
        {...(props.register &&
          props.name &&
          (props.required !== undefined
            ? props.register(props.name, props.required)
            : props.register(props.name)))}
      />
      <span
        className={`${error} ${addBoxStyle} ${
          boxSize ? setSizeBox(boxSize) : ''
        }`}
      >
        {' '}
        <i className={`i-dp-done ${styles.Checkbox__Icon} ${addIconStyle}`} />
      </span>
      {props.children && (
        <span
          className={`${styles.Checkbox__Text} ${
            disabled ? styles.Checkbox__Text_Disabled : ''
          }`}
        >
          {props.children}
        </span>
      )}
    </label>
  );
};

export default CheckboxMTS;
