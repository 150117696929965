import {
  INewOrder,
  IOrderForm,
  Offering,
  OrderTimeslot,
  TimeslotModel,
} from '../../../types';
import { UserSellPoint } from '../../../context/UserContext';
import { Address } from '../../../api/AddressApi';
import { RequestAddress } from '../../../api/BasketApi';
import { UserOrganization } from '../../../context/HandbookContext';
import { storeDP } from '../../../store/store';
import { getConfig } from '../../../api/ConfigApi';
import { createAdditionalOrder } from './utils/CreateAdditionalOrder';

const extractDigits = (value: string): string => value?.replace(/[^\d]/g, '');

let additionalCreateOrder = false;
let calculatorWithoutTarif = '';

void getConfig().then((res) => {
  additionalCreateOrder = res.additionalCreateOrder;
  calculatorWithoutTarif = res.calculatorWithoutTarif;
});

export interface TimeSlotBase {
  timeslot?: OrderTimeslot | undefined;
  activeSlot?: TimeslotModel;
}

export interface ChangeTimeslotRequest extends TimeSlotBase {
  requestId: string;
  notes: string;
}

type Client = {
  contactName: string;
  contactPhone: string;
  comment: string;
};

export type ByRequest = {
  requestId: string;
  periodStart: string;
  periodEnd: string;
  type: string;
  regionName?: string;
  providerName?: string;
  providerId?: string;
};

export function orderFormToNewRequest(
  orderForm: IOrderForm,
  sellPoint?: UserSellPoint,
  timeslot?: OrderTimeslot,
  client?: Client,
  organization?: UserOrganization
): INewOrder {
  const checkID = storeDP.getState().createOrderReducer.isConvergentCheckId;
  const redPack = storeDP.getState().createOrderReducer.redPackData;
  const isCheckId = checkID ? checkID : null;

  const hasTarif = calculatorWithoutTarif.includes(
    orderForm.baseOffer?.id ?? ''
  );

  const additionalOffers = (additionalOffers: Offering[]) =>
    additionalOffers.map((offer) => {
      return {
        id: offer.id,
        name: offer.name,
        description: offer.description,
        isBase: offer.isBase,
        categories: offer.categories,
        technologies: offer.technologies,
        prices: offer.prices,
        products: offer.products,
        quantity: offer.quantity,
      };
    });

  const request: INewOrder = {
    address: ToRequestAddress(orderForm.address, orderForm.isPrivate),
    firstName: orderForm.client.firstname,
    lastName: orderForm.client.lastname,
    middleName: orderForm.client.secondname,
    contactPhone: client?.contactPhone
      ? extractDigits(client?.contactPhone)
      : extractDigits(orderForm.client.phone),
    comment: client?.comment ? client.comment : orderForm.client.comment,

    offerings: [
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      orderForm.baseOffer!,
      ...additionalOffers(orderForm.additionalOffers),
    ].filter((item) => item?.id !== '1-ABPB903D'), //КОСТЫЛЬ: Убираем из основной заявки ТВ-ОБорудование и ТВ-Оборудование б.у.
    technicalCapabilityId: orderForm.capability?.id,
    checkResultId: orderForm.checkResultId,
    sellPointId: sellPoint?.id,
    sellPointName: sellPoint?.name,
    timeSlot: timeslot ?? orderForm.timeslot,
    basketId: orderForm.basketId ? orderForm.basketId : '',
    organizationName: organization?.name,
    accountNumber: orderForm.chosenAgreement?.accountNumber
      ? orderForm.chosenAgreement.accountNumber
      : null,
    msisdn: orderForm.client.bundleNumber
      ? orderForm.client.bundleNumber
      : null,
    isConvergentCheckId:
      orderForm.chosenAgreement && redPack ? null : isCheckId,
    providerId: orderForm.providerId ?? '',
    providerName: orderForm.providerName ?? '',
  };

  if (additionalCreateOrder && !hasTarif) {
    const sortedAdditionalOffers = orderForm.additionalOffers;

    if (sortedAdditionalOffers.length) {
      request.additionals = createAdditionalOrder(
        sortedAdditionalOffers,
        additionalOffers
      );
    }
  }

  return request;
}

export function ToRequestAddress(
  address: Address | undefined,
  isPrivate: boolean,
  niossId?: string
): RequestAddress {
  const flat: string | undefined = address?.flat?.name;

  const isPriv = Boolean(!flat && isPrivate);

  const { flat: _, ...adrWithoutFlat } = address as Address;

  return {
    niossId,
    flat,
    isPrivate: isPriv,
    ...adrWithoutFlat,
  };
}

export type Provider = {
  providerId: string;
  providerName: string;
};

export const orderFormByRequest = (
  requestId: string,
  timeslot: OrderTimeslot,
  provider?: Provider
): ByRequest => {
  return {
    requestId: requestId,
    periodStart: timeslot?.periodStart,
    periodEnd: timeslot?.periodEnd,
    type: timeslot?.type,
    regionName: timeslot?.regionName,
    providerId: (provider && provider.providerId) ?? '',
    providerName: (provider && provider.providerName) ?? '',
  };
};

type GolfstreamCreateOrderRequest = {
  address: {
    fullAddress: string;
    region: string;
    type: string;
  };
  customer: {
    lastname: string;
    firstname: string;
    middlename: string;
    mobile: string;
  };
  siebelRequestId: string;
  comments: string[];
};

export const orderFormToGolfstreamRequest = (
  orderForm: IOrderForm,
  type: string,
  siebelRequestId: string
): GolfstreamCreateOrderRequest => {
  const addressReq = {
    fullAddress: orderForm.address?.fullAddress ?? '',
    region: orderForm.address?.region.name ?? '',
    type: type,
  };

  const customer = {
    lastname: orderForm.client.lastname,
    firstname: orderForm.client.firstname,
    middlename: orderForm.client.secondname,
    mobile: orderForm.client.phone,
  };

  return {
    address: addressReq,
    customer: customer,
    siebelRequestId: siebelRequestId,
    comments: [orderForm.client.comment],
  };
};

export const changeTimeSlotRequest = (
  requestId: string,
  notes: string,
  timeslot: OrderTimeslot
): ChangeTimeslotRequest => {
  return {
    requestId: requestId,
    notes: notes,
    timeslot: timeslot,
  };
};
