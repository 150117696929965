import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './Layout.module.scss';
import { Footer, Header, SideMenu } from '../index';

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const Layout = (props: Props): JSX.Element => {
  const [openedSideMenu, setOpenedSideMenu] = useState(false);

  const toggleSideMenu = () => {
    setOpenedSideMenu(!openedSideMenu);
  };

  const backClassNames = classNames({
    [styles.SideMenu__Back]: true,
    [styles.SideMenu__Back__opened]: openedSideMenu,
  });

  return (
    <section className={styles.Wrapper}>
      <div className={styles.Header}>
        <Header toggleSideMenu={toggleSideMenu} />
      </div>
      <main className={styles.Content}>{props.children}</main>
      <div className={styles.Footer}>
        <Footer />
      </div>
      <div
        className={backClassNames}
        onClick={toggleSideMenu}
        data-testid="layout-toggle-side-menu"
      />
      <aside
        className={`${styles.SideMenu} ${
          openedSideMenu ? styles.SideMenu_opened : ''
        }`}
      >
        <SideMenu opened={openedSideMenu} close={toggleSideMenu} />
      </aside>
    </section>
  );
};

export default Layout;
