import { storeDP } from '../store';
import {
  SubscriptionActions,
  SubscriptionReducerActions,
  SubscriptionState,
} from './types/subscriptionTypes';

const initialState: SubscriptionState = {
  customer: null,
  itemPricesSummary: [],
  loading: false,
  errors: [],
  flags: [],
  cardsContent: [],
  calculateBasketPrice: undefined,
};

export const subscriptionReducer = (
  state = initialState,
  action: SubscriptionActions
): SubscriptionState => {
  switch (action.type) {
    case SubscriptionReducerActions.SET_CUSTOMER:
      return { ...state, customer: action.payload };

    case SubscriptionReducerActions.SET_ITEM_PRICES_SUMMARY:
      return { ...state, itemPricesSummary: action.payload };

    case SubscriptionReducerActions.TOGGLE_LOADER:
      return { ...state, loading: action.payload };

    case SubscriptionReducerActions.SET_ERROR:
      return { ...state, errors: action.payload };

    case SubscriptionReducerActions.SET_FLAGS:
      return { ...state, flags: action.payload };

    case SubscriptionReducerActions.SET_CARDS_CONTENT:
      return { ...state, cardsContent: action.payload };

    case SubscriptionReducerActions.SET_CALCULATE_BASKET_PRICE:
      return { ...state, calculateBasketPrice: action.payload };

    default:
      return state;
  }
};

export const clearSubscriptionsReducer = () => {
  storeDP.dispatch({
    type: SubscriptionReducerActions.SET_CUSTOMER,
    payload: null,
  });
  storeDP.dispatch({
    type: SubscriptionReducerActions.SET_ITEM_PRICES_SUMMARY,
    payload: [],
  });
  storeDP.dispatch({
    type: SubscriptionReducerActions.TOGGLE_LOADER,
    payload: false,
  });
  storeDP.dispatch({
    type: SubscriptionReducerActions.SET_ERROR,
    payload: [],
  });
  storeDP.dispatch({
    type: SubscriptionReducerActions.SET_FLAGS,
    payload: [],
  });
  storeDP.dispatch({
    type: SubscriptionReducerActions.SET_CARDS_CONTENT,
    payload: [],
  });
};
