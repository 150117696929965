import React, { useState, useRef } from 'react';
import MySalesModal from './components/MySalesModal/MySalesModal';
import styles from './MySales.module.scss';
import { useOnClickOutside } from '../../../hooks';
import ModalFrame from '../../_ui/ModalFrame/ModalFrame';
import { Modal } from '../../_modals';
import ReactDOM from 'react-dom';

const MySales = (): JSX.Element => {
  const [openMysales, setOpenMysales] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (openMysales) setOpenMysales(false);
  });
  const modalroot = document.getElementById('modal-root');

  return (
    <div className={styles.container}>
      <div
        className={styles.button}
        onClick={() => setOpenMysales(true)}
        data-testid="my-sales"
      >
        <span>Мои продажи</span>
        <i className={`i-dp-currency ${styles.icon}`} />
      </div>

      {openMysales && window.screen.width >= 1024 && (
        <>
          <div className={styles.modal}>
            <ModalFrame
              onCloseClick={() => setOpenMysales(false)}
              modalStyle={styles.block}
            >
              <MySalesModal />
            </ModalFrame>
          </div>
        </>
      )}

      {openMysales &&
        modalroot &&
        window.screen.width < 1024 &&
        ReactDOM.createPortal(
          <div className={styles.modal_adaptive}>
            <Modal
              onCloseClick={() => setOpenMysales(false)}
              modalStyle={styles.block_adaptive}
            >
              <MySalesModal />
            </Modal>
          </div>,
          modalroot
        )}
    </div>
  );
};

export default MySales;
