/**
 * первую букву в слове переводит в заглавную
 * @param str слова
 * @returns string
 */
export const capFirstLet = (str: string): string => {
    if (!str) {
        return str;
    }
    let result: Array<string> = [];
    str.split(' ').forEach(el => {
        result.push(el[0] ? el[0].toUpperCase() + el.slice(1) : '');
    });
    str = result.join(' ');

    result = [];
    str.split('-').forEach(el => {
        result.push(el[0] ? el[0].toUpperCase() + el.slice(1) : '');
    });
    str = result.join('-');
    return str;
};

export const toUpperCaseFirstLet = (str: string): string => {
    if (!str) {
        return str;
    }
    const splitted = str.split('');
    const first = splitted[0].toUpperCase();
    const rest = [...splitted];
    rest.splice(0,1);
    const result = [first, ...rest].join('');
    return result;
};
