/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState, useEffect } from 'react';
import { UseFormRegister, Path, RegisterOptions } from 'react-hook-form';
import { useSlots, UseSlotsResult } from '../../../hooks';
import ErrorText from '../ErrorText/ErrorText';
import styles from './Input.module.scss';
import InputMask from 'react-input-mask';

type Props = {
  value?: string;
  text?: string;
  type?: string;
  name: Path<any>;
  register?: UseFormRegister<any>;
  errors?: Record<string, unknown>;
  testid?: string;
  dirty?: boolean;
  disabled?: boolean;
  toggle?: () => void;
  tabiIndex?: number;
  className?: string;
  required?: RegisterOptions;
  placeHolder?: string;
  onChange?: (value) => void;
  children?: JSX.Element | JSX.Element[];
  labelStyle?: string;
  inputStyle?: string;
  errorStyle?: string;
  label?: string;
  defaultValue?: string;
  autoComplete?: string;
};

export type PropsWithMask = Props & {
  mask: string | Array<string | RegExp>;
  maskPlaceholder?: string | null | undefined;
  alwaysShowMask?: boolean | undefined;
};

const Input = (props: Props): JSX.Element => {
  const isMasked = false;
  return CreateInput(props, isMasked);
};

export const InputWithMask = (props: PropsWithMask): JSX.Element => {
  const isMasked = true;
  return CreateInput(props, isMasked);
};

const CreateInput = (props: Props, isMasked: boolean) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.errors !== undefined) {
      setErrors(props.errors);
    }
  }, [props.errors]);

  const setError = GetErrorMessage(props.name, errors);

  const slots = useSlots(['prefix', 'postfix'], props.children);

  const inputElement = isMasked
    ? CreateInputMaskElement(props as PropsWithMask, setError)
    : CreateInputElement(props, setError);

  return (
    <div className={styles.container}>
      {props.label && (
        <label className={`${styles.label}`}>{props.label}</label>
      )}
      <label
        className={`${props.labelStyle ? props.labelStyle : ''} ${
          props.className ? props.className : ''
        } `}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          }
        }}
        data-testid="input-label"
      >
        {props.children ? CreateIconTitlePrefix(slots) : CreateTextTitle(props)}

        {inputElement}

        {props.children ? CreateIconTitlePostfix(slots) : ''}
      </label>
      <div
        style={{ height: 16 }}
        className={styles.errorContainer}
        data-testid={
          setError
            ? props.testid
              ? `input-${props.testid}-error`
              : `input-${props.name}-error`
            : ''
        }
      >
        {setError && <ErrorText>{setError}</ErrorText>}
      </div>
    </div>
  );
};

const GetErrorMessage = (
  elementName: string,
  errors: Record<string, unknown>
): string => {
  //��� �������� ����� ���� � ���� client.name, ��������
  const names = elementName.includes('.')
    ? elementName.split('.')
    : [elementName];
  let setError = errors[names[0]] as string;

  if (Object.keys(errors).length > 0 && setError) {
    for (let i = 1; i < names.length; i++) {
      setError = setError[names[i]] as string;
    }

    if (setError !== undefined) {
      setError = setError['message'] as string;
    }
  }

  return setError;
};

const CreateInputMaskElement = (
  props: PropsWithMask,
  setError: string
): JSX.Element => {
  const pasteValue = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const getPasteValue = event.clipboardData
      .getData('text')
      .replace(/[^0-9]/g, '');
    if (getPasteValue.length === 12 && getPasteValue.startsWith('+7')) {
      const slice = getPasteValue.slice(2);
      event.currentTarget.value = `+7(${slice}`;
    } else if (
      getPasteValue.length === 11 &&
      (getPasteValue.startsWith('7') || getPasteValue.startsWith('8'))
    ) {
      const slice = getPasteValue.slice(1);
      event.currentTarget.value = `+7(${slice}`;
    }
  };

  return (
    <InputMask
      disabled={props.disabled}
      mask={props.mask as string}
      maskChar=""
      // maskPlaceholder={null}
      value={props.value}
      onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => pasteValue(e)}
      onClick={(e) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        e.preventDefault();
        props?.toggle && props.toggle();
      }}
      {...(props.register ? props.register(props.name, props.required) : '')}
      data-testid={props.testid ? props.testid : ''}
    >
      {/* @ts-ignore */}
      {() => CreateInputElement(props, setError)}
    </InputMask>
  );
};

const CreateInputElement = (props: Props, setError: string): JSX.Element => {
  return (
    <input
      id={props.name}
      tabIndex={props.tabiIndex}
      defaultValue={props.defaultValue}
      autoComplete={props.autoComplete ? props.autoComplete : ''}
      className={`
                ${props.inputStyle ? props.inputStyle : ''}
                ${setError && props.errorStyle ? props.errorStyle : ''}
                ${props.type === 'date' ? styles.Pointer : ''}
            `}
      type={props.type}
      placeholder={props.placeHolder}
      data-testid={`input-${props.testid ? props.testid : ''}`}
      disabled={props.disabled}
      value={props.value}
      onClick={(e) => {
        e.preventDefault();
        props?.toggle && props.toggle();
      }}
      {...(props.register ? props.register(props.name, props.required) : '')}
    />
  );
};

const CreateTextTitle = (props: Props) => {
  return (
    <span className={styles.Input__Text}>
      {props.text}
      {props.required?.required ? '*' : ''}
    </span>
  );
};

const CreateIconTitlePrefix = (slots: UseSlotsResult) => {
  return <div className={styles.textInput__prefix}>{slots.prefix}</div>;
};

const CreateIconTitlePostfix = (slots: UseSlotsResult) => {
  return <div className={styles.textInput__postfix}>{slots.postfix}</div>;
};

export default Input;

Input.defautProps = {
  type: 'text',
};
