import { useState } from 'react';
import {
  DeepMap,
  FieldError,
  FieldNamesMarkedBoolean,
  UseFormRegister
} from 'react-hook-form';

import Input, { InputWithMask } from '../../../_ui/Input/Input';
import styles from '../StaffForm.module.scss';
import inputStyles from '../../../_ui/Input/Input.module.scss';
import { Dealer } from '../../../../types';
import { Constants } from '../../../../constants';
import { storeDP } from '../../../../store/store';
import { getConfig } from '../../../../api/ConfigApi';
import { checkSameDigits } from '../../../_modals/UpdateMsisdn/components/Utils/Utils';

type Props = {
  register: UseFormRegister<Dealer>;
  dealerInfo?: Dealer;
  errors?: DeepMap<Dealer, FieldError>;
  dirty: DeepMap<Partial<Readonly<FieldNamesMarkedBoolean<Dealer>>>, true>;
  isCreateForm: boolean;
};

const InputsNew = (props: Props): JSX.Element => {
  const { dealerInfo, isCreateForm } = props;

  const [configUpdateStaffNumber, setConfigUpdateStaffNumber] =
    useState<boolean>(true);

  const user = storeDP.getState().userReducer.user;

  const canChange = (role: string, right: string) => {
    return (
      isCreateForm ||
      ((dealerInfo?.roles2?.includes(role) && user?.rights?.includes(right)) &&
        !dealerInfo?.roles2?.includes('Administrator'))
    );
  };

  const canChangePhone = canChange('Supervisor', 'Supervisor_ChangePhone')||
    canChange('Employee', 'Employee_ChangePhone') && user && user.login !== dealerInfo?.id;
  const canChangeEmail = canChange('Supervisor', 'Supervisor_ChangeEmail') || canChange('Employee', 'Employee_ChangeEmail');
  const canChangeFIO = canChange('Supervisor', 'Supervisor_ChangeFIO') || canChange('Employee', 'Employee_ChangeFIO');
  const canChangeStaffNumber = canChange('Supervisor', 'Supervisor_ChangeStaffNumber') || canChange('Employee', 'Employee_ChangeStaffNumber');

  void getConfig().then((res) =>
    setConfigUpdateStaffNumber(res.updateStaffNumber === true)
  );

  return (
    <div className={styles.StaffForm__Info}>
      <Input
        disabled={!canChangeFIO}
        text="Фамилия"
        placeHolder="Фамилия"
        type="text"
        name="lastname"
        register={props.register}
        required={{
          required: Constants.MESSAGE_INPUT_REQUIRED,
          pattern: {
            value:
              /^(([А-ЯЁа-яё]{1,24})|(([А-ЯЁа-яё]{1,24})+(-[А-ЯЁа-яё]{1,24})))$/,
            message:
              'Используйте кириллицу, дефис, не используйте цифры, пробелы',
          },
          minLength: {
            value: 2,
            message: 'Минимальное количество символов: 2',
          },
          maxLength: {
            value: 24,
            message: 'Максимальное количество символов: 24',
          },
        }}
        errors={props.errors}
        testid="lastname"
        dirty={props.dirty['lastname']}
        tabiIndex={1}
        className={styles.StaffForm__Input_Lastname}
        labelStyle={inputStyles.Input}
        inputStyle={`${inputStyles.Input__Input} ${inputStyles.Capitalize}`}
        errorStyle={inputStyles.Input__Input_Error}
      />
      <InputWithMask
        disabled={!canChangePhone}
        text="Телефон"
        mask="+7 999 999-99-99"
        maskPlaceholder={null}
        type="tel"
        name="phone"
        required={{
          required: Constants.MESSAGE_INPUT_REQUIRED,
          pattern: {
            value: /\+7\s\d{3}\s\d{3}-\d{2}-\d{2}$/g,
            message: 'Телефон должен быть в формате +7 999 123-45-67',
          },
          validate: (value: string) => checkSameDigits(value),
        }}
        register={props.register}
        testid="phone"
        tabiIndex={4}
        className={styles.StaffForm__Input_Phone}
        errors={props.errors}
        placeHolder="+7"
        dirty={props.dirty['phone']}
        labelStyle={inputStyles.Input}
        inputStyle={inputStyles.Input__Input}
        errorStyle={inputStyles.Input__Input_Error}
      />
      <Input
        disabled={!canChangeFIO}
        text="Имя"
        placeHolder="Имя"
        type="text"
        name="firstname"
        register={props.register}
        required={{
          required: Constants.MESSAGE_INPUT_REQUIRED,
          pattern: {
            value: /^([а-яёА-ЯЁ]+)$/,
            message: 'Используйте только кириллицу',
          },
          minLength: {
            value: 2,
            message: 'Минимальное количество символов: 2',
          },
          maxLength: {
            value: 24,
            message: 'Максимальное количество символов: 24',
          },
        }}
        errors={props.errors}
        testid="firstname"
        dirty={props.dirty['firstname']}
        tabiIndex={2}
        className={styles.StaffForm__Input_Firstname}
        labelStyle={inputStyles.Input}
        inputStyle={`${inputStyles.Input__Input} ${inputStyles.Capitalize}`}
        errorStyle={inputStyles.Input__Input_Error}
      />
      <Input
        disabled={!canChangeEmail}
        text="Email"
        placeHolder="Электронная почта"
        type="email"
        name="email"
        register={props.register}
        required={{
          required: Constants.MESSAGE_INPUT_REQUIRED,
          pattern: {
            value:
              /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
            message: 'Поле должно содержать электронную почту',
          },
          maxLength: {
            value: 64,
            message: 'Максимальное количество символов: 64',
          },
          validate: (value: string) => {
            if (value.split('@')[0].indexOf('.') !== -1)
              return 'Email не должен содержать символ точки до знака @';
          },
        }}
        errors={props.errors}
        testid="email"
        dirty={props.dirty['email']}
        tabiIndex={5}
        className={styles.StaffForm__Input_Email}
        labelStyle={inputStyles.Input}
        inputStyle={inputStyles.Input__Input}
        errorStyle={inputStyles.Input__Input_Error}
      />
      <Input
        disabled={!canChangeFIO}
        text="Отчество"
        placeHolder="Отчество"
        type="text"
        name="midname"
        required={{
          pattern: {
            value: /^([а-яёА-ЯЁ ]+)$/,
            message: 'Используйте только кириллицу, пробелы',
          },
          maxLength: {
            value: 24,
            message: 'Максимальное количество символов: 24',
          },
        }}
        register={props.register}
        errors={props.errors}
        testid="midname"
        tabiIndex={3}
        labelStyle={inputStyles.Input}
        inputStyle={`${inputStyles.Input__Input} ${inputStyles.Capitalize}`}
        errorStyle={inputStyles.Input__Input_Error}
      />
      {configUpdateStaffNumber && (
        <Input
          disabled={!canChangeStaffNumber}
          text="Идентификатор сотрудника"
          placeHolder="Идентификатор сотрудника"
          type="text"
          name="staffNumber"
          register={props.register}
          errors={props.errors}
          testid="staffNumber"
          tabiIndex={6}
          labelStyle={inputStyles.Input}
          inputStyle={`${inputStyles.Input__Input} ${inputStyles.Capitalize}`}
          errorStyle={inputStyles.Input__Input_Error}
        />
      )}
    </div>
  );
};

export default InputsNew;
