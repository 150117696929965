import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './Header.module.scss';
import { BurgerMenu } from '../index';
import HeaderLogin from '../HeaderLogin/HeaderLogin';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import { getConfig } from '../../../api/ConfigApi';
import NavbarOld from '../NavBar/NavbarOld';
import NavbarNew from '../NavBar/NavbarNew';

type Props = {
  toggleSideMenu: () => void;
};

const Header = ({ toggleSideMenu }: Props): JSX.Element => {
  const [useNewRoleModel, setUseNewRoleModel] = useState(false);

  void getConfig().then((config) => {
    setUseNewRoleModel(config.useNewRoleModel === true);
  });
  return (
    <header className={styles.Wrapper}>
      <Link to="/" className={styles.Logo}>
        <Logo />
      </Link>
      <div className={styles.Navbar}>
        {useNewRoleModel ? <NavbarNew /> : <NavbarOld />}
      </div>

      <div className={styles.HeaderLogin}>
        <HeaderLogin />
      </div>

      <div className={styles.Burger}>
        <BurgerMenu toggle={toggleSideMenu} />
      </div>
    </header>
  );
};

export default Header;
