import { UserOrganization } from '../../../context/HandbookContext';
import { UserSellPoint } from '../../../context/UserContext';
import { User } from '../../../types';
import { SellPoint, Organization } from '../../../types/Organization';

export enum UserReducerActions {
  USER = 'USER',
  AUTHORIZED = 'AUTHORIZED',
  USER_SELL_POINT = 'USER_SELL_POINT',
  USER_ORGANIZATION = 'USER_ORGANIZATION',
}

interface AuthorizedAction {
  type: UserReducerActions.AUTHORIZED;
  payload: boolean;
}

interface UserAction {
  type: UserReducerActions.USER;
  payload?: User;
}

interface UserSellpointAction {
  type: UserReducerActions.USER_SELL_POINT;
  payload: SellPoint;
}

interface UserOrganizationAction {
  type: UserReducerActions.USER_ORGANIZATION;
  payload: Organization;
}

export type UserActions =
  | UserAction
  | AuthorizedAction
  | UserSellpointAction
  | UserOrganizationAction;

export interface UserState {
  user?: User;
  authorized: boolean;
  userSellPoint: UserSellPoint;
  userOrganization: UserOrganization;
}
