import React from 'react';
import defaultStyles from './Modal.module.scss';

type Props = {
  onCloseClick?: () => void;
  children: any;
  closeButtonStyle?: string;
  modalStyle?: string;
  overlaySwitchOff?: string;
  ref?: React.MutableRefObject<null>;
  block_testId?: string;
};

const Modal = React.forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element => {
    const withOutOverlay = props.overlaySwitchOff ? props.overlaySwitchOff : '';
    const closeButtonStyle = props.closeButtonStyle
      ? props.closeButtonStyle
      : '';
    const modalStyle = props.modalStyle ? props.modalStyle : '';

    return (
      <div
        className={`${defaultStyles.Modal} ${withOutOverlay}`}
        ref={props.ref}
      >
        {!props.overlaySwitchOff && (
          <div className={defaultStyles.Modal__Overlay} />
        )}
        <div
          className={`${defaultStyles.Modal__Block} ${modalStyle}`}
          ref={ref}
          data-testid={props.block_testId ? props.block_testId : "modal-container"}
        >
          {/* Кнопка закрытия модального окна */}
          {props.onCloseClick && (
            <button
              className={`${defaultStyles.Modal__ButtonClose} ${closeButtonStyle}`}
              onClick={props.onCloseClick}
              data-testid="modal-close-button"
            >
              <i
                className={`i-dp-close ${defaultStyles.Modal__ButtonCloseIcon}`}
              />
            </button>
          )}

          {props.children}
        </div>
      </div>
    );
  }
);

export default Modal;
