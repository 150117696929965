import { Config } from '../types/Config';
import defaultConfig from './config.json';

let promise: Promise<Config> | undefined = undefined;

async function getConfigImpl(): Promise<Config> {
  try {
    const response = await fetch('/config.json');
    const configJson = await response.json() as Config;
    const isBoolean = new RegExp('true|false', 'i');

    Object.keys(configJson).forEach(key => {
      if (isBoolean.test(configJson[key] as string)) {
        configJson[key] = toBoolean(configJson[key] as string);
      }
    });

    return Object.assign(defaultConfig, configJson);
  }
  catch (e) {
    console.error("Loading config.json filed. %s", (e as Error).message);
  }
  return defaultConfig;
}

export function getConfig(): Promise<Config> {
  if (!promise) {
    promise = getConfigImpl();
  }
  return promise;
}

const toBoolean = (value: string): boolean => Boolean(JSON.parse(value));