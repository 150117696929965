import React from 'react';
import styles from './DateCalendarMonth.module.scss';
import classNames from 'classnames';
import { DoubleDate } from '../../../../../types';
import { getMonthInfo } from '../../../../../utils/datetime';

type Props = {
  date: Date;
  dates: DoubleDate;
  chooseDate: () => void;
  onHoverDate?: (d: Date | null) => void;
  expectedIndex?: 0 | 1;
  targetDate?: Date | null;
  periodSet?: true | undefined;
  periodRestrictionTimeslot?: null | number;
};

const DateCalendarMonth = (props: Props): JSX.Element => {
  const weekDays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
  const {
    date,
    dates,
    chooseDate,
    onHoverDate,
    periodSet,
    periodRestrictionTimeslot,
  } = props;
  const [from, to] = dates;

  const today = new Date();
  const { year, monthIndex, daysInMonth, firstWeekDay } = getMonthInfo(date);

  const onMouseOver = (day: number) => {
    const date = new Date(year, monthIndex, day);
    if (onHoverDate) onHoverDate(date);
  };
  const onMouseLeave = () => {
    if (onHoverDate) onHoverDate(null);
  };

  const startDate = from;
  const endDate = to;

  //if (expectedIndex === 0) {
  //  startDate = to;
  //  endDate = targetDate || from;
  //}
  //if (expectedIndex === 1) {
  //  startDate = from;
  //  endDate = targetDate || to;
  //}

  const startTime = startDate ? startDate.getTime() : null;
  const endTime = endDate ? endDate.getTime() : null;

  const getDayClass = (i: number) => {
    const indexDate = new Date(year, monthIndex, i + 1);
    const isActive = dates.some((el) => el?.getTime() === indexDate.getTime());
    const isToday =
      indexDate.getDate() === today.getDate() &&
      indexDate.getFullYear() === today.getFullYear() &&
      indexDate.getMonth() === today.getMonth();
    const indexTime = indexDate.getTime();
    const dateLimit =
      periodRestrictionTimeslot &&
      periodRestrictionTimeslot !== null &&
      new Date(indexDate.getTime()).getTime() >
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        ).getTime() +
          (periodRestrictionTimeslot - 1) * 24 * 60 * 60 * 1000;

    //const daysDifference = !endTime && !startTime
    //    ? false
    //    : (indexTime - startTime!) / 60 / 60 / 24 / 1000;
    //const setPeriod = !startTime ? false : daysDifference < 0 || daysDifference > 3;
    const lessThanToday =
      new Date(indexTime) <
      new Date(today.getFullYear(), today.getMonth(), today.getDate());

    return classNames({
      [styles.day]: true,
      [styles.day_today]: isToday,
      [styles.day_active]: isActive,
      [styles.day_hover]:
        !endTime || !startTime
          ? false
          : (indexTime < endTime && indexTime > startTime) ||
            (indexTime > endTime && indexTime < startTime),
      [styles.day_nonhover]: !periodSet ? false : lessThanToday || dateLimit,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.week_days}>
        {weekDays.map((w) => (
          <div key={w} className={styles.week}>
            {w}
          </div>
        ))}
      </div>
      <div className={styles.days} onMouseLeave={onMouseLeave}>
        {Array(firstWeekDay)
          .fill('')
          .map((e, i) => (
            <div key={`empty_${i}`} />
          ))}
        {Array(daysInMonth)
          .fill('')
          .map((e, i) => (
            <button
              key={`day_${i}`}
              className={getDayClass(i)}
              onClick={chooseDate}
              onMouseOver={() => onMouseOver(i + 1)}
              data-testid={`date-calendar-choose-day-${
                i + 1
              }-month-${monthIndex + 1}`}
            >
              {i + 1}
            </button>
          ))}
      </div>
    </div>
  );
};

export default DateCalendarMonth;
