import React from 'react';
import styles from './MySalesResultRunny.module.scss';

type Props = {
  currentValue: number;
  maxValue: number;
  resultStatus: (value: number, max: number, status: string[]) => string;
};

const MySalesResultRunny = (props: Props): JSX.Element => {
  const { currentValue, maxValue, resultStatus } = props;
  const runnyStatuses = [
    styles.runny__goodBoy,
    styles.runny__notBad,
    styles.runny__whatAFckIsthis,
  ];

  const runnyPosition = (value: number, max: number) => {
    if (value && max && value < max) {
      const result = (100 / max) * value;
      return result;
    }
    if (value && max && value >= max) {
      return 100;
    }
    return 0;
  };

  const styleRunny = {
    width: `${runnyPosition(currentValue, maxValue)}px`,
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.line}`} />
      <div
        className={`${styles.runny} ${resultStatus(
          currentValue,
          maxValue,
          runnyStatuses
        )}`}
        style={styleRunny}
      />
    </div>
  );
};

export default MySalesResultRunny;
