import React from 'react';
import styles from './ModalFrame.module.scss';

type Props = {
  onCloseClick?: () => void;
  children: JSX.Element;
  closeButtonStyle?: string;
  modalStyle?: string;
};

const ModalFrame = (props: Props): JSX.Element => {
  const closeButtonStyle = props.closeButtonStyle ? props.closeButtonStyle : '';
  const modalStyle = props.modalStyle ? props.modalStyle : '';
  return (
    <div className={`${styles.Modal__Block} ${modalStyle}`}>
      {/* Кнопка закрытия модального окна */}
      {props.onCloseClick && (
        <button
          className={`${styles.Modal__ButtonClose} ${closeButtonStyle}`}
          onClick={props.onCloseClick}
          data-testid="modal-frame-close-button"
        >
          <i className={`i-dp-close ${styles.Modal__ButtonCloseIcon}`} />
        </button>
      )}

      {props.children}
    </div>
  );
};

export default ModalFrame;
