import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import NotesModal from '../_modals/NotesModal/NotesModal';
import styles from './Notes.module.scss';

type Props = {
  notesStyle?: string;
};
const Notes = (props: Props): JSX.Element => {
  const notesStyle = props.notesStyle ? props.notesStyle : '';
  const initNotes = localStorage.getItem('notes');
  const [notes, setNotes] = useState(initNotes || '');
  const [modal, setModal] = useState(false);
  const { url } = useRouteMatch();

  const buttonPosition =
    window.location.pathname === '/new_order'
      ? ''
      : styles.Notes__Button_NotNewOrderPath;
  return (
    <div className={`${styles.Notes} ${notesStyle}`}>
      <div
        data-testid="notes-open-modal"
        className={`${styles.Notes__Button} ${buttonPosition}`}
        onClick={() => setModal(true)}
      >
          Заметки
          <i className="i-dp-edit" />
      </div>
      {window.location.pathname === `${url}` && notes !== '' && (
        <textarea
          readOnly
          name="notes"
          value={notes}
          className={styles.Notes__TextArea}
        />
      )}
      {modal && (
        <NotesModal close={() => setModal(false)} setNotes={setNotes} />
      )}
    </div>
  );
};

export default Notes;
