import React from 'react';
import styles from './LoaderWithOverlay.module.scss';
import { LoaderMatrix } from '../../_layout';

const LoaderWithOverlay = (): JSX.Element => {
  return (
    <div
      className={styles.Loader__LoaderContainer}
      data-testid="loader-with-overlay"
    >
      <div
        className={`${styles.Loader__Overlay} ${styles.Loader__Overlay_Loader}`}
      />
      <div className={styles.Loader__Loader}>
        <LoaderMatrix />
      </div>
    </div>
  );
};

export default LoaderWithOverlay;
