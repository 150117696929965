import React from 'react';
import styles from './BurgerMenu.module.scss';

type Props = {
  toggle: () => void;
};

const BurgerMenu = ({ toggle }: Props): JSX.Element => {
  return (
    <div className={styles.Wrapper}>
      <button
        className={styles.Burger}
        onClick={toggle}
        data-testid="burger-menu"
      >
        {/*TODO: <i className="i-dp-menu" />*/}
        <svg viewBox="0 0 100 80" width="20" height="20">
          <rect width="100" height="8" rx="4" ry="4" y="0" x="0" />
          <rect width="100" height="8" rx="4" ry="4" y="30" x="0" />
          <rect width="100" height="8" rx="4" ry="4" y="60" x="0" />
        </svg>
      </button>
    </div>
  );
};

export default BurgerMenu;
