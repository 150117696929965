type Children = JSX.Element | JSX.Element[] | undefined;
export type UseSlotsResult = Record<string, JSX.Element>;

export const useSlots = (
  slotNames: string[],
  children: Children
): UseSlotsResult => {
  const slots = {} as UseSlotsResult;
  if (!children) return slots;
  const arrChildren = Array.isArray(children) ? children : [children];

  slotNames.forEach((name) => {
    const element = arrChildren.find((el: JSX.Element) => {
      const props = el?.props as Record<string, any>;
      return props?.slot === name;
    });
    if (element) slots[name] = element;
  });

  return slots;
};
