import React from 'react';
import styles from './InfoText.module.scss';

type Props = {
  text: string;
  icon: string;
  iconPosition: 'left' | 'right';
  disabled: boolean;
  onClick: () => void;
};

const InfoText = (props: Props): JSX.Element => {
  const { text, iconPosition, icon } = props;

  const position = 'styles.Wrapper__' + iconPosition;
  const iconPos = 'styles.Icon__' + iconPosition;
  const iconClasses = `${styles.Icon} ${iconPos} i-dp-${icon}`;
  const wrapperClasses = `${styles.Wrapper} ${position} `;

  return (
    <span
      className={wrapperClasses}
      onClick={props.onClick}
      data-testid="info-text"
    >
      {text}
      <i className={iconClasses} />
    </span>
  );
};

InfoText.defaultProps = {
  iconPosition: 'right',
  icon: '',
  disabled: false,
};

export default InfoText;
