import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { TextAreaDP } from '../../../_ui';
import ButtonMTS from '../../../_ui/ButtonMTS/ButtonMTS';
import styles from './HelpReview.module.scss';
import { useDropzone } from 'react-dropzone';
import { ReviewFile } from '../../../../types/Help';
import { useAxios } from '../../../../api/useAxios';
import { ApiErrorData } from '../../../../types';
import { InfoModal, ProblemModal } from '../../../_modals';
import classNames from 'classnames';

type Props = {
  setLoader: (data: boolean) => void;
  onCloseClick: () => void;
};

const HelpReview = (props: Props): JSX.Element => {
  const { setLoader, onCloseClick } = props;

  const [comment, setComment] = useState<string>('');
  const [errorText, setErrorText] = useState<boolean>(true);
  const [modalError, setModalError] = useState<ApiErrorData>();
  const [filesError, setFilesError] = useState<string>('');
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);
  const [files, setFiles] = useState<ReviewFile[]>([]);

  const successContent = {
    iconStyle: 'i-dp-done',
    style: `${styles.modal__icon__success}`,
    header: 'Отзыв успешно отправлен',
    buttonText: 'Закрыть',
    buttonStyle: `${styles.modal__buttonClose}`,
  };

  const { send: sendFeedback, ...sendFeedbackAxios } = useAxios(
    '/feedback',
    'post'
  );

  useEffect(() => {
    if (sendFeedbackAxios.fullResponse?.status === 200) {
      setLoader(false);
      setModalSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendFeedbackAxios.fullResponse]);

  useEffect(() => {
    if (sendFeedbackAxios.error) {
      let error;
      if (typeof sendFeedbackAxios.error.data === 'string') {
        error = sendFeedbackAxios.error;
      } else {
        error = sendFeedbackAxios.error.data;
      }
      setLoader(false);
      setModalError(error as ApiErrorData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendFeedbackAxios.error]);

  const calculateFileWeight = (file: number) => {
    if (file < 1024) {
      return `${file} Байт`;
    } else if (1024 <= file && file < Math.pow(1024, 2)) {
      return `${Math.round(file / 1024)} КБайт`;
    } else if (Math.pow(1024, 2) <= file && file < Math.pow(1024, 3)) {
      return `${Math.round(file / Math.pow(1024, 2))} МБайт`;
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        [...files, acceptedFiles]
          .flat()
          .filter(
            (offer: ReviewFile, index, self) =>
              index === self.findIndex((i: ReviewFile) => i.name === offer.name)
          )
      );
    },
    [files]
  );

  const middleIndex = Math.round(files.length / 2);
  const column1 = files.slice(0, middleIndex);
  const column2 = files.slice(middleIndex, files.length + 1);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/webp': ['.webp'],
    },
  });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const deleteFile = (file: ReviewFile) => {
    setFiles(files.filter((item) => item.name !== file.name));
  };

  const filesSize =
    files.map((item) => item.size).reduce((total, value) => total + value, 0) /
    Math.pow(1024, 2);

  const toBase64 = (file): Promise<string> =>
    new Promise<string>((resolve) => {
      const reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve(reader.result ? reader.result?.toString() : '');
    });

  const send = async () => {
    setLoader(true);
    const images: string[] = [];
    for (const file of files) {
      images.push(await toBase64(file));
    }
    sendFeedback({
      feedbackText: comment,
      images: images.map((image) => {
        const reg = /^data:image\/[a-z]+;base64,/i;
        const newImage = image.replace(reg, '');
        return newImage;
      }),
    });
  };

  const inputClass = () =>
    classNames({
      [styles.buttonUpload]: true,
      [styles.buttonUpload_disabled]: files.length === 10 || filesSize > 10,
    });

  useEffect(() => {
    if (comment.length > 0) {
      setErrorText(false);
    } else {
      setErrorText(true);
    }
  }, [comment]);

  useEffect(() => {
    if (filesSize > 10) {
      setFilesError('Размер файлов больше 10МБ');
    } else if (files.length > 10) {
      setFilesError('Кол-во файлов больше 10шт');
    } else {
      setFilesError('');
    }
  }, [filesSize, files]);

  function onPromise<T>(promise: () => Promise<T>) {
    return () => {
      if (promise) {
        promise().catch((error) => {
          console.log('Unexpected error', error);
        });
      }
    };
  }

  return (
    <div>
      <h3 className={styles.header}>Оставить отзыв</h3>
      <div className={styles.textArea}>
        <label
          className={`${styles.textArea__label} ${styles.textArea__label_top}`}
        >
          Опишите проблему или предложение
        </label>
        <TextAreaDP
          value={comment}
          rows={5}
          textareaStyle={styles.textArea__textArea}
          textinputStyle={styles.textArea__textArea_label}
          onChange={handleChange}
          data-testid="help-review-text"
        />
        <label
          className={`${styles.textArea__label} ${styles.textArea__label_bottom}`}
        >
          Основная информация с текущей страницы будет автоматически прикреплена
          к сообщению
        </label>
      </div>

      {files.length > 0 && (
        <div className={styles.files}>
          <div className={styles.files__container}>
            {column1.map((file) => (
              <div className={styles.file}>
                <i className={`i-dp-file ${styles.file__prefix}`} />
                <div className={styles.file__info}>
                  <label className={styles.file__name}>{file.name}</label>
                  <label className={styles.file__weight}>
                    {calculateFileWeight(file.size)}
                  </label>
                </div>

                <i
                  className={`i-dp-close ${styles.file__deleteButton}`}
                  onClick={() => deleteFile(file)}
                  data-testid="help-review-delete-file"
                />
              </div>
            ))}
          </div>
          <div className={styles.files__container}>
            {column2.map((file) => (
              <div className={styles.file}>
                <i className={`i-dp-file ${styles.file__prefix}`} />
                <div className={styles.file__info}>
                  <label className={styles.file__name}>{file.name}</label>
                  <label className={styles.file__weight}>
                    {calculateFileWeight(file.size)}
                  </label>
                </div>

                <i
                  className={`i-dp-close ${styles.file__deleteButton}`}
                  onClick={() => deleteFile(file)}
                  data-testid="help-review-delete-file"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={inputClass()} {...getRootProps()}>
        <input {...getInputProps()} disabled={files.length === 10} />
        {isDragActive ? (
          <p>Перетащить скриншот сюда...</p>
        ) : (
          <p>Загрузить скриншот</p>
        )}
      </div>
      {filesError.length > 0 ? (
        <label
          className={`${styles.textArea__label} ${styles.textArea__label_bottom} ${styles.textArea__label_error}`}
        >
          {filesError}
        </label>
      ) : (
        <label
          className={`${styles.textArea__label} ${styles.textArea__label_bottom}`}
        >
          Кол-во файлов: не больше 10шт. Cуммарный размер: не больше 10МБ.
        </label>
      )}

      <div className={styles.buttonMenu}>
        <ButtonMTS
          card="primary"
          onClick={onPromise(send)}
          disabled={errorText || filesError.length > 0}
          additionalstyle={styles.buttonMenu__buttonMTS}
          testid="help-review-send"
        >
          Отправить
        </ButtonMTS>
        <ButtonMTS
          card="outline"
          additionalstyle={styles.buttonMenu__buttonMTS}
          onClick={() => onCloseClick()} //ЗАМЕНИТь
          testid="help-review-cancel"
        >
          Отменить
        </ButtonMTS>
      </div>

      {modalSuccess && (
        <InfoModal
          content={successContent}
          onButtonClick={() => {
            setModalSuccess(false);
            setComment('');
            setFiles([]);
          }}
          onCloseClick={() => {
            setModalSuccess(false);
            setComment('');
            setFiles([]);
          }}
        />
      )}

      {modalError && (
        <ProblemModal
          error={modalError}
          onCloseClick={() => {
            setModalError(undefined);
          }}
        />
      )}
    </div>
  );
};

export default HelpReview;
