import React from 'react';
import defaultStyles from './InfoModal.module.scss';
import { Modal } from '..';
import ReactDOM from 'react-dom';

type Props = {
  onCloseClick?: () => void;
  onButtonClick?: () => void;
  content: InfoModalContent;
};

export type InfoModalContent = {
  style?: string | null;
  header?: string | null;
  buttonStyle?: string | null;
  buttonText?: string | null;
  buttonIcon?: string | null;
  information?: string[] | null;
  informationStyle?: string;
  question?: string | null;
  iconStyle?: string | null;
  specialButton?: JSX.Element | null;
  orderId?: string | null;
  id?: string;
};

const InfoModal = (props: Props): JSX.Element => {
  const { content } = props;

  const infoModalPortal = document.getElementById('infoModal');
  const informationStyle = content.informationStyle
    ? content.informationStyle
    : '';

  if (infoModalPortal) {
    return ReactDOM.createPortal(
      <Modal onCloseClick={props.onCloseClick}>
        {content && (
          <div className={defaultStyles.InfoModal__Block}>
            {/* Заголовок модального окна */}
            <span
              className={`${content.iconStyle || ''} ${content.style || ''} ${
                defaultStyles.InfoModal__Icon
              }`}
            />
            <h3
              className={defaultStyles.InfoModal__Header}
              style={{ textAlign: 'center' }}
            >
              {content.header}
            </h3>

            {/* Номер заявки */}
            {content.orderId ? (
              <div className={defaultStyles.InfoModal__Text}>
                {content.orderId}
              </div>
            ) : null}

            {/* Содержимое модального окна */}
            {content.information?.length ? (
              <div
                className={`${defaultStyles.InfoModal__Text} ${informationStyle}`}
              >
                {content.information?.map((text, index) => (
                  <p key={`${text}-${index}`}>{text}</p>
                ))}
              </div>
            ) : null}

            {/* Уточняющее собщение модального окна */}
            {content.question ? (
              <p className={defaultStyles.InfoModal__Text}>
                {content.question}
              </p>
            ) : null}

            {content.specialButton}

            {/* Кнопка */}
            <button
              className={content.buttonStyle || defaultStyles.InfoModal__Button}
              onClick={props.onButtonClick}
              data-testid="info-modal-button"
            >
              <i
                className={`${content.buttonIcon || ''} ${
                  defaultStyles.InfoModal__ButtonIcon
                }`}
              />
              {content.buttonText}
            </button>
          </div>
        )}
      </Modal>,
      infoModalPortal
    );
  }

  return <div />;
};

export default InfoModal;
