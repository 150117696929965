import { storeDP } from '../../../../store/store';
import { Offering } from '../../../../types';
import { CountEquipment } from '../../../../types/OrderCommentCalculator';
import { equipments } from './../OrderCommentCalculatorReborn/components/Arrays/Equipments';

export const createAdditionalOrder = (
  sortedAdditionalOffers: Offering[],
  additionalOffers: (additionalOffers: Offering[]) => Offering[]
) => {
  const { offerings, calculatorEquipments, orderForm } =
    storeDP.getState().createOrderReducer;

  // eslint-disable-next-line prefer-const
  let groups: { group: string; id: string[] }[] = [];

  for (let i = 0; i < equipments.length; i++) {
    const ids = equipments[i].equipments
      .map((item) => {
        if (item.offerId) {
          return item.offerId;
        } else {
          return '';
        }
      })
      .filter(
        (offer, index, self) => index === self.findIndex((i) => i === offer)
      );
    //Создание отдельных групп для SIM и MNP
    if (
      equipments[i].type === 'additionalSim' ||
      equipments[i].type === 'tvequipments' ||
      equipments[i].type === 'cameras' ||
      equipments[i].type === 'routers'
    ) {
      //Доп СИМ
      if (equipments[i].type === 'additionalSim') {
        equipments[i].equipments.forEach((item) => {
          groups.push({
            group: `${equipments[i].type}-${item.id}`,
            id: item.offerId ? [item.offerId] : [''],
          });
        });
      }

      //ТВ-Оборудование
      if (equipments[i].type === 'tvequipments') {
        const hasOptions = equipments[i].equipments.find(
          (item) => item.options
        );

        const hasPLC = equipments[i].equipments.find(
          (item) => item.offerId === '1-9S4GGBX5'
        );

        const hasPLCUsed = equipments[i].equipments.find(
          (item) => item.offerId === '1-A9MKYQCZ'
        );

        //PLC-адаптер
        if (hasPLC || hasPLCUsed) {
          const hasGroup = groups.find(
            (group) => group.group === `${equipments[i].type}-PLC`
          );

          if (!hasGroup) {
            groups.push({
              group: `${equipments[i].type}-PLC`,
              id: [
                hasPLC ? hasPLC.offerId ?? '' : '',
                hasPLCUsed ? hasPLCUsed.offerId ?? '' : '',
              ],
            });
          } else {
            hasGroup.id.push(hasPLC ? hasPLC.offerId ?? '' : '');
            hasGroup.id.push(hasPLCUsed ? hasPLCUsed.offerId ?? '' : '');
          }
        }
        if (hasOptions) {
          if (hasOptions?.options && hasOptions?.options[0]) {
            groups.push({
              group: `${equipments[i].type}-multiroom`,
              id: hasOptions?.options[0].offerId
                ? [hasOptions?.options[0].offerId]
                : [''],
            });
          }
        }

        const tvIds = ids
          .filter((item) => item !== '1-A9MKYQCZ') //Отфильтровываем ОТТ-приставку
          .filter((item) => item !== '1-9S4GGBX5'); //Отфильтровываем PLC-приставку

        groups.push({
          group: `${equipments[i].type}-sell`,
          id: tvIds, //Отфильтровываем PLC-приставку
        });

        groups.push({
          group: `${equipments[i].type}-installment`,
          id: tvIds, //Отфильтровываем PLC-приставку
        });
      }

      if (equipments[i].type === 'cameras') {
        equipments[i].equipments.forEach((item) => {
          item.usage.forEach((usage) => {
            const hasGroup = groups.find(
              (group) => group.group === `${equipments[i].type}-${usage.id}`
            );
            if (!hasGroup) {
              groups.push({
                group: `${equipments[i].type}-${usage.id}`,
                id: item.offerId ? [item.offerId] : [''],
              });
            } else {
              hasGroup.id.push(item.offerId ? item.offerId : '');
            }
          });
        });
      }

      if (equipments[i].type === 'routers') {
        groups.push({
          group: `${equipments[i].type}-sell`,
          id: ids, //Отфильтровываем PLC-приставку
        });

        groups.push({
          group: `${equipments[i].type}-installment`,
          id: ids, //Отфильтровываем PLC-приставку
        });
      }
    } else {
      groups.push({
        group: equipments[i].type,
        id: ids,
      });
    }
  }

  const sortAdditionalOffersByGroups: {
    group: string;
    comment: string;
    additionalOffers: Offering[];
  }[] = groups.map((group) => {
    const additionalOffers = sortedAdditionalOffers
      .filter((offer) => group.id.includes(offer.id))
      .filter((item) => {
        //Фильтрация ТВ-оборудование продажа
        if (
          group.group === `tvequipments-sell` ||
          group.group === `routers-sell`
        ) {
          if (item.usage === 'sell') {
            return true;
          } else {
            return false;
          }
          //Фильтрация ТВ-оборудование рассрочка
        } else if (
          group.group === 'tvequipments-installment' ||
          group.group === `routers-installment`
        ) {
          if (
            item.usage === 'installment_24' ||
            item.usage === 'installment_12'
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            (item.calculatorId === 'plc-equipment' && item.usage === 'rent') ||
            (item.id === '1-ABPB903D' && item.usage === 'rent')
          ) {
            return false;
          } else {
            return true;
          }
        }
      });

    const withoutMultiroomComment = additionalOffers.map((offer) => {
      return offer.comment;
    });

    const withMultiroomComment = calculatorEquipments
      .filter(
        (equipment) =>
          equipment.id === 'tv-equipment_add' ||
          equipment.id === 'tv-equipment_add_used'
      )
      .map((equipment) => equipment.comment || '');

    const comment =
      group.group === 'tvequipments-multiroom'
        ? [
            ...withMultiroomComment,
            ...additionalOffers.map((offer) => offer.comment || ''),
          ].join('\n')
        : withoutMultiroomComment.join('\n');

    return {
      group: group.group,
      comment: Array.isArray(comment) ? comment.join('\n') : comment,
      additionalOffers,
    };
  });

  const createRequestAdditionals: {
    offerings: Offering[];
    comment: string;
  }[] = sortAdditionalOffersByGroups
    .map((group) => {
      if (group.additionalOffers.length) {
        // eslint-disable-next-line prefer-const
        let createOrderArray: Offering[] = [];

        //Поиск дублей
        const hasDublicates = (offerings: Offering[]) => {
          const arr = offerings.map((item) => item.id);
          return new Set(arr).size !== arr.length;
        };

        //Сумма дублей
        const sumDublicate = (offerings: Offering[]): Offering[] => {
          const result = {};

          offerings.forEach((offering) => {
            const id = offering.id;
            const quantity = offering.quantity;

            if (result[id]) {
              ((result[id] as Offering).quantity as number) +=
                quantity as number;
            } else {
              result[id] = { ...offering, quantity: quantity };
            }
          });
          return Object.values(result);
        };

        const hasSellOffering = offerings.find(
          (offering) => offering.id === '1-A69G0QZG'
        );

        const hasSellUsage = group.additionalOffers.some(
          (offer) => offer.usage === 'sell'
        );

        const hasInstallmentOffering = offerings.find(
          (offering) => offering.id === '1-A69G0QZI'
        );

        const hasInstallmentUsage = group.additionalOffers.some(
          (offer) =>
            offer.usage === 'installment_12' ||
            offer.usage === 'installment_24' ||
            offer.usage === 'installment_36'
        );
        const hasRentOffering = offerings.find(
          (offering) => offering.id === '1-A69G0QZE'
        );

        const hasRentUsage = group.additionalOffers.some(
          (offer) => offer.usage === 'rent'
        );

        const hasMNPSIMOffering = offerings.find(
          (offering) => offering.id === '1-9YGKQUOB'
        );

        const hasMNPUsage = group.additionalOffers.some(
          (offer) => offer.usage === 'mnp'
        );
        const hasSIMUsage = group.additionalOffers.some(
          (offer) => offer.usage === 'sendsim'
        );

        const hasOptionUsage = group.additionalOffers.some(
          (offer) => offer.usage === 'option'
        );

        //Есть ли ID для создания доп СЗ
        const hasCreateOrderId = group.additionalOffers.some(
          (item) => item.createOrderId
        );

        const hasBaseMultiroomOffering =
          orderForm?.baseOffer?.id === '1-9QHCX3WE';

        if (hasCreateOrderId || hasOptionUsage) {
          //КОСТЫЛЬ относится к Multiroom
          //Добавляем предложения в массив для новой СЗ
          const addOfferingToCreateOrderArray = (offerings: Offering[]) => {
            const newOfferings = offerings.filter((item) => {
              if (
                item.id === '1-9QHCX3VM' &&
                (item.usage === 'rent' || item.usage === 'installment_36')
              ) {
                return false;
              } else {
                return true;
              }
            });

            if (hasDublicates(newOfferings)) {
              sumDublicate(newOfferings).forEach((offer) => {
                createOrderArray.push(offer);
              });
            } else {
              newOfferings.forEach((offer) => createOrderArray.push(offer));
            }
          };

          //Создаем новую СЗ
          const returnNewOrder = (offering: Offering) => {
            addOfferingToCreateOrderArray(group.additionalOffers);
            createOrderArray.unshift(offering);
            return {
              offerings: createOrderArray,
              comment: group.comment,
            };
          };

          //Выставление приоритета базового компонента
          if (hasSellUsage && hasSellOffering) {
            return returnNewOrder(hasSellOffering);
          }

          if (hasInstallmentUsage && hasInstallmentOffering) {
            return returnNewOrder(hasInstallmentOffering);
          }
          if (hasRentUsage && hasRentOffering) {
            return returnNewOrder(hasRentOffering);
          }

          //Отдельный СЗ для MNP
          if (hasMNPUsage && hasMNPSIMOffering) {
            return returnNewOrder(hasMNPSIMOffering);
          }
          //Отдельный СЗ для SIM
          if (hasSIMUsage && hasMNPSIMOffering) {
            return returnNewOrder(hasMNPSIMOffering);
          }

          // ОЧЕРЕДНОЙ ОТДЕЛЬНЫЙ КОСТЫЛЬ для MUTIROOM
          if (hasOptionUsage && !hasBaseMultiroomOffering) {
            const filteredAddTvEquipment: CountEquipment[] = [];

            for (let i = 0; i < calculatorEquipments.length; i++) {
              if (
                calculatorEquipments[i].id === 'tv-equipment_add' ||
                calculatorEquipments[i].id === 'tv-equipment_add_used'
              ) {
                filteredAddTvEquipment.push(calculatorEquipments[i]);
              }
            }

            if (filteredAddTvEquipment.length) {
              const hasMultiroomOffering = offerings.find(
                (item) => item.id === '1-9QHCX3WE'
              );

              if (hasMultiroomOffering) {
                return returnNewOrder(hasMultiroomOffering);
              }
            }
          }

          return { offerings: [], comment: '' };
        } else {
          return { offerings: [], comment: '' };
        }
      } else {
        return { offerings: [], comment: '' };
      }
    })
    .filter((item) => item?.offerings.length)
    .map((item) => {
      return {
        offerings: additionalOffers(item.offerings),
        comment: item.comment,
      };
    });

  return createRequestAdditionals;
};
