import { CalculateBasket } from '../../../types';
import {
  CardContent,
  Customer,
  ItemPrices,
  Error,
} from '../../../types/Subscriptions';

export enum SubscriptionReducerActions {
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_ITEM_PRICES_SUMMARY = 'SET_ITEM_PRICES_SUMMARY',
  SET_CALCULATE_BASKET_PRICE = 'SET_CALCULATE_BASKET_PRICE',
  SET_ERROR = 'SET_ERROR',
  SET_FLAGS = 'SET_FLAGS',
  SET_CARDS_CONTENT = 'SET_CARDS_CONTENT',
  TOGGLE_LOADER = 'TOGGLE_LOADER',
}

interface SetCustomer {
  type: SubscriptionReducerActions.SET_CUSTOMER;
  payload: Customer | null;
}

interface SetItemPricesSummary {
  type: SubscriptionReducerActions.SET_ITEM_PRICES_SUMMARY;
  payload: ItemPrices[];
}

interface ToggleLoader {
  type: SubscriptionReducerActions.TOGGLE_LOADER;
  payload: boolean;
}

interface SetError {
  type: SubscriptionReducerActions.SET_ERROR;
  payload: Error[];
}

interface SetFlags {
  type: SubscriptionReducerActions.SET_FLAGS;
  payload: Error[];
}

interface SetCardsContent {
  type: SubscriptionReducerActions.SET_CARDS_CONTENT;
  payload: CardContent[];
}

interface SetCalculateBasketPrice {
  type: SubscriptionReducerActions.SET_CALCULATE_BASKET_PRICE;
  payload: CalculateBasket | undefined;
}

export type SubscriptionActions =
  | SetCustomer
  | SetItemPricesSummary
  | ToggleLoader
  | SetError
  | SetFlags
  | SetCardsContent
  | SetCalculateBasketPrice;
export interface SubscriptionState {
  customer: Customer | null;
  itemPricesSummary: ItemPrices[];
  loading: boolean;
  errors: Array<Error>;
  flags: Array<Error>;
  cardsContent: CardContent[];
  calculateBasketPrice?: CalculateBasket;
}
