import axios from 'axios';

const baseUrl = process.env.REACT_APP_AUTH_DEV;

export const authClient = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

export const isAuthenticated = () =>
  authClient.get('/oauth2/auth').then((r) => r.status === 202);

const buildUrl = (path: string, params: Record<string, string>) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const url = new URL(path, new URL(baseUrl!, window.location.href));
  for (const name in params) {
    url.searchParams.append(name, encodeURI(params[name]));
  }
  return url;
};

export const getLogoutUrl = (callback?: string) => {
  const signOutUrl = buildUrl('oauth2/sign_out', {
    rd: callback ?? window.location.href,
  }).href;
  return buildUrl('account/logout', { redirect_url: signOutUrl }).href;
};

export const getLoginUrl = () => {
  return buildUrl('oauth2/start', { rd: window.location.href }).href;
};
