export const regions: string[] = [
  'Алтайский',
  'Амурская',
  'Архангельская',
  'Астраханская',
  'Белгородская',
  'Владимирская',
  'Вологодская',
  'Волгоградская',
  'Воронежская',
  'Забайкальский',
  'Ивановская',
  'Иркутская',
  'Калужская',
  'Камчатский',
  'Кемеровская',
  'Кировская',
  'Краснодарский',
  'Красноярский',
  'Курганская',
  'Курская',
  'Ленинградская',
  'Липецкая',
  'Магаданская',
  'Марий Эл',
  'Московская',
  'Нижегородская',
  'Новгородская',
  'Новосибирская',
  'Омская',
  'Оренбургская',
  'Орловская',
  'Пензенская',
  'Пермский',
  'Приморский',
  'Башкортостан',
  'Бурятия',
  'Саха /Якутия/',
  'Татарстан',
  'Тыва',
  'Хакасия',
  'Ростовская',
  'Рязанская',
  'Саратовская',
  'Свердловская',
  'Смоленская',
  'Ставропольский',
  'Тамбовская',
  'Тверская',
  'Тульская',
  'Тюменская',
  'Удмуртская',
  'Ульяновская',
  'Ханты-Мансийский Автономный округ - Югра',
  'Хабаровский',
  'Челябинская',
  'Ямало-Ненецкий',
  'Ярославская',
  'Москва',
  'Санкт-Петербург',
];
