import React, { useState, useRef, KeyboardEvent, useEffect } from 'react';
import styles from './DateRangeCalendar.module.scss';
import { datetime } from '../../../../../utils';
import DateCalendarMonth from '../DateCalendarMonth/DateCalendarMonth';
import { DoubleDate } from '../../../../../types';

type Props = {
  mainDate: Date;
  dates: DoubleDate;
  chooseDate: () => void;
  onHoverDate?: (d: Date | null) => void;
  close?: () => void;
  expectedIndex?: 0 | 1;
  targetDate?: Date | null;
  periodSet?: true | undefined;
  previosMonth?: boolean;
  periodRestrictionTimeslot?: null | number;
};

const DateRangeCalendar = (props: Props): JSX.Element => {
  const ref = useRef(null);
  const {
    dates,
    close,
    chooseDate,
    onHoverDate,
    expectedIndex,
    targetDate,
    periodSet,
    previosMonth,
  } = props;

  // INFO +++ Month controls
  const initMonthDatesPrev = [
    datetime.toZeroHour(datetime.addMonth(-1, dates[0] || new Date())),
    datetime.toZeroHour(dates[0] || new Date()),
  ];

  const initMonthDatesNext = [
    datetime.toZeroHour(dates[0] || new Date()),
    datetime.toZeroHour(datetime.addMonth(1, dates[0] || new Date())),
  ];

  const [monthDates, setMonthDates] = useState(
    previosMonth ? initMonthDatesPrev : initMonthDatesNext
  );
  const changeMonth = (value: number) => () => {
    const firstDate = datetime.addMonth(value, monthDates[0]);
    const secDate = datetime.addMonth(1, firstDate);

    setMonthDates([firstDate, secDate]);
  };

  // INFO Close on Escape
  useEffect(() => {
    if (!close) return;
    const closeCalendar = (e) => {
      if ((e as KeyboardEvent).key === 'Escape' && close) close();
    };
    document.addEventListener('keydown', closeCalendar);
    return () => document.removeEventListener('keydown', closeCalendar);
  }, [close]);

  const parseTitleMoth = (date: Date | null) => {
    date = date ? date : new Date();
    const monthText = date.toLocaleDateString('ru-RU', { month: 'long' });
    return `${monthText} ${date.getFullYear()}`;
  };

  return (
    <article
      className={styles.container}
      ref={ref}
      data-testid="date-range-calendar-container"
    >
      <header className={styles.header}>
        {periodSet && (
          <button
            className={styles.clear_btn}
            onClick={close}
            tabIndex={-1}
            type="button"
          >
            <i className="i-dp-close" />
          </button>
        )}
        <div className={`${styles.column} ${styles.column_left}`}>
          <div className={styles.columnHeader}>
            <button
              className={styles.month_btn}
              onClick={changeMonth(-1)}
              data-testid="date-range-calendar-month-minus"
            >
              <i className={`i-dp-arrow-up ${styles.shevron_left}`} />
            </button>
            <span className={styles.month_left}>
              {parseTitleMoth(monthDates[0])}
            </span>
          </div>
          <DateCalendarMonth
            date={monthDates[0] || new Date()}
            dates={dates}
            chooseDate={() => {
              chooseDate();
              close && close();
            }}
            onHoverDate={onHoverDate}
            expectedIndex={expectedIndex}
            targetDate={targetDate}
            periodSet={periodSet}
            periodRestrictionTimeslot={props.periodRestrictionTimeslot}
          />
        </div>

        <div className={`${styles.column} ${styles.column_right}`}>
          <div className={styles.columnHeader}>
            <span className={styles.month_right}>
              {parseTitleMoth(monthDates[1])}
            </span>
            <button
              className={styles.month_btn}
              onClick={changeMonth(1)}
              data-testid="date-range-calendar-month-plus"
            >
              <i className={`i-dp-arrow-up ${styles.shevron_right}`} />
            </button>
          </div>
          <DateCalendarMonth
            date={monthDates[1] || datetime.addMonth(1)}
            dates={dates}
            chooseDate={() => {
              chooseDate();
              close && close();
            }}
            onHoverDate={onHoverDate}
            expectedIndex={expectedIndex}
            targetDate={targetDate}
            periodSet={periodSet}
            periodRestrictionTimeslot={props.periodRestrictionTimeslot}
          />
        </div>
      </header>
      {/* <div style={{ display: 'flex', columnGap: 40 }}>
        <DateCalendarMonth
          date={monthDates[0] || new Date()}
          dates={dates}
          chooseDate={chooseDate}
          onHoverDate={onHoverDate}
          expectedIndex={expectedIndex}
          targetDate={targetDate}
        />
        <DateCalendarMonth
          date={monthDates[1] || datetime.addMonth(1)}
          dates={dates}
          chooseDate={chooseDate}
          onHoverDate={onHoverDate}
          expectedIndex={expectedIndex}
          targetDate={targetDate}
        />
      </div> */}
    </article>
  );
};
export default DateRangeCalendar;
