import { Tariff } from '../../constants';
import {
  CreateOrderActions,
  CreateOrderState,
  CreateOrderReducerActions,
} from './types/createOrderTypes';

const initialState: CreateOrderState = {
  apps: [],
  basket: undefined,
  address: undefined,
  previousBasketAddress: undefined,
  techCapabilities: null,
  techCapabilitiesEntries: '',
  requestAndContracts: undefined,
  clientFormErrors: [],
  tariff: Tariff.Simple,
  orderForm: undefined,
  orderData: undefined,
  orderStep: undefined,
  calculateBasket: undefined,
  basketAlternateOfferings: [],
  calculatorForm: undefined,
  calculatorEquipments: [],
  calculatorSelectedPromo: [],
  autoconvergentGroup: undefined,
  withoutCheckTechCapability: false,
  filteredPackages: [],
  currentProduct: undefined,
  regionNamesAllowedInstalment: [],
  offerings: [],
  redPackData: null,
  errorRedPack: null,
  isConvergentCheckId: null,
  bundleType: null,
};

export const createOrderReducer = (
  state = initialState,
  action: CreateOrderActions
): CreateOrderState => {
  switch (action.type) {
    case CreateOrderReducerActions.SET_APPS:
      return { ...state, apps: action.payload };

    case CreateOrderReducerActions.REFRESH_BASKET:
      return { ...state, basket: action.payload };

    case CreateOrderReducerActions.SET_ADDRESS:
      return { ...state, address: action.payload };

    case CreateOrderReducerActions.SET_TECH_CAPABILITIES:
      return { ...state, techCapabilities: action.payload };

    case CreateOrderReducerActions.SET_REQUEST_AND_CONTRACTS:
      return { ...state, requestAndContracts: action.payload };

    case CreateOrderReducerActions.SET_CLIENT_FORM_ERRORS:
      return { ...state, clientFormErrors: action.payload };

    case CreateOrderReducerActions.SELECT_TARIF:
      return { ...state, tariff: action.payload };

    case CreateOrderReducerActions.SET_ORDER_FORM:
      return { ...state, orderForm: action.payload };

    case CreateOrderReducerActions.SET_BUNDLE_TYPE:
      return { ...state, bundleType: action.payload };

    case CreateOrderReducerActions.SET_PREVIOUS_BASKET_ADDRESS:
      return { ...state, previousBasketAddress: action.payload };

    case CreateOrderReducerActions.SET_ORDER_DATA:
      return { ...state, orderData: action.payload };

    case CreateOrderReducerActions.SET_CALCULATE_BASKET:
      return { ...state, calculateBasket: action.payload };

    case CreateOrderReducerActions.SET_BASKET_ALTERNATION_OFFERINGS:
      return { ...state, basketAlternateOfferings: action.payload };

    case CreateOrderReducerActions.SELECT_ORDER_STEP:
      return { ...state, orderStep: action.payload };

    case CreateOrderReducerActions.SET_CALCULATOR_FORM:
      return { ...state, calculatorForm: action.payload };

    case CreateOrderReducerActions.SET_CALCULATOR_EQUIPMENTS:
      return { ...state, calculatorEquipments: action.payload };

    case CreateOrderReducerActions.SET_AUTOCONVERGENT_GROUP:
      return { ...state, autoconvergentGroup: action.payload };

    case CreateOrderReducerActions.SET_WITHOUT_CHECK_TECH_CAPABILITY:
      return { ...state, withoutCheckTechCapability: action.payload };

    case CreateOrderReducerActions.SET_FILTERED_PACKAGES:
      return { ...state, filteredPackages: action.payload };

    case CreateOrderReducerActions.SET_CURRENT_PRODUCT:
      return { ...state, currentProduct: action.payload };

    case CreateOrderReducerActions.SET_REGION_NAMES_ALLOWED_INSTALMENT:
      return { ...state, regionNamesAllowedInstalment: action.payload };

    case CreateOrderReducerActions.SET_TECH_CAPABILITIES_ENTRIES:
      return { ...state, techCapabilitiesEntries: action.payload };

    case CreateOrderReducerActions.SET_OFFERINGS:
      return { ...state, offerings: action.payload };

    case CreateOrderReducerActions.SET_REDPACK_ERROR:
      return { ...state, errorRedPack: action.payload };

    case CreateOrderReducerActions.SET_IS_CONVERGENT_CHECK_ID:
      return { ...state, isConvergentCheckId: action.payload };

    case CreateOrderReducerActions.SET_REDPACK_DATA:
      return { ...state, redPackData: action.payload };
    case CreateOrderReducerActions.SET_CALCULATOR_SELECTED_PROMO:
      return { ...state, calculatorSelectedPromo: action.payload };
    default:
      return state;
  }
};
