import { Technologies } from "./types/Technologies";

export class Constants {
    static readonly MESSAGE_INPUT_REQUIRED = 'Обязательное поле';
    static readonly MESSAGE_INPUT_PHONE_FORMAT = 'Телефон должен быть в формате +7(999)123-45-67';
}

export const AutoConvergentProducts = [
    { name: 'Телевидение', icon: 'i-dp-tv', technology: Technologies.Tv },
    { name: 'Интернет', icon: 'i-dp-internet', technology: Technologies.Inet },
    { name: 'Цифровые продукты', icon: 'i-dp-maps-home', technology: '' },
    { name: 'Мобильная связь', icon: 'i-dp-mobile', technology: Technologies.Phone },
];

export enum Tariff {
    Simple,
    Personal,
    Packages
};