export enum Roles {
  DealerAdministrator = 'DealerAdministrator',
  SellerMultiSaleCreate = 'SellerMultiSaleCreate',
  SellerSaleCreate = 'SellerSaleCreate',
  SellerUpsaleCreate = 'SellerUpsaleCreate',
  SellerTechChangeCreate = 'SellerTechChangeCreate',
  SellerChangeTarifCreate = 'SellerChangeTarifCreate',
  SellerSupplyServiceSaleCreate = 'SellerSupplyServiceSaleCreate',
  ViewerOwnList = 'ViewerOwnList',
  ViewerSellpointList = 'ViewerSellpointList',
  ViewerOrgList = 'ViewerOrgList',
  ViewAllEmployees = 'ViewAllEmployees',
  EmployeeCreate = 'Employee_Create',
}
