import { ChangeEvent, Dispatch, useState } from 'react';
import { createPortal } from 'react-dom';
import ButtonMTS from '../../_ui/ButtonMTS/ButtonMTS';
import Modal from '../Modal/Modal';
import styles from './NotesModal.module.scss';

type Props = {
  close: () => void;
  setNotes: Dispatch<string>;
};

const NotesModal = (props: Props): JSX.Element => {
  const initNotes = localStorage.getItem('notes');
  const [notes, setNotes] = useState(initNotes || '');
  const buttonSubmit = () => {
    props.close();
    props.setNotes(notes);
    localStorage.setItem('notes', notes);
  };
  const modalRoot = document.getElementById('modal-root') as HTMLElement;

  return createPortal(
    <Modal
      onCloseClick={props.close}
      modalStyle={styles.ModalBlock}
      closeButtonStyle={styles.ButtonClose}
    >
      <div className={styles.Content}>
        <div style={{ width: '100%' }}>
          <div className={styles.Header_Title}>Заметки</div>
          <div className={styles.Info}>Текст сообщения*</div>
          <textarea
            data-testid="notes-modal-text"
            className={styles.TextArea}
            value={notes}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              void setNotes(e.target.value)
            }
          />

          <ButtonMTS
            card="primary"
            testid="notes-modal-save"
            additionalstyle={styles.ButtonMTS}
            onClick={buttonSubmit}
          >
            <span>Сохранить и закрыть</span>
          </ButtonMTS>
        </div>
      </div>
    </Modal>,
    modalRoot
  );
};

export default NotesModal;
