import { useEffect } from 'react';
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

import CheckboxMTS from '../../../_ui/CheckboxMTS/CheckboxMTS';

import styles from '../StaffForm.module.scss';
import { Dealer, User } from '../../../../types';
import { Organization } from '../../../../types/Organization';
import { Constants } from '../../../../constants';
import { storeDP } from '../../../../store/store';

import Select from '../../../_ui/Select/Select';
import inputStyles from '../../../_ui/Input/Input.module.scss';

type Props = {
  watch: UseFormWatch<Dealer>;
  isUser: boolean;
  user?: User;
  register?: UseFormRegister<Dealer>;
  dealerInfo?: Dealer;
  organization: Organization;
  errors: Record<string, unknown>;
  isCreateForm: boolean;
  isDisableConditions: boolean;
  setIsDisableConditions: (data: boolean) => void;
  control: Control<Dealer, any>;
};

const CheckboxesNew = (props: Props): JSX.Element => {
  const { setIsDisableConditions, dealerInfo, isCreateForm, organization } =
    props;

  const user = storeDP.getState().userReducer.user;
  const isAdmin = user?.roles2 && user.roles2.includes('Administrator');

  useEffect(() => {
    const disable = !isAdmin && !props.isCreateForm;
    setIsDisableConditions(disable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const canChangePointOfSale =
    isCreateForm ||
    (((dealerInfo?.roles2?.includes('Supervisor') &&
      user?.rights?.includes('Supervisor_ChangeSellPoints')) ||
      (dealerInfo?.roles2?.includes('Employee') &&
        user?.rights?.includes('Employee_ChangeSellPoints'))) &&
      !dealerInfo?.roles2?.includes('Administrator'));

  function TranslateRole(role: string): string
  {
    switch (role) {
      case 'Administrator': return 'Администратор';
      case 'Supervisor': return 'Супервайзер';
      case 'Employee': return 'Сотрудник точки';
      default: return role;
    }
  }

const availableSellPoints = isCreateForm ?
  props.organization.sellPoints.filter(point => props.user?.sellPoints.find(p => p === point.id)) :
  props.organization.sellPoints;

  return (
    <div className={styles.StaffForm__Checkboxes}>
      <div className={styles.StaffForm__Roles}>
        <h3
          className={`${styles.StaffForm__RolesHeader} ${
            props.errors['roles'] ? styles.StaffForm__RolesHeader_error : ''
          }`}
        >
          Роли*
        </h3>
        <div className={styles.StaffForm__RolesList}>
          {organization.roles2 &&
            organization.roles2.map((role) => {
              if (!isCreateForm) {
                return (
                  <div key={role} className={styles.StaffForm__Checkbox}>
                    <CheckboxMTS
                      boxSize="big"
                      disabled={true}
                      name="roles"
                      value={role}
                      register={props.register}
                      checked={
                        dealerInfo?.roles2 &&
                        dealerInfo.roles2.some((item) => item === role)
                      }
                      data-testid={`role-${role}`}
                      {...(props.isUser || dealerInfo?.roles2
                        ? {}
                        : {
                            required: {
                              required: Constants.MESSAGE_INPUT_REQUIRED,
                            },
                          })}
                    >
                      {TranslateRole(role)}
                    </CheckboxMTS>
                  </div>
                );
              }

              if (isCreateForm && role.toLocaleLowerCase() === 'employee') {
                return (
                  <div key={role} className={styles.StaffForm__Checkbox}>
                    <CheckboxMTS
                      disabled={true}
                      boxSize="big"
                      name="roles"
                      value={role}
                      register={props.register}
                      checked={true}
                      data-testid={`role-${role}`}
                    >
                      {TranslateRole(role)}
                    </CheckboxMTS>
                  </div>
                );
              }

              return null;
            })}
        </div>
      </div>
      <div className={styles.StaffForm__Roles}>
        <h3 className={styles.StaffForm__RolesHeader}>Точки продаж*</h3>

        <Controller
          render={({ field: { onChange, value } }) => (
            <div
              className={`${styles.StaffForm__Select} ${
                props.errors.sellPoints ? inputStyles.Input__Input_Error : ''
              }`}
            >
              <Select
                formName="sellPoints"
                disabled={!canChangePointOfSale}
                multipleСhoice={false}
                value={
                  (value && value[0]) ??
                  (dealerInfo?.sellPoints &&
                    onChange([dealerInfo.sellPoints[0]]))
                }
                onChange={(val) => onChange([val])}
                register={props.register}
                {...{
                  required: {
                    required: Constants.MESSAGE_INPUT_REQUIRED,
                  },
                }}
                list={availableSellPoints.map((item) => {
                  return { value: item.id, label: item.name };
                })}
              />
            </div>
          )}
          name={'sellPoints'}
          control={props.control}
        />
      </div>
    </div>
  );
};

export default CheckboxesNew;
