import React, {
  ChangeEvent,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import ButtonClear from '../ButtonClear/ButtonClear';
import styles from './InputMTS.module.scss';

type Props = {
  id?: string;
  type?: string;
  label?: string;
  onChange?: (args: any) => void;
  onBlur?: () => void;
  value?: number | string;
  error?: boolean;
  name?: string;
  testid?: string;
  defaultValue?: string | number;
  placeholder?: string;
  containerStyle?: string;
  inputStyle?: string;
  prefix?: HTMLElement | JSX.Element;
  postfix?: HTMLElement | JSX.Element;
  inputBoxStyle?: string;
  disable?: boolean;
  maxLength?: number;
  max?: number;
  onKeyDown?: KeyboardEventHandler;
  onClick?: (data?: string) => void;
  onFocus?: (data?: string) => void;
  onMouseDown?: () => void;
  tabIndex?: number;
  register?: UseFormRegisterReturn;
  ref?: MutableRefObject<null>;
  noFocus?: boolean;
  onClear?: (args: any) => void;
  onClose?: () => void;
  buttonClear?: boolean;
  inputMode?: 'numeric';
  autoFocus?: boolean;
};

const InputMTS = (props: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerStyle = props.containerStyle ? props.containerStyle : '';
  const inputStyle = props.inputStyle ? props.inputStyle : '';
  const inputBoxStyle = props.inputBoxStyle ? props.inputBoxStyle : '';
  const [value, setValue] = useState<string | number | undefined>(
    props.value ? props.value : ''
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (props.onChange) props.onChange(inputValue);
  };

  useEffect(() => {
    setValue(props.value);
    if (props.value === '' && !props.noFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const labelError = props.error ? styles.label_error : '';
  const inputBoxError = props.error ? styles.input_error : '';
  const inputBoxDisabled = props.disable ? styles.input_disable : '';

  return (
    <div
      className={`${styles.container} ${containerStyle}`}
      onClick={() => inputRef.current?.focus()}
    >
      {props.label && (
        <label className={`${styles.label} ${labelError}`}>{props.label}</label>
      )}
      <div
        className={`${styles.input} ${inputBoxStyle} ${inputBoxError} ${inputBoxDisabled}`}
      >
        {props.prefix}
        <input
          inputMode={props.inputMode ? props.inputMode : 'text'}
          id={props.id}
          ref={props.ref ? props.ref : inputRef}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          type={props.type}
          className={`${styles.input__input} ${inputStyle}`}
          name={props.name}
          onFocus={() =>
            props.onFocus && props.name && props.onFocus(props.name)
          }
          autoFocus={props.autoFocus}
          data-testid={props.testid}
          value={value}
          tabIndex={props.tabIndex}
          onChange={handleChange}
          onBlur={props.onBlur}
          onMouseDown={props.onMouseDown}
          disabled={!!props.disable}
          maxLength={props.maxLength}
          onKeyDown={props.onKeyDown}
          autoComplete={'off'}
          onClick={() => {
            props.onClick && props.name && props.onClick(props.name);
          }}
          {...props.register}
        />
        {props.postfix}

        <div
          className={
            props.buttonClear
              ? styles.buttonClear_visible
              : styles.buttonClear_hidden
          }
        >
          <ButtonClear
            size="medium"
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              } else {
                if (props.onClear) props.onClear(props.name);
                if (props.onChange) props.onChange('');
                setValue('');
                inputRef.current?.focus();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InputMTS;
