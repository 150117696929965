import React from 'react';
import styles from '../StaffForm.module.scss';
import ButtonMTS from '../../../_ui/ButtonMTS/ButtonMTS';
import { Dealer } from '../../../../types';

type Props = {
  isUser: boolean;
  isValid: boolean;
  dealerInfo?: Dealer;
  deleteOpen: () => void;
  blockedOpen: () => void;
  unblockedOpen: () => void;
};

const BottomMenuEditDealerOld = (props: Props): JSX.Element => {
  return (
    <div
      className={`${styles.StaffForm__ButtonMenu} ${styles.StaffForm__ButtonMenu_Edit}`}
    >
      <ButtonMTS
        type="submit"
        testid="bottom-menu-save-dealer"
        additionalstyle={styles.StaffForm__ButtonSave}
      >
        Сохранить
      </ButtonMTS>

      <div className={styles.StaffForm__ButtonMenuNav}>
        {!props.isUser && (
          <button
            type="button"
            className={styles.StaffForm__Button}
            onClick={props.deleteOpen}
            data-testid="bottom-menu-delete-dealer"
          >
            <i className="i-dp-delete" /> Удалить
          </button>
        )}

        {!props.isUser &&
          (props.dealerInfo?.isActive ? (
            <button
              type="button"
              className={styles.StaffForm__Button}
              onClick={props.blockedOpen}
              data-testid="bottom-menu-block-dealer"
            >
              <i className="i-dp-block" /> Заблокировать
            </button>
          ) : (
            <button
              type="button"
              className={styles.StaffForm__Button}
              onClick={props.unblockedOpen}
              data-testid="bottom-menu-unblock-dealer"
            >
              <i className="i-dp-done" /> Разблокировать
            </button>
          ))}
      </div>
    </div>
  );
};

export default BottomMenuEditDealerOld;
