import React, { useEffect, useState } from 'react';
import styles from './MySalesModal.module.scss';
import { useAxios } from '../../../../../api/useAxios';
import { MySales } from '../../../../../types/MySales';
import { ApiErrorData } from '../../../../../types';
import MySalesResults from '../MySalesResults/MySalesResults';
import MySalesNoResults from '../MySalesNoResults/MySalesNoResults';
import LoaderMatrix from '../../../LoaderMatrix/LoaderMatrix';
import { ProblemModal } from '../../../../_modals';
import moment from 'moment';
import 'moment/locale/ru';

const MySalesModal = (): JSX.Element => {
  const [salesPlan, setSalesPlan] = useState<MySales>();
  const [error, setError] = useState<ApiErrorData>();
  const [loader, setLoader] = useState<boolean>(false);

  const { send: getSalesPlan, ...returnGetSalesPlan } = useAxios<MySales>(
    '/salesPlan/salesplan'
  );

  useEffect(() => {
    setLoader(true);
    getSalesPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (returnGetSalesPlan.response) {
      setSalesPlan(returnGetSalesPlan.response);
      setLoader(false);
    }

    if (returnGetSalesPlan.error) {
      setError(returnGetSalesPlan.error.data);
      setLoader(false);
    }
  }, [returnGetSalesPlan.response, returnGetSalesPlan.error]);

  const dateFormat = moment(salesPlan?.date as string).locale('ru').format('D MMMM YYYY');

  return (
    <>
      {loader && !salesPlan ? (
        <LoaderMatrix />
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <h4 className={styles.header__name}>Мои продажи</h4>
            <span className={styles.header__date}>
              К завершению дня: {dateFormat}
            </span>
          </div>

          {salesPlan && salesPlan.hasData ? (
            <MySalesResults salesPlan={salesPlan} />
          ) : (
            <MySalesNoResults />
          )}
        </div>
      )}

      {error && <ProblemModal error={error} />}
    </>
  );
};

export default MySalesModal;
