/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { createOrderReducer } from './createOrderReducer';
import { subscriptionReducer } from './subscriptionReducer';
import { OrderListReducer } from './orderListReducer';
import { MicrofrontReducer } from './microfrontReducer';

export const rootReducer = combineReducers({
  userReducer: userReducer,
  createOrderReducer: createOrderReducer,
  subscriptionReducer: subscriptionReducer,
  orderListReducer: OrderListReducer,
  microfrontReducer: MicrofrontReducer,
});
