import { getConfig } from "../api/ConfigApi";

declare global {
  interface Window { _mtm: any[] }
}

// пользоваться action через этот enum. Т к в matomo можно фильтровать по ним
export enum ActionMatomo {
  Info = 'Данные',
  Error = 'Ошибка',
  Event = 'Событие',
  Notice = 'Уведомление'
}

const eventCategory = () => `DP`; // менять осознанно. В matomo может быть настроена фильтрация

/**
   * встроить скрипт Tag Manager-а в страницу
   * @param userId? string | null
*/
const initMatomo = async (userId: string | null = null): Promise<void> => {
  const _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
  try {
    const config = await getConfig();
    if (config.matomoContainerId) {
      const url = "https://matomo.obs.mts.ru/";

      userId && _mtm.push({'uid': userId });
      userId && logMatomo(ActionMatomo.Info, 'Пользователь', userId);

      const scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.async = true;
      scriptEl.src = `${url}js/container_${config.matomoContainerId}.js`;

      document.head.appendChild(scriptEl);
      return;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 'Нет настройки в конфиге';
    }
  } catch (error) {
    console.error('Load setup matomo:', error);
  }
};

/**
 * отправить ошибку через Tag Manager в Matomo
 * @param traceId string
 * @param message? string | null
 */
const logErrorMatomo = (traceId: string, message?: string | null): void => {
  console.error(`traceId: ${traceId}, ${message ? `Message: ${message}` : ''}`);
  logMatomo(ActionMatomo.Error, `traceId: ${traceId}`, message ? `Message: ${message}` : '');
};

/**
   * отправить событие через Tag Manager в Matomo
   * @param action - действие по событию
   * @param name - название события
   * @param value - значение события
*/
const logMatomo = (action: ActionMatomo, name: string, value?: string | null) => {
  const _mtm = (window._mtm = window._mtm || []);
  _mtm.push({
    event: 'MEvent',
    eventCategory: eventCategory(), // категория события
    eventAction: action,
    eventLabel: `${name}${value ? `: ${value}` : ''}`
  });

};

export { initMatomo, logErrorMatomo, logMatomo };