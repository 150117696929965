import React from 'react';
import { ApiErrorData } from '../../../types';

type Props = {
  errorData?: ApiErrorData;
  errorMessage?: string;
  url?: string;
};

const AuthError = ({ errorData, errorMessage, url }: Props): JSX.Element => {
  const message =
    errorMessage ||
    (errorData
      ? errorData.title ||
        `${errorData?.status || ''}: ${errorData?.detail ?? ''}`
      : '');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 40,
      }}
    >
      <span className="i-dp-report" style={{ fontSize: '100px' }} />
      <h2>Ошибка авторизации</h2>
      <br />
      {message && <h3 style={{ marginBottom: 20 }}>{message}</h3>}
      {errorData?.traceId && <p>{errorData?.traceId}</p>}
      <br />
      <a href={window.location.origin}>Вернуться назад</a>
    </div>
  );
};

export default AuthError;
