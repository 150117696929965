import styles from './ButtonMTS.module.scss';

type Card = 'primary' | 'outline' | 'redselect';

type Props = {
  children: string | number | JSX.Element | HTMLElement;
  disabled?: boolean;
  onClick?: () => void;
  onMouseDown?: () => void;
  card: Card;
  type: 'button' | 'submit' | 'reset' | undefined;
  additionalstyle?: string;
  tabIndex?: number;
  error?: boolean;
  name?: string;
  value?: string;
  testid?: string;
};

const ButtonMTS = (props: Props): JSX.Element => {
  const { children, card, type, error, ...rest } = props;
  const additionalstyle = props.additionalstyle ? props.additionalstyle : '';
  const cardClass = (card: Card) => {
    switch (card) {
      case 'primary':
        return styles.Button_primary;
      case 'redselect':
        return styles.Button_redselect;

      default:
        return styles.Button_outline;
    }
  };

  const classes = `${styles.Button} ${cardClass(props.card)}`;
  const errorStyle = error ? styles.Error : '';

  return (
    <button
      type={type}
      {...rest}
      className={`${classes} ${additionalstyle} ${errorStyle}`}
      data-testid={props.testid}
      disabled={props.disabled}
    >
      {children}
    </button>
  );
};

ButtonMTS.defaultProps = {
  type: 'button',
  card: 'primary',
};

export default ButtonMTS;
