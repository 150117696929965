import {
  OrderListFilters,
  OrderListActions,
  OrderListReducerActions,
} from './types/orderListTypes';

const startTodayDate = new Date(new Date().setHours(0, 0, 0, 0));
const endTodayDate = new Date(new Date().setHours(23, 59, 59, 999));
const sevenDaysAgoDate = new Date(
  new Date(
    new Date(new Date().setDate(endTodayDate.getDate() - 7)).setHours(
      0,
      0,
      0,
      0
    )
  )
);
const startMonthDate = new Date(
  new Date(
    new Date(new Date().setDate(endTodayDate.getDate())).setHours(0, 0, 0, 0)
  ).setDate(1)
);

export const initialStateOrderList: OrderListFilters = {
  pageIndex: 0,
  datesCreated: [sevenDaysAgoDate, endTodayDate],
  datesClosed: [],
  datesExpectedFinished: [],
  status: [],
  sellPoint: '',
  additionalSellpoint: '',
  orderId: '',
  selectedUser: '',
  address: undefined,
  completionState: [],
  openSRCheck: [],
  openContractCheck: [],
};

export const allOrderListBySellPoint = (
  sellPoint: string
): OrderListFilters => {
  return {
    ...initialStateOrderList,
    sellPoint: sellPoint,
  };
};

export const todayExpectedFinishedStateOrderList = (
  sellPoint: string
): OrderListFilters => {
  return {
    ...initialStateOrderList,
    sellPoint: sellPoint,
    datesExpectedFinished: [startTodayDate, endTodayDate],
  };
};

export const stateOrderListByCompletionState = (
  completionState: string[],
  sellPoint: string
): OrderListFilters => {
  return {
    ...initialStateOrderList,
    sellPoint: sellPoint,
    completionState: completionState,
    datesCreated: [startMonthDate, endTodayDate],
  };
};
export const closedStateOrderListByCompletionState = (
  completionState: string[],
  sellPoint: string
): OrderListFilters => {
  return {
    ...initialStateOrderList,
    sellPoint: sellPoint,
    completionState: completionState,
    status: ['Закрыт'],
  };
};

export const OrderListReducer = (
  state = initialStateOrderList,
  action: OrderListActions
): OrderListFilters => {
  switch (action.type) {
    case OrderListReducerActions.CHECK_OPEN_CONTRACT:
      return { ...state, openContractCheck: action.payload };
    case OrderListReducerActions.CHECK_OPEN_SR:
      return { ...state, openSRCheck: action.payload };
    case OrderListReducerActions.SELECT_USER:
      return { ...state, selectedUser: action.payload };
    case OrderListReducerActions.SET_ADDITIONAL_SELPOINT:
      return { ...state, additionalSellpoint: action.payload };
    case OrderListReducerActions.SET_ADDRESS:
      return { ...state, address: action.payload };
    case OrderListReducerActions.SET_COMPLETION_STATE:
      return { ...state, completionState: action.payload };
    case OrderListReducerActions.SET_DATES_CLOSE:
      return { ...state, datesClosed: action.payload };
    case OrderListReducerActions.SET_DATE_EXPECTED_FINISHED:
      return { ...state, datesExpectedFinished: action.payload };
    case OrderListReducerActions.SET_DATES_CREATED:
      return { ...state, datesCreated: action.payload };
    case OrderListReducerActions.SET_ORDER_ID:
      return { ...state, orderId: action.payload };
    case OrderListReducerActions.SET_PAGE_INDEX:
      return { ...state, pageIndex: action.payload };
    case OrderListReducerActions.SET_SELLPOINT:
      return { ...state, sellPoint: action.payload };
    case OrderListReducerActions.SET_STATUS:
      return { ...state, status: action.payload };
    case OrderListReducerActions.SET_STATE:
      return action.payload;
    case OrderListReducerActions.RESET:
      return initialStateOrderList;
    default:
      return initialStateOrderList;
  }
};
