/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useRef, useState } from 'react';
import styles from './Footer.module.scss';
import InfoText from '../../_ui/InfoText/InfoText';
import Help from '../../Help/Help';
import { getConfig } from '../../../api/ConfigApi';
import WidgetModal from '../Widget/components/widgetModal/widgetModal';

const Footer = (): JSX.Element => {
  const refFooter = useRef(null);

  const [openHelp, setOpenHelp] = useState(false);
  const [openWidget, setOpenWidget] = useState(false);

  const [helpSwitch, setHelpSwitch] = useState<boolean>(false);
  const [widgetSwitch, setWidgetSwitch] = useState<boolean>(false);
  void getConfig().then((config) => {
    setHelpSwitch(config.helpSwitch === true);
    setWidgetSwitch(config.widgetSwitch === true);
  });

  return (
    <footer className={styles.Footer} ref={refFooter}>
      {helpSwitch && (
        <InfoText
          text="Оставить отзыв"
          icon=""
          onClick={() => setOpenHelp(true)}
          data-testid="open-help"
        />
      )}

      {widgetSwitch && (
        <InfoText
          text="Виджет МТС"
          icon=""
          onClick={() => setOpenWidget(true)}
          data-testid="open-widget"
        />
      )}
      {/* <InfoText text="Антистресс" icon="antistress" /> */}

      {openHelp && <Help onHelpCloseClick={() => setOpenHelp(false)} />}

      {openWidget && <WidgetModal closeModal={() => setOpenWidget(false)} />}
    </footer>
  );
};

export default Footer;
