import { useState } from 'react';
import {
  DeepMap,
  FieldError,
  FieldNamesMarkedBoolean,
  UseFormRegister,
} from 'react-hook-form';

import Input, { InputWithMask } from '../../../_ui/Input/Input';
import styles from '../StaffForm.module.scss';
import inputStyles from '../../../_ui/Input/Input.module.scss';
import { Dealer } from '../../../../types';
import { Constants } from '../../../../constants';
import { storeDP } from '../../../../store/store';
import { getConfig } from '../../../../api/ConfigApi';
import { checkSameDigits } from '../../../_modals/UpdateMsisdn/components/Utils/Utils';

type Props = {
  isUser: boolean;
  register: UseFormRegister<Dealer>;
  errors?: DeepMap<Dealer, FieldError>;
  dirty: DeepMap<Partial<Readonly<FieldNamesMarkedBoolean<Dealer>>>, true>;
  isCreateForm: boolean;
};

const InputsOld = (props: Props): JSX.Element => {
  const user = storeDP.getState().userReducer.user;
  const isAdmin = user?.roles2 && user?.roles.includes('DealerAdministrator');
  const isDisableConditions = !(isAdmin || props.isUser) && !props.isCreateForm;

  const [configUpdateStaffNumber, setConfigUpdateStaffNumber] =
    useState<boolean>(false);

  void getConfig().then((res) =>
    setConfigUpdateStaffNumber(res.updateStaffNumber === true)
  );

  const MTSemail = (value: string) => {
    const regExp = /^mts.ru$/g;
    if (regExp.exec(value.split('@')[1])) {
      return 'В логине @mts.ru не должно быть точки';
    }
  };

  return (
    <div className={styles.StaffForm__Info}>
      <Input
        disabled={isDisableConditions}
        text="Фамилия"
        placeHolder="Фамилия"
        type="text"
        name="lastname"
        register={props.register}
        required={{
          required: Constants.MESSAGE_INPUT_REQUIRED,
          pattern: {
            value:
              /^(([А-ЯЁа-яё]{1,24})|(([А-ЯЁа-яё]{1,24})+(-[А-ЯЁа-яё]{1,24})))$/,
            message:
              'Используйте кириллицу, дефис, не используйте цифры, пробелы',
          },
          minLength: {
            value: 2,
            message: 'Минимальное количество символов: 2',
          },
          maxLength: {
            value: 24,
            message: 'Максимальное количество символов: 24',
          },
        }}
        errors={props.errors}
        testid="lastname"
        dirty={props.dirty['lastname']}
        tabiIndex={1}
        className={styles.StaffForm__Input_Lastname}
        labelStyle={inputStyles.Input}
        inputStyle={`${inputStyles.Input__Input} ${inputStyles.Capitalize}`}
        errorStyle={inputStyles.Input__Input_Error}
      />
      <InputWithMask
        disabled={isDisableConditions}
        text="Телефон"
        mask="+7 999 999-99-99"
        maskPlaceholder={null}
        type="tel"
        name="phone"
        required={{
          required: Constants.MESSAGE_INPUT_REQUIRED,
          pattern: {
            value: /(\+7\s$)|(\+7\s\d{3}\s\d{3}-\d{2}-\d{2}$)/g,
            message: 'Телефон должен быть в формате +7 999 123-45-67',
          },
          validate: (value: string) => checkSameDigits(value),
        }}
        register={props.register}
        testid="phone"
        tabiIndex={4}
        className={styles.StaffForm__Input_Phone}
        errors={props.errors}
        placeHolder="+7"
        dirty={props.dirty['phone']}
        labelStyle={inputStyles.Input}
        inputStyle={inputStyles.Input__Input}
        errorStyle={inputStyles.Input__Input_Error}
      />
      <Input
        disabled={isDisableConditions}
        text="Имя"
        placeHolder="Имя"
        type="text"
        name="firstname"
        register={props.register}
        required={{
          required: Constants.MESSAGE_INPUT_REQUIRED,
          pattern: {
            value: /^([а-яёА-ЯЁ]+)$/,
            message: 'Используйте только кириллицу',
          },
          minLength: {
            value: 2,
            message: 'Минимальное количество символов: 2',
          },
          maxLength: {
            value: 24,
            message: 'Максимальное количество символов: 24',
          },
        }}
        errors={props.errors}
        testid="firstname"
        dirty={props.dirty['firstname']}
        tabiIndex={2}
        className={styles.StaffForm__Input_Firstname}
        labelStyle={inputStyles.Input}
        inputStyle={`${inputStyles.Input__Input} ${inputStyles.Capitalize}`}
        errorStyle={inputStyles.Input__Input_Error}
      />
      <Input
        disabled={isDisableConditions}
        text="Email"
        placeHolder="Электронная почта"
        type="email"
        name="email"
        register={props.register}
        required={{
          required: Constants.MESSAGE_INPUT_REQUIRED,
          pattern: {
            value:
              /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
            message: 'Поле должно содержать электронную почту',
          },
          //INFO: на проде, используют email адреса, которые включают ".";
          validate: (value: string) =>
            value.split('@')[0].indexOf('.') === -1 || MTSemail(value),
        }}
        errors={props.errors}
        testid="email"
        dirty={props.dirty['email']}
        tabiIndex={5}
        className={styles.StaffForm__Input_Email}
        labelStyle={inputStyles.Input}
        inputStyle={inputStyles.Input__Input}
        errorStyle={inputStyles.Input__Input_Error}
      />
      <Input
        disabled={isDisableConditions}
        text="Отчество"
        placeHolder="Отчество"
        type="text"
        name="midname"
        required={{
          pattern: {
            value: /^([а-яёА-ЯЁ ]+)$/,
            message: 'Используйте только кириллицу, пробелы',
          },
          maxLength: {
            value: 24,
            message: 'Максимальное количество символов: 24',
          },
        }}
        register={props.register}
        errors={props.errors}
        testid="midname"
        tabiIndex={3}
        labelStyle={inputStyles.Input}
        inputStyle={`${inputStyles.Input__Input} ${inputStyles.Capitalize}`}
        errorStyle={inputStyles.Input__Input_Error}
      />
      {configUpdateStaffNumber && (
        <Input
          disabled={isDisableConditions}
          text="Идентификатор сотрудника"
          placeHolder="Идентификатор сотрудника"
          type="text"
          name="staffNumber"
          register={props.register}
          errors={props.errors}
          testid="staffNumber"
          tabiIndex={7}
          labelStyle={inputStyles.Input}
          inputStyle={`${inputStyles.Input__Input} ${inputStyles.Capitalize}`}
          errorStyle={inputStyles.Input__Input_Error}
        />
      )}
    </div>
  );
};

export default InputsOld;
