import React, { useEffect, useState } from 'react';
import { SavMfComponent } from '@sav/sav-mf-client';
import { SavMf } from '@sav/types';

import styles from './TestSavPage.module.scss';
import { getConfig } from '../../api/ConfigApi';
import { useAxios } from '../../api/useAxios';
import { Link } from 'react-router-dom';


const TestSavPage = (): JSX.Element => {
  const [urlSav, setUrlSav] = useState<string>('');
  const axiosSavannahCode = useAxios<string>('/account/getSavannahCode');
  const { send: fetchSavannahCode, response: responseSavannahCode } = axiosSavannahCode;

  void getConfig().then((config) => {
    setUrlSav(config.urlSavannahJs);
  });


  useEffect(() => {
    console.log('Load page');
    return () => {
      console.log('Lost page');
      void SavMf.logout('/').then((loggedOut) => {
        console.log('loggedOut', loggedOut);
        if (!loggedOut) {
          console.log('Error in loggout');
        }
      });
    };
  }, []);

  useEffect(() => {
    if (!urlSav) { return; }
    if (!document.getElementById('savannah-script')) {
      const scriptEl = document.createElement('script');
      scriptEl.type = 'module';
      scriptEl.async = true;
      scriptEl.id = 'savannah-script';
      scriptEl.src = urlSav;
      document.head.appendChild(scriptEl);
    }
    document.getElementById('savannah-script')?.addEventListener('load', () => {

      // подписка на событие по отсутствию/протуханию авторизационного токена
      // запрашиваем заново без перезагрузки страницы
      SavMf.getSavBus().subscribe('sys:needAuthorize', () => {
        console.log('Need Authorize');
        fetchSavannahCode();
      });

      setTimeout(() => {
        SavMf.getSavBus().publish('demoInputEvent', 'sav-demo', 'событие от DP');
      }, 2000);

    });
  }, [urlSav]);

  useEffect(() => {
    if (responseSavannahCode) {
      void SavMf.loginSavAuth(responseSavannahCode)
        .then((authentificated) => {
          if (authentificated) {
            console.log('authentificated', authentificated);
          }
        });
    }
  }, [responseSavannahCode]);



  return (
    <div className={styles.page}>
      <Link to="/" >
        На главную
      </Link>
      <h2 className={styles.title}>Демонстрация микрофронтендов Саванны</h2>
      <h3 >МФ с бесшовной авторизацией</h3>
      <SavMfComponent code="sav-demo-auth" />
      <br />
      <h3 >МФ без авторизации</h3>
      <SavMfComponent code="sav-demo" demoAttribute="значение передано из DP" />
    </div>
  );
};
export default TestSavPage;