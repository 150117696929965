import { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { apiClient } from '../../../api/apiClient';
import styles from '../StaffForm/StaffForm.module.scss';
import { Dealer } from '../../../types/Dealer';
import { ApiErrorData } from '../../../types/ApiError';
import { Organization } from '../../../types/Organization';
import BottomMenuAddDealer from './components/BottomMenuAddDealer';
import { InfoModal, ProblemModal } from '../../_modals';
import Templates from './Templates';
import { InfoModalContent } from '../../_modals/InfoModal/InfoModal';
import { formatString } from '../../../utils';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { modalForm } from '../StaffList/StaffList';
import { ActionMatomo, logMatomo } from '../../../utils/matomo';
import LoaderWithOverlay from '../../_ui/LoaderWithOverlay/LoaderWithOverlay';
import { capFirstLet } from '../../_ui/Input/Input.helper';
import { storeDP } from '../../../store/store';
import InputsOld from './components/InputsOld';
import CheckboxesOld from './components/CheckboxesOld';
import BottomMenuEditDealerOld from './components/BottomMenuEditDealerOld';

export type Props = {
  status: modalForm;
  closeForm: () => void;
  dealerLogin?: string;
  reload: () => void;
  organization: Organization;
  dealers?: Dealer[];
};

const StaffFormOld = (props: Props): JSX.Element => {
  const user = storeDP.getState().userReducer.user;
  const [modal, setModal] = useState<InfoModalContent>();
  const [modalAction, setModalAction] = useState<(() => void) | undefined>();
  const [loader, setLoader] = useState(false);
  const [dealerInfoId, setDealerInfoId] = useState<string>('');
  const [errorApi, setErrorApi] = useState<ApiErrorData>();
  const [dealerInfo, setDealerInfo] = useState<Dealer>();
  const [isUser, setIsUser] = useState<boolean>();
  const [isDisableConditions, setIsDisableConditions] =
    useState<boolean>(false);

  const isAdmin = user?.roles.includes('DealerAdministrator');

  useEffect(() => {
    if (dealerInfo) setIsUser(dealerInfo?.id === user?.login);
    if (props.status['createForm']) setIsUser(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerInfo, user?.login]);

  useEffect(() => {
    setDealerInfo(
      props.dealers &&
        props.dealers.find((dealer) => dealer.id === props.dealerLogin)
    );
  }, [props.dealers, props.dealerLogin]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const safeInvoke = async <T, P>(
    action: () => Promise<T | string>
  ): Promise<T | string> => {
    try {
      return await action();
    } catch (error) {
      setLoader(false);
      const axiosError = error as AxiosError<ApiErrorData>;
      setErrorApi(axiosError.response?.data);

      return error as T | string;
    }
  };

  //INFO Создание дилера
  const apiPostDealer = async <T, P>(params?: P): Promise<T | string> =>
    await safeInvoke(async () => {
      setLoader(true);
      const response = await apiClient.post<T>('/dealers', params, {
        withCredentials: true,
      });

      if (response.status === 200) {
        setLoader(false);
        showModal(
          'added',
          () => () => void props.closeForm,
          response.data as unknown as Dealer
        );
        props.reload();
      }

      logMatomo(ActionMatomo.Notice, 'Сотрудник успешно создан');

      return response.data;
    });

  const onSubmitPostDealer: SubmitHandler<Dealer> = (data) => {
    data.roles = data.roles && [data.roles].flat();
    data.sellPoints = [data.sellPoints].flat();
    data.phone = data.phone === '+7(' ? '' : data.phone;
    data.lastname = capFirstLet(data.lastname);
    data.firstname = capFirstLet(data.firstname);
    data.midname = capFirstLet(data.midname);
    return apiPostDealer(data);
  };

  //INFO Редактирование данных дилера
  const apiPutDealer = async <T, P>(
    params: P
  ): Promise<T | string | (() => void)> =>
    await safeInvoke(async () => {
      setLoader(true);
      const response = await apiClient.put<T>(
        `/dealers/${dealerInfoId}`,
        params,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setLoader(false);
        showModal('edited', () => () => void props.closeForm, dealerInfo);
        props.reload();
      }

      logMatomo(ActionMatomo.Notice, 'Сотрудник успешно отредактирован');

      return response.data;
    });

  const onSubmitPutDealer: SubmitHandler<Dealer> = (data) => {
    if (dealerInfo) {
      data.id = dealerInfo.id;
      data.isActive = dealerInfo?.isActive;
      if (isUser || isDisableConditions) data.roles = dealerInfo.roles;
    }
    data.sellPoints = [data.sellPoints].flat();
    data.phone = data.phone === '+7(' ? '' : data.phone;
    data.lastname = capFirstLet(data.lastname);
    data.firstname = capFirstLet(data.firstname);
    data.midname = capFirstLet(data.midname);

    return apiPutDealer(data);
  };

  //INFO Удаление дилера
  const apiDeleteDealer = async <T, P>(params?: P): Promise<T | string> =>
    await safeInvoke(async () => {
      setLoader(true);
      const response = await apiClient.delete<T>(
        `/dealers/${dealerInfoId}`,
        params as AxiosRequestConfig
      );

      if (response.status === 200) {
        setLoader(false);
        showModal('deletedone', () => () => void props.closeForm(), dealerInfo);
        props.reload();
      }

      logMatomo(ActionMatomo.Notice, 'Сотрудник успешно удален');

      return response.data;
    });

  //INFO Блокировка дилера
  const apiDisableDealer = async <T, P>(params?: P): Promise<T | string> =>
    await safeInvoke(async () => {
      setLoader(true);
      const response = await apiClient.post<T>(
        `/dealers/${dealerInfoId}/disable`,
        params,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setLoader(false);
        showModal('blockdone', () => () => void props.closeForm(), dealerInfo);
        props.reload();
      }

      logMatomo(ActionMatomo.Notice, 'Сотрудник успешно заблокирован');

      return response.data;
    });

  //INFO Разблокировка дилера
  const apiEnableDealer = async <T, P>(params?: P): Promise<T | string> =>
    await safeInvoke(async () => {
      setLoader(true);
      const response = await apiClient.post<T>(
        `/dealers/${dealerInfoId}/enable`,
        params,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setLoader(false);
        showModal(
          'unblockdone',
          () => () => void props.closeForm(),
          dealerInfo
        );
        props.reload();
      }

      logMatomo(ActionMatomo.Notice, 'Сотрудник успешно разблокирован');

      return response.data;
    });

  const getFIO = (dealer?: Dealer): string =>
    [dealer?.lastname, dealer?.firstname, dealer?.midname]
      .filter((v) => v)
      .join(' ');

  const showModal = (
    template: string,
    action: (() => void) | undefined,
    dealer?: Dealer
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const content = Object.create(
      Templates[template] as object
    ) as InfoModalContent;

    if (dealer) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      content.question = formatString(content.question!, getFIO(dealer));
    }

    setModalAction(action);
    setModal(content);
  };

  //INFO Работа с формой - React hook form
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, dirtyFields },
    setValue,
    watch,
  } = useForm<Dealer>({ mode: 'onChange' });

  useEffect(() => {
    if (dealerInfo) {
      setValue('lastname', dealerInfo.lastname, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('firstname', dealerInfo.firstname, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('midname', dealerInfo.midname, {
        shouldValidate: true,
        shouldDirty: true,
      });

      if (dealerInfo.phone !== undefined) {
        const num = dealerInfo.phone.toString();
        const num1 = num.replace(/[^0-9]/g, '');
        if (!/\+7\s\d{3}\s\d{3}-\d{2}-\d{2}$/.test(num) && num1.length === 11) {
          const res = num1.replace(
            /([\d]{1})([\d]{3})(\d{3})([\d]{2})([\d]{2})$/g,
            '+7 $2 $3-$4-$5'
          );
          setValue('phone', res, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue('phone', dealerInfo.phone, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
      setValue('email', dealerInfo.email, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue('staffNumber', dealerInfo.staffNumber, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setDealerInfoId(dealerInfo.id);
    }
  }, [dealerInfo, setValue]);

  return (
    <>
      <div className={styles.StaffForm__Overlay} />
      {isUser !== undefined && (
        <div className={styles.StaffForm}>
          <form
            className={styles.StaffForm__Form}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(
              dealerInfo ? onSubmitPutDealer : onSubmitPostDealer
            )}
          >
            {/* Заголовок */}
            <div className={styles.StaffForm__Header} data-testid="staff-form">
              <h1 className={styles.StaffForm__HeaderName}>
                {props.status['createForm'] ? (
                  <span>Добавить сотрудника</span>
                ) : (
                  dealerInfo && (
                    <div className={styles.StaffForm__HeaderName__Edit}>
                      <span>Редактировать данные сотрудника:</span>
                      <br/>
                      <span>{`${getFIO(dealerInfo)}`}</span>
                      <span className={styles.StaffForm__HeaderName__Login}>
                        {`${dealerInfo.id}`}
                      </span>
                    </div>
                  )
                )}
              </h1>
              <button type="button" data-testid="staff-form-close">
                <i
                  className={`i-dp-close ${styles.StaffForm__ButtonClose}`}
                  onClick={props.closeForm}
                />
              </button>
            </div>

            {/* Инпуты */}
            <InputsOld
              isUser={isUser}
              register={register}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              errors={errors as any}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              dirty={dirtyFields as any}
              isCreateForm={props.status['createForm']}
            />

            {/* Чекбоксы */}
            <CheckboxesOld
              watch={watch}
              isUser={isUser}
              user={user}
              register={register}
              dealerInfo={dealerInfo}
              organization={props.organization}
              errors={errors}
              isCreateForm={props.status['createForm']}
              isDisableConditions={isDisableConditions}
              setIsDisableConditions={(bool) => setIsDisableConditions(bool)}
            />

            {/* Кнопки */}
            {props.status['createForm'] ? (
              //INFO Создание дилера
              <BottomMenuAddDealer isValid={isValid} />
            ) : (
              //INFO Редактирование дилера
              (isAdmin || isUser) && (
                <BottomMenuEditDealerOld
                  isValid={isValid}
                  isUser={isUser}
                  dealerInfo={dealerInfo}
                  deleteOpen={() =>
                    showModal(
                      'delete',
                      () => () => void apiDeleteDealer(),
                      dealerInfo
                    )
                  }
                  blockedOpen={() =>
                    showModal(
                      'block',
                      () => () => void apiDisableDealer(),
                      dealerInfo
                    )
                  }
                  unblockedOpen={() =>
                    showModal(
                      'unblock',
                      () => () => void apiEnableDealer(),
                      dealerInfo
                    )
                  }
                />
              )
            )}
          </form>
        </div>
      )}

      {/* Лоадер */}
      {loader && <LoaderWithOverlay />}

      {/* Окно уведомления */}
      {modal && (
        <InfoModal
          content={modal}
          onButtonClick={() => {
            setModal(undefined);
            modalAction && modalAction();
          }}
          onCloseClick={
            modal.id !== 'added' ? () => {
              setModal(undefined);
            } : undefined}
        />
      )}

      {errorApi && (
        <ProblemModal
          error={errorApi}
          onCloseClick={() => setErrorApi(undefined)}
          onButtonClick={() => setErrorApi(undefined)}
          buttonName="Закрыть"
        />
      )}
    </>
  );
};

export default StaffFormOld;
