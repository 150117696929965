import React, { Suspense, useContext, useRef } from 'react';
import './GlobalModal.scss';
import { createPortal } from 'react-dom';
import { LoaderMatrix } from '../../_layout';
import { GlobalModalContext } from '../../../context/GlobalModalContext';
import { CSSTransition } from 'react-transition-group';

const SellPointModal = React.lazy(
  () => import('../SellPointModal/SellPointModal')
);

// const OrdersFiltersModal = React.lazy(
//   () => import('../OrdersFiltersModal/OrderFiltersModal')
// );

const GlobalModal = (): JSX.Element | null => {
  const modalRoot = document.getElementById('modal-root') as HTMLElement;
  const { isOpen, type } = useContext(GlobalModalContext);
  const nodeRef = useRef(null);
  const ref = useRef(null);

  const chooseModal = () => {
    switch (type) {
      case 'sellPoint':
        return <SellPointModal />;

      default:
        return null;
    }
  };

  const content = chooseModal();
  if (!content) return null;

  // TODO: анимация не отрабатывает в первый раз на конкретную модалку
  return createPortal(
    <CSSTransition
      classNames="modal-transition"
      nodeRef={nodeRef}
      key={type}
      timeout={300}
      in={isOpen}
      appear
      unmountOnExit
    >
      <div ref={nodeRef}>
        <div className="modal_overlay">
          <div ref={ref} className="global_modal">
            <Suspense
              fallback={
                <div className="loader">
                  <LoaderMatrix />
                </div>
              }
            >
              {content}
            </Suspense>
          </div>
        </div>
      </div>
    </CSSTransition>,
    modalRoot
  );
};

export default GlobalModal;
