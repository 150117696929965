import { Address, Capability } from '../../../api/AddressApi';
import { CheckServiceRequestsAndContractsResponse } from '../../../api/CheckServiceRequestsAndContractsService';
import { ProductOfferingExtend } from '../../../api/OfferingsApi';
import { BasketAlternateOffering } from '../../../components/OrderPipeline/components/OrderOffers/OrderOffers';
import { Tariff } from '../../../constants';
import { Basket, CalculateBasket, IOrderForm, Offering } from '../../../types';
import { ConvergentProduct } from '../../../types/ConvergentProduct';
import { CurrentProduct } from '../../../types/CurrentProduct';
import {
  CountEquipment,
  OrderCommentCalculatorType,
} from '../../../types/OrderCommentCalculator';
import { OrderData } from '../../../types/OrderData';
import { Apps } from '../../../index';
import { RedPackAbonentData } from '../../../types/RedPack';
import { SelectedPromo } from '../../../components/OrderPipeline/components/OrderCommentCalculatorReborn/OrderCommentCalculatorReborn';

export enum CreateOrderReducerActions {
  REFRESH_BASKET = 'REFRESH_BASKET',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_TECH_CAPABILITIES = 'SET_TECH_CAPABILITIES',
  SET_TECH_CAPABILITIES_ENTRIES = 'SET_TECH_CAPABILTIES_ENTRIES',
  SET_REQUEST_AND_CONTRACTS = 'SET_REQUEST_AND_CONTRACTS',
  SET_CLIENT_FORM_ERRORS = 'SET_CLIENT_FORM_ERRORS',
  SET_ORDER_FORM = 'SET_ORDER_FORM',
  SET_BUNDLE_TYPE = 'SET_BUNDLE_TYPE',
  SET_PREVIOUS_BASKET_ADDRESS = 'SET_PREVIOUS_BASKET_ADDRESS',
  SET_ORDER_DATA = 'SET_ORDER_DATA',
  SET_CALCULATE_BASKET = 'SET_CALCULATE_BASKET',
  SET_BASKET_ALTERNATION_OFFERINGS = 'SET_BASKET_ALTERNATION_OFFERINGS',
  SET_CALCULATOR_FORM = 'SET_CALCULATOR_FORM',
  SET_CALCULATOR_EQUIPMENTS = 'SET_CALCULATOR_EQUIPMENTS',
  SET_CALCULATOR_SELECTED_PROMO = 'SET_CALCULATOR_SELECTED_PROMO',
  SET_AUTOCONVERGENT_GROUP = 'SET_AUTOCONVERGENT_GROUP',
  SET_WITHOUT_CHECK_TECH_CAPABILITY = 'SET_WITHOUT_CHECK_TECH_CAPABILITY',
  SET_FILTERED_PACKAGES = 'SET_FILTERED_PACKAGES',
  SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT',
  SET_REGION_NAMES_ALLOWED_INSTALMENT = 'SET_REGION_NAMES_ALLOWED_INSTALMENT',
  SELECT_TARIF = 'SELECT_TARIF',
  SELECT_ORDER_STEP = 'SELECT_ORDER_STEP',
  SET_APPS = 'SET_APPS',
  SET_OFFERINGS = 'SET_OFFERINGS',
  SET_REDPACK_DATA = 'SET_REDPACK_DATA',
  SET_REDPACK_ERROR = 'SET_REDPACK_ERROR',
  SET_IS_CONVERGENT_CHECK_ID = 'SET_IS_CONVERGENT_CHECK_ID',
}

export type ErrorRedPack = {
  error: number;
  phoneNumber: string;
};

interface SetRedPackError {
  type: CreateOrderReducerActions.SET_REDPACK_ERROR;
  payload: ErrorRedPack | null;
}

interface SetApps {
  type: CreateOrderReducerActions.SET_APPS;
  payload: Apps | [];
}

interface RefreshBasketAction {
  type: CreateOrderReducerActions.REFRESH_BASKET;
  payload: Basket | undefined;
}

interface SetAddress {
  type: CreateOrderReducerActions.SET_ADDRESS;
  payload: Address | undefined;
}

interface SetTechCapabilities {
  type: CreateOrderReducerActions.SET_TECH_CAPABILITIES;
  payload: Capability | null;
}

interface SetTechCapabilitiesEntries {
  type: CreateOrderReducerActions.SET_TECH_CAPABILITIES_ENTRIES;
  payload: string;
}

interface SetRequestAndContracts {
  type: CreateOrderReducerActions.SET_REQUEST_AND_CONTRACTS;
  payload: CheckServiceRequestsAndContractsResponse | undefined;
}

interface SetClientFormErrors {
  type: CreateOrderReducerActions.SET_CLIENT_FORM_ERRORS;
  payload: string[];
}

interface SelectTariff {
  type: CreateOrderReducerActions.SELECT_TARIF;
  payload: Tariff;
}

interface SelectOrderStep {
  type: CreateOrderReducerActions.SELECT_ORDER_STEP;
  payload: number | undefined;
}

interface SetOrderForm {
  type: CreateOrderReducerActions.SET_ORDER_FORM;
  payload: IOrderForm | undefined;
}

interface SetBundleType {
  type: CreateOrderReducerActions.SET_BUNDLE_TYPE;
  payload: string | null;
}

interface SetPreviousBasketAddress {
  type: CreateOrderReducerActions.SET_PREVIOUS_BASKET_ADDRESS;
  payload: Address | undefined;
}

interface SetOrderData {
  type: CreateOrderReducerActions.SET_ORDER_DATA;
  payload: OrderData | undefined;
}

interface SetCalculateBasket {
  type: CreateOrderReducerActions.SET_CALCULATE_BASKET;
  payload: CalculateBasket | undefined;
}

interface SetBasketAlternationOfferings {
  type: CreateOrderReducerActions.SET_BASKET_ALTERNATION_OFFERINGS;
  payload: BasketAlternateOffering[];
}

interface SetCalculatorForm {
  type: CreateOrderReducerActions.SET_CALCULATOR_FORM;
  payload: OrderCommentCalculatorType | undefined;
}

interface SetCalculatorEquipments {
  type: CreateOrderReducerActions.SET_CALCULATOR_EQUIPMENTS;
  payload: CountEquipment[];
}

interface SetAutoconvergentGroup {
  type: CreateOrderReducerActions.SET_AUTOCONVERGENT_GROUP;
  payload: ConvergentProduct[] | undefined;
}

interface SetWithoutCheckTechCapability {
  type: CreateOrderReducerActions.SET_WITHOUT_CHECK_TECH_CAPABILITY;
  payload: boolean;
}

interface SetFilteredPackages {
  type: CreateOrderReducerActions.SET_FILTERED_PACKAGES;
  payload: ProductOfferingExtend[];
}

interface SetCurrentProduct {
  type: CreateOrderReducerActions.SET_CURRENT_PRODUCT;
  payload: CurrentProduct | undefined;
}

interface SetRegionNamesAllowedInstalment {
  type: CreateOrderReducerActions.SET_REGION_NAMES_ALLOWED_INSTALMENT;
  payload: string[];
}

interface SetOfferings {
  type: CreateOrderReducerActions.SET_OFFERINGS;
  payload: Offering[];
}

interface SetIsConvergentCheckId {
  type: CreateOrderReducerActions.SET_IS_CONVERGENT_CHECK_ID;
  payload: string | null;
}

interface SetRedPackData {
  type: CreateOrderReducerActions.SET_REDPACK_DATA;
  payload: RedPackAbonentData | null;
}

interface SetCalculatorSelectedPromo {
  type: CreateOrderReducerActions.SET_CALCULATOR_SELECTED_PROMO;
  payload: SelectedPromo[];
}

export type CreateOrderActions =
  | SetApps
  | RefreshBasketAction
  | SetAddress
  | SetTechCapabilities
  | SetRequestAndContracts
  | SetClientFormErrors
  | SetOrderForm
  | SetBundleType
  | SetPreviousBasketAddress
  | SetOrderData
  | SetCalculateBasket
  | SetCalculatorForm
  | SetBasketAlternationOfferings
  | SetCalculatorEquipments
  | SetCalculatorSelectedPromo
  | SetAutoconvergentGroup
  | SetWithoutCheckTechCapability
  | SetFilteredPackages
  | SetCurrentProduct
  | SetRegionNamesAllowedInstalment
  | SelectTariff
  | SelectOrderStep
  | SetTechCapabilitiesEntries
  | SetOfferings
  | SetRedPackError
  | SetIsConvergentCheckId
  | SetRedPackData;

export interface CreateOrderState {
  apps: Apps;
  basket?: Basket;
  address?: Address;
  requestAndContracts?: CheckServiceRequestsAndContractsResponse;
  techCapabilities: Capability | null;
  techCapabilitiesEntries: string;
  clientFormErrors: string[];
  tariff: Tariff;
  orderForm?: IOrderForm | undefined;
  previousBasketAddress?: Address;
  orderData?: OrderData;
  calculateBasket?: CalculateBasket;
  basketAlternateOfferings: BasketAlternateOffering[];
  orderStep?: number;
  calculatorForm?: OrderCommentCalculatorType;
  calculatorEquipments: CountEquipment[];
  autoconvergentGroup?: ConvergentProduct[];
  withoutCheckTechCapability: boolean;
  filteredPackages: ProductOfferingExtend[];
  currentProduct?: CurrentProduct;
  regionNamesAllowedInstalment: string[];
  offerings: Offering[];
  redPackData: RedPackAbonentData | null;
  errorRedPack: ErrorRedPack | null;
  isConvergentCheckId: string | null;
  bundleType: string | null;
  calculatorSelectedPromo: SelectedPromo[];
}
