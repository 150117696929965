import React from 'react';

import styles from './Error.module.scss';

type Props = {
  // TODO: найти верный тип
  children: JSX.Element | JSX.Element[] | string;
  error?: {
    message: string;
  };
};

const ErrorText = (props: Props): JSX.Element => {
  return (
    <>
      <span className={styles.Error} data-testid="error-text">
        {props.children}
      </span>
    </>
  );
};

export default ErrorText;
