import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { tokenStore } from './useAxios';

type ServerError = {
  message: string;
};

type RedPackError = {
  detail: string;
};

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_DEV,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config: AxiosRequestConfig<any>) => {
    if (tokenStore.token) {
      config.headers = Object.assign(config['headers'] || {}, {
        "Authorization": `Bearer ${tokenStore.token}`,
      });
    }
    return config;
  });

export const apiGet = async <T, P>(
  url: string,
  params?: P
): Promise<T | string> => {
  try {
    const response = await apiClient.get<T>(url, { params });
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ServerError>;
    if (axiosError.response?.data.message) return axiosError.response?.data.message || '';

    const redPackError = error as AxiosError<RedPackError>;
    if (redPackError.response?.data.detail) return redPackError.response?.data.detail || '';

    return 'unknown error';
  }
};

export const apiPost = async <T, P>(
  url: string,
  params?: P
): Promise<T | string> => {
  try {
    const response = await apiClient.post<T>(url, params, {
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;
    return axiosError.response?.data.message || 'unknown error';
  }
};

export const apiPut = async <T, P>(
  url: string,
  params: P
): Promise<T | string> => {
  try {
    const response = await apiClient.put<T>(url, params, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ServerError>;
    return axiosError.response?.data.message || 'unknown error';
  }
};

export const apiPatch = async <T, P>(
  url: string,
  params: P
): Promise<T | string> => {
  try {
    const response = await apiClient.patch<T>(url, params, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ServerError>;
    return axiosError.response?.data.message || 'unknown error';
  }
};

export const apiDel = async <T>(url: string): Promise<T | string> => {
  try {
    const response = await apiClient.delete<T>(url);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ServerError>;
    return axiosError.response?.data.message || 'unknown error';
  }
};
