import styles from './ProblemModal.module.scss';
import { Modal } from '..';
import { ApiErrorData } from '../../../types';
import ReactDOM from 'react-dom';
import IncidentMessage from './components/IncidentMessage/IncidentMessage';

type Props = {
  onCloseClick?: () => void;
  onButtonClick?: () => void;
  buttonName?: string;
  error: ApiErrorData;
  message?: string | null;
};

const ProblemModal = (props: Props): JSX.Element => {
  const infoModalPortal = document.getElementById('infoModal');

  const isRequestOperation =
    props.error.url &&
    (props.error.url === '/requests/operation' ||
      props.error.url === '/requests');

  if (infoModalPortal) {
    return ReactDOM.createPortal(
      <Modal
        onCloseClick={props.onCloseClick}
      >
        <div
          className={styles.ProblemModal__Block}
          data-testid="problem-modal"
        >
          {/*Идентификатор трассировки*/}
          {props.error && (
            <span
              className={styles.ProblemModal__TraceId}
              data-testid="problem-modal-traceId"
            >
              traceId: {props.error.traceId}
            </span>
          )}

          {/* Содержимое модального окна */}
          <span className={`i-dp-report ${styles.ProblemModal__Icon_Error}`} />
          <h3
            className={styles.ProblemModal__Header}
            data-testid="problem-modal-message"
          >
            {props.message || 'Что-то пошло не так'}
          </h3>

          <p className={styles.ProblemModal__Text}>
            {props.error.terminationCode || props.error.status}
          </p>

          {/* Сообщение об инциденте */}
          {isRequestOperation && (
            <IncidentMessage
              url="https://servicedesk.mts.ru/blueprints/b04f3638-0687-4cf0-8241-bfe93fb3d29d"
              error={props.error}
              size="Small"
            />
          )}

          {props.onButtonClick && (
            <button
              type="button"
              className={styles.ProblemModal__ButtonRetry}
              onMouseDown={props.onButtonClick}
              data-testid="problem-modal-button"
            >
              {props.buttonName}
            </button>
          )}
        </div>
      </Modal>,
      infoModalPortal
    );
  }

  return <div />;
};

export default ProblemModal;
