import { useState, createContext } from 'react';
import { SellPoint } from '../types/Organization';
import { Description } from '../components/AutoConvergent/AutoConvergent';

type Props = {
  children: JSX.Element;
};

export type UserSellPoint = SellPoint | null;

// TODO наладить очищение контекста при смене адреса,
// иначе велика ошибка не очищения при добавлении сюда новых данных
export interface IUserContext {
  changeProductModalOpen: string;
  setChangeProductModalOpen: (data: string) => void;
  productDescriptionOpen: Description | undefined;
  setProductDescriptionOpen: (data: Description | undefined) => void;
  basketHeight: number;
  setBasketHeight: (height: number) => void;
}

const UserContext = createContext<IUserContext>({
  changeProductModalOpen: '',
  setChangeProductModalOpen: () => {},
  productDescriptionOpen: undefined,
  setProductDescriptionOpen: () => {},
  basketHeight: 0,
  setBasketHeight: () => {},
});

const UserProvider = ({ children }: Props): JSX.Element => {
  const [changeProductModalOpen, setChangeProductModalOpen] =
    useState<string>('');
  const [productDescriptionOpen, setProductDescriptionOpen] = useState<
    Description | undefined
  >(undefined);
  const [basketHeight, setBasketHeight] = useState<number>(0);

  const value = {
    basketHeight,
    setBasketHeight,
    productDescriptionOpen,
    setProductDescriptionOpen,
    changeProductModalOpen,
    setChangeProductModalOpen,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
