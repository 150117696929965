import {
  MicrofrontActions,
  MicrofrontReducerActions,
  MicrofrontState,
} from './types/microfrontTypes';

const initialState: MicrofrontState = {
  agreements: [],
};

export const MicrofrontReducer = (
  state = initialState,
  action: MicrofrontActions
): MicrofrontState => {
  switch (action.type) {
    case MicrofrontReducerActions.SET_AGREEMENTS:
      return { ...state, agreements: action.payload };

    default:
      return state;
  }
};
