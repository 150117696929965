import React from 'react';
import styles from './FormInputError.module.scss';

type Props = {
  showError?: boolean;
  message: string;
};

const FormInputError = ({
  showError = false,
  message,
}: Props): JSX.Element | null => {
  if (!showError) return null;
  return <span className={styles.error}>{message}</span>;
};

export default FormInputError;
