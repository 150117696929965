import React, { useState } from 'react';
import { RoutesOld } from './router/RoutesOld';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import { Layout, ProtectApp } from './components/_layout';
import { HandbookProvider } from './context/HandbookContext';
import { GlobalModalProvider } from './context/GlobalModalContext';
import { getConfig } from './api/ConfigApi';
import TestSavPage from './pages/TestSavPage/TestSavPage';
import { RoutesNew } from './router/RoutesNew';
import Auth from './components/_layout/Auth/Auth';

export default function App(): JSX.Element {
  const [useNewRoleModel, setUseNewRoleModel] = useState(false);

  void getConfig().then((config) => {
    setUseNewRoleModel(config.useNewRoleModel === true);
  });

  const isTestSav = window.location.href.includes('/test-sav');

  return (
    <Router>
      <UserProvider>
        <div
          style={
            window.location.pathname === '/authorization'
              ? {
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <Route path={'/authorization'}>
            <Auth />
          </Route>

          {isTestSav && (
            <ProtectApp>
              <Route path={'/test-sav'}>
                <TestSavPage />
              </Route>
            </ProtectApp>
          )}

          {!isTestSav && (
            <ProtectApp>
              <HandbookProvider>
                <GlobalModalProvider>
                  <Layout>
                    {useNewRoleModel ? <RoutesNew /> : <RoutesOld />}
                  </Layout>
                </GlobalModalProvider>
              </HandbookProvider>
            </ProtectApp>
          )}
        </div>
      </UserProvider>
    </Router>
  );
}
