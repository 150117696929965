import React from 'react';
import styles from './LoaderMatrix.module.scss';

const LoaderMatrix = (): JSX.Element => {
  return (
    <div className={styles.container} data-testid="loader-matrix">
      <ul className={styles.list}>
        {Array(4)
          .fill('')
          .map((e, i) => (
            <li key={i} className={styles.element} />
          ))}
      </ul>
    </div>
  );
};

export default LoaderMatrix;
