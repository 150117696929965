import React from 'react';
import styles from './DateBlock.module.scss';
import moment from 'moment';

type Props = {
  ontToggle?: () => void;
  onClear?: () => void;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  placeholder?: string | null;
  disabled?: boolean;
  disableClearButton?: boolean;
};

const DateRangeBlock = (props: Props): JSX.Element => {
  const { ontToggle, onClear, dateFrom, dateTo, placeholder, disabled } = props;

  const formatDate = (date: Date): string => moment(date).format('DD.MM.YYYY');

  const formatDateRange = (
    dateFrom?: Date | null,
    dateTo?: Date | null
  ): string => {
    const from = dateFrom ? `c ${formatDate(dateFrom)}` : '';
    const to = dateTo ? ` по ${formatDate(dateTo)}` : '';

    return `${from}${to}`;
  };

  return (
    <div
      className={`${styles.container} ${
        disabled ? styles.container_disabled : ''
      }`}
    >
      <button
        className={styles.toggle_btn}
        onClick={ontToggle}
        type="button"
        disabled={disabled}
        data-testid="date-range-block-toggle"
      >
        <i className={`i-dp-day ${disabled ? styles.icon_disabled : ''}`} />
        <span
          className={`${styles.text} ${disabled ? styles.text_disabled : ''}`}
        >
          {formatDateRange(dateFrom, dateTo) || placeholder}
        </span>
      </button>
      {props.disableClearButton ? null : (
        <button
          className={styles.clear_btn}
          onClick={onClear}
          disabled={!dateFrom && !dateTo}
          tabIndex={-1}
          type="button"
          data-testid="date-range-block-clear"
        >
          <i className="i-dp-close" />
        </button>
      )}
    </div>
  );
};

export default DateRangeBlock;
