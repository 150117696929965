import React from 'react';
import styles from './TextAreaDP.module.scss';
import { useSlots } from '../../../hooks';

type Props = {
  cols?: number;
  type?: string;
  name?: string;
  id?: string;
  value?: string;
  onChange?: (value) => void;
  onBlur?: () => void;
  disabled?: boolean;
  placeholder?: string;
  children?: JSX.Element | JSX.Element[];
  textareaStyle?: string;
  textinputStyle?: string;
  rows: number;
  tabindex?: number;
  onKeyDown?: () => void;
  readOnly?: boolean;
  autoComplete?: string;
  error?: boolean;
  errorMessage?: string;
  testid?: string;
  maxLength?: number;
};

const TextAreaDP = React.forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const { children, errorMessage, ...rest } = props;
    const slots = useSlots(['prefix', 'postfix'], children);
    const textareaStyle = props.textareaStyle ? props.textareaStyle : '';
    const textinputStyle = props.textinputStyle ? props.textinputStyle : '';
    const error = props.error ? styles.textInput__error : '';

    return (
      <div className={styles.container}>
        <label className={`${styles.textInput} ${textinputStyle} `}>
          {slots.prefix && (
            <div className={styles.textInput__prefix}>{slots.prefix}</div>
          )}
          <textarea
            {...rest}
            ref={ref}
            className={`${styles.textInput__input} ${textareaStyle} ${error}`}
            rows={props.rows}
            autoComplete="off"
            data-testid={
              props.testid
                ? props.error
                  ? `${props.testid}-error`
                  : props.testid
                : ''
            }
          />
          {slots.postfix && (
            <div className={styles.textInput__postfix}>{slots.postfix}</div>
          )}
        </label>

        {errorMessage && (
          <span className={styles.textInput__errorMessage}>{errorMessage}</span>
        )}
      </div>
    );
  }
);

export default TextAreaDP;
