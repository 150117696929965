import React from 'react';
import styles from '../StaffForm.module.scss';
import ButtonMTS from '../../../_ui/ButtonMTS/ButtonMTS';
import { Dealer, User } from '../../../../types';

type Props = {
  isUser?: boolean;
  user?: User;
  isValid: boolean;
  dealerInfo?: Dealer;
  deleteOpen: () => void;
  blockedOpen: () => void;
  unblockedOpen: () => void;
};

const BottomMenuEditDealerNew = (props: Props): JSX.Element => {
  const { user, dealerInfo, isUser } = props;

  const disabledDeleteButton =
    dealerInfo?.roles2?.includes('Supervisor') ||
    (!dealerInfo?.roles2 && user?.rights?.includes('Supervisor_Delete')) ||
    ((dealerInfo?.roles2?.includes('Employee') ||
      (!dealerInfo?.roles2 && user?.rights?.includes('Employee_Delete'))) &&
      !dealerInfo?.roles2?.includes('Administrator'));

  const switchBlockUnblock =
    dealerInfo?.roles2?.includes('Supervisor') ||
    (!dealerInfo?.roles2 &&
      user?.rights?.includes('Supervisor_EnableDisable')) ||
    ((dealerInfo?.roles2?.includes('Employee') ||
      (!dealerInfo?.roles2 &&
        user?.rights?.includes('Employee_EnableDisable'))) &&
      !dealerInfo?.roles2?.includes('Administrator'));

  return (
    <div
      className={`${styles.StaffForm__ButtonMenu} ${styles.StaffForm__ButtonMenu_Edit}`}
    >
      <ButtonMTS
        type="submit"
        testid="bottom-menu-save-dealer"
        additionalstyle={styles.StaffForm__ButtonSave}
      >
        Сохранить
      </ButtonMTS>

      <div className={styles.StaffForm__ButtonMenuNav}>
        {disabledDeleteButton && !isUser && (
          <button
            type="button"
            className={styles.StaffForm__Button}
            onClick={props.deleteOpen}
            data-testid="bottom-menu-delete-dealer"
          >
            <i className="i-dp-delete" /> Удалить
          </button>
        )}

        {switchBlockUnblock &&
          !isUser &&
          (props.dealerInfo?.isActive ? (
            <button
              type="button"
              className={styles.StaffForm__Button}
              onClick={props.blockedOpen}
              data-testid="bottom-menu-block-dealer"
            >
              <i className="i-dp-block" /> Заблокировать
            </button>
          ) : (
            <button
              type="button"
              className={styles.StaffForm__Button}
              onClick={props.unblockedOpen}
              data-testid="bottom-menu-unblock-dealer"
            >
              <i className="i-dp-done" /> Разблокировать
            </button>
          ))}
      </div>
    </div>
  );
};

export default BottomMenuEditDealerNew;
