import { useState, useRef, useEffect } from 'react';
import { useOnClickOutside } from '../../../hooks';

import styles from './Select.module.scss';
import { Dealer } from '../../../types';
import { Path, UseFormRegister } from 'react-hook-form';

type Option = {
  value: string | number;
  label?: string | number;
  disabled?: boolean;
  unit?: string;
};

export type Props = {
  value?: string | number;
  valueArray?: string[];
  onChange?: (value: string | string[] | number) => void;
  list: Option[];
  name?: string;
  formName?: Path<Dealer>;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  classNameInput?: string;
  style?: Record<string, string>;
  filtered?: boolean;
  singleChoice?: boolean;
  multipleСhoice?: boolean;
  prefix?: JSX.Element | HTMLElement;
  deleteButton?: boolean;
  deleteFunction?: () => void;
  needUpTitle?: boolean;
  unit?: string;
  register?: UseFormRegister<Dealer>;
  required?: {
    required: string;
  };
  testid?: string;
  defaultOpenList?: boolean;
  error?: boolean;
};

const Select = (props: Props): JSX.Element => {
  const {
    list,
    filtered,
    multipleСhoice,
    classNameInput,
    defaultOpenList,
    error,
    singleChoice,
  } = props;

  const findValue = () => {
    const item = list?.find((i) => i.value === props.value);
    const name = item?.label || item?.value || props.value;
    const unit = item?.unit ? item.unit : props.unit;
    return name && unit ? name.toString() + ` ${unit.toString()}` : name;
  };

  const [value, setValue] = useState<string | number | undefined>(findValue());
  const [valueArray, setValueArray] = useState<string[]>(
    props.valueArray ? props.valueArray : []
  );
  const [show, setShow] = useState(defaultOpenList);
  const [readOnly, setReadOnly] = useState(!filtered);
  const [localList, setLocalList] = useState(list || []);

  const inputStyle = classNameInput ? classNameInput : '';

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (show) setShow(false);
  });

  const errorStyle = error ? styles.error : '';
  const disableStyle = props.disabled ? styles.disable : '';

  const changeArray = (value: string) => {
    if (valueArray.find((item) => item === value)) {
      setValueArray(valueArray.filter((item) => item !== value));
    } else {
      setValueArray([...valueArray, value]);
    }
  };

  useEffect(() => {
    if (multipleСhoice && props.onChange) props.onChange(valueArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueArray]);

  useEffect(() => {
    if (props.valueArray) setValueArray(props.valueArray);
  }, [props.valueArray]);

  const filterList = () => {
    const l = list?.filter((item) => {
      if (!value) return true;
      let isLabel = false;
      let isValue = false;
      if (item.label)
        isLabel = item.label
          .toString()
          .toLowerCase()
          .includes(`${value}`.toLowerCase());
      if (item.value)
        isValue = item.value
          .toString()
          .toLowerCase()
          .includes(`${value}`.toLowerCase());
      return isLabel || isValue;
    });
    return l;
  };

  useEffect(() => {
    list.sort((x, y) => {
      if (x.value < y.value) {
        return -1;
      }
      if (x.value > y.value) {
        return 1;
      }
      return 0;
    });
    setLocalList(list);
  }, [list]);

  useEffect(() => {
    //reset
    !props.value && setValue('');
  }, [props.value]);

  useEffect(() => {
    setReadOnly(false);
    if (!filtered) setValue(findValue());
    if (filtered) {
      const l = filterList();
      if (l?.length === 0)
        setLocalList([{ value: 'Ничего не нашлось', disabled: true }]);
      if (l?.length && l?.length > 0) setLocalList(l);
    }
    setReadOnly(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, value]);

  const options = (
    <div className={styles.Input__Options} data-testid="select-options-block">
      {localList.map((item: Option, i: number) => (
        <button
          type="button"
          key={item.value.toString() + i.toString()}
          className={styles.Input__Option}
          disabled={item.disabled}
          onClick={
            multipleСhoice
              ? () => changeArray(item.value as string)
              : () => {
                  !multipleСhoice && setShow(false);
                  setValue(item.label || item.value);
                  if (props.onChange) props.onChange(item.value);
                }
          }
          data-testid={`select-option-${i}`}
        >
          <>
            {item.label && (
              <span>
                {item.label} {item.unit ? item.unit : props.unit}
              </span>
            )}
            {!item.label && (
              <span>
                {item.value} {item.unit ? item.unit : props.unit}
              </span>
            )}
          </>
          {valueArray.find((value) => value === item.value) && (
            <i className={`i-dp-done ${styles.Input__Icon}`} />
          )}
        </button>
      ))}
    </div>
  );

  return (
    <>
      {!!props.needUpTitle && (
        <div className={styles.Input_title}>{props.placeholder}</div>
      )}
      <div
        className={[
          styles.Input,
          props.className,
          errorStyle,
          disableStyle,
        ].join(' ')}
        style={props.style}
        ref={ref}
      >
        {props.prefix}

        {filtered ? (
          <input
            name={props.name}
            autoComplete="off"
            placeholder={props.placeholder}
            value={value}
            className={`${styles.Input__input} ${
              valueArray.length !== 0 ? styles.Input__input_fill : ''
            } ${inputStyle}`}
            disabled={props.disabled}
            onClick={() => setShow(!show)}
            onChange={(e) => {
              setValue(e.target.value);
              setShow(true);
            }}
            data-testid={props.testid ? props.testid : 'select-show'}
          />
        ) : (
          <input
            readOnly={readOnly}
            key={value}
            name={props.name}
            autoComplete="off"
            placeholder={props.placeholder}
            value={multipleСhoice ? valueArray : value}
            className={`${styles.Input__input} ${
              valueArray.length !== 0 ? styles.Input__input_fill : ''
            } ${inputStyle}`}
            disabled={props.disabled}
            onClick={() => setShow(!show)}
            data-testid={props.testid ? props.testid : 'select-show'}
            {...(props.register &&
              props.formName &&
              (props.required !== undefined
                ? props.register(props.formName, props.required)
                : props.register(props.formName)))}
          />
        )}

        {valueArray.length !== 0 && !singleChoice && (
          <i
            className={`i-dp-close ${styles.Input__Icon_Close}`}
            onClick={() => {
              setValueArray([]);
            }}
            data-testid="select-value-array"
          />
        )}

        {value?.toString() && !singleChoice && props.deleteButton && (
          <i
            className={`i-dp-close ${styles.Input__Icon_Close}`}
            onClick={() => {
              props.deleteFunction && props.deleteFunction();
              setValue('');
              props.onChange && props.onChange('');
            }}
            data-testid="select-delete"
          />
        )}

        {!singleChoice ? (
          !props.disabled && (
            <i
              className="i-dp-down"
              style={{
                position: 'relative',
                fontSize: '150%',
                margin: '0 10px 2px 0',
              }}
              onClick={() => {
                setShow(!show);
              }}
              data-testid="select-show"
            />
          )
        ) : (
          <i
            className="i-dp-close"
            style={{
              position: 'relative',
              fontSize: '200%',
              margin: '0 10px 2px 0',
            }}
            onClick={() => {
              props.deleteFunction && props.deleteFunction();
              setValue('');
              props.onChange && props.onChange('');
            }}
            data-testid="select-show"
          />
        )}

        {show && !props.disabled && localList?.length ? options : null}
      </div>
    </>
  );
};

export default Select;
