import React from 'react';
import styles from './ToolTip.module.scss';

type Props = {
  children: string;
  position: 'left' | 'right' | 'top' | 'bottom';
};

const ToolTip = (props: Props): JSX.Element => {
  const arrowPosition = (position) => {
    switch (position) {
      case 'bottom': {
        return styles.container_up;
      }

      case 'top': {
        return styles.container_down;
      }

      case 'left': {
        return styles.container_right;
      }

      case 'right': {
        return styles.container_left;
      }
    }
  };

  const arrowRotation = (position) => {
    switch (position) {
      case 'bottom': {
        return styles.arrow_up;
      }

      case 'top': {
        return styles.arrow_down;
      }

      case 'left': {
        return styles.arrow_right;
      }

      case 'right': {
        return styles.arrow_left;
      }
    }
  };

  return (
    <div className={arrowPosition(props.position)}>
      <div className={styles.block}>
        <pre className={styles.pre}>{props.children}</pre>
      </div>
      <div className={arrowRotation(props.position)} />
    </div>
  );
};

export default ToolTip;
