/* eslint-disable @typescript-eslint/ban-types */
import { createContext, useEffect, useState } from 'react';
import GlobalModal from '../components/_modals/GlobalModal/GlobalModal';

interface IOpenModal {
  type: string;
  events?: {
    onClose?: () => void;
    onSubmit?: () => void;
    onOpen?: () => void;
  };
}

interface IGlobalModal extends IOpenModal {
  isOpen: boolean;
  resetModal: () => void;
  openModal: (params: IOpenModal) => void;
  closeModal: () => void;
}

type Props = {
  children: JSX.Element;
};

const initialState = {
  isOpen: false,
  type: '',
  resetModal: () => {},
  openModal: () => {},
  closeModal: () => {},
};

const GlobalModalContext = createContext<IGlobalModal>(initialState);

const GlobalModalProvider = (props: Props): JSX.Element => {
  const [modal, setModal] = useState<IGlobalModal>(initialState);

  const resetModal = () => setModal(initialState);

  const openModal = (params: IOpenModal) =>
    setModal((prevState) => ({ ...prevState, ...params, isOpen: true }));

  const closeModal = () =>
    setModal((prevState) => ({ ...prevState, isOpen: false }));

  // Возможно понадобится очищать
  // useEffect(() => {
  //   if (!modal.isOpen) resetModal();
  //   return resetModal;
  // }, [modal.isOpen]);

  useEffect(() => {
    openModal({ type: 'init modal' });
  }, []);

  const contextValue = {
    ...modal,
    resetModal,
    openModal,
    closeModal,
  };

  return (
    <GlobalModalContext.Provider value={contextValue}>
      {props.children}
      <GlobalModal />
    </GlobalModalContext.Provider>
  );
};

export { GlobalModalContext, GlobalModalProvider };
