import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styles from './OrderCreatePortal.module.scss';

type Props = {
  children?: ReactNode;
};

const OrderCreatePortal = (props: Props): JSX.Element => {
  const orderCreatePortal = document.getElementById('staffForm');

  if (!orderCreatePortal) return <div />;

  return ReactDOM.createPortal(
    <div className={styles.OrderCreatePortal}>
      <div className={styles.OrderCreatePortal__Overlay} />
      {props.children}
    </div>,
    orderCreatePortal
  );
};

export default OrderCreatePortal;
