import { Equipment } from '../../../../../../types/OrderCommentCalculator';
import { regions } from './Regions';

export type EquipmentGroup = {
  groupName: string;
  limit?: number;
  type: EquipmentGroupType;
  equipments: Equipment[];
};

export type EquipmentGroupType =
  | 'routers'
  | 'cameras'
  | 'tvsets'
  | 'tvequipments'
  | 'smartDevices'
  | 'additionalSim'
  | 'service'
  | 'payment'
  | 'conditioner'
  | 'robot'
  | 'smartphones';

//INFO: Роутер

export const routers: EquipmentGroup = {
  groupName: 'Роутер',

  type: 'routers',
  equipments: [
    {
      group: 'routers',
      icon: 'i-dp-router',
      name: 'Роутер',
      offerId: '1-9QHCX3VM',
      id: 'router',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 10,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 12 мес.',
          id: 'installment_12',
          min: 1,
          max: 1,
          period: 12,
          technology: 'gpon',
          required: true,
          createOrderId: '1-A69G0QZI',
        },
        {
          name: 'Рассрочка 36 мес.',
          id: 'installment_36',
          min: 1,
          max: 1,
          period: 36,
          required: true,
        },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      promo: false,
      promoPlaceholder: 'Название акции',
      promoLength: 20,
      promoIcon: 'i-dp-discount',
      price: 0,
      unit: 'шт',
    },
    {
      group: 'routers',
      icon: 'i-dp-router',
      offerId: '1-9QHCX3VM',
      id: 'router_used',
      name: 'Роутер Б.У.',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 10,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
        },
        {
          name: 'Рассрочка 36 мес.',
          id: 'installment_36',
          min: 1,
          max: 1,
          period: 36,
          technology: 'gpon',
          required: true,
        },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'routers',
      icon: 'i-dp-router',
      offerId: '1-9QHCX3VM',
      id: 'router_wifi_6',
      name: 'Роутер WI-FI 6',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 10,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 12 мес.',
          id: 'installment_12',
          min: 1,
          max: 1,
          period: 12,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
  ],
};

export const cameras: EquipmentGroup = {
  groupName: 'Камера',
  type: 'cameras',
  equipments: [
    {
      group: 'cameras',
      icon: 'i-dp-router',
      offerId: '1-AA1QAVOG',
      id: 'cam_mts_inner',
      name: ' Камера МТС (внутренняя)',
      usage: [
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 5,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'cameras',
      icon: 'i-dp-router',
      offerId: '1-9XX2TV6Q',
      id: 'cam_mts_video_control_inner',
      name: 'МТС Видеоконтроль (внутренняя)',
      usage: [
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 5,
          required: true,
          createOrderId: '1-A69G0QZE',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'cameras',
      icon: 'i-dp-router',
      offerId: '1-AA1QAVOE',
      id: 'cam_mts_video_control_outer',
      name: 'МТС Видеоконтроль (внешняя)',
      usage: [
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 5,
          required: true,
          createOrderId: '1-A69G0QZE',
        },
      ],
      price: 0,
      unit: 'шт',
    },
  ],
};

export const tvSets: EquipmentGroup = {
  groupName: 'Телевизор',
  limit: 1,
  type: 'tvsets',
  equipments: [
    {
      group: 'tvsets',
      icon: 'i-dp-tv',
      offerId: '1-A0OZEYQ1',
      id: 'tv_24',
      name: 'Телевизор D24',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 12 мес.',
          id: 'installment_12',
          min: 1,
          max: 1,
          period: 12,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvsets',
      icon: 'i-dp-tv',
      offerId: '1-A0OZEZLX',
      id: 'tv_32',
      name: 'Телевизор D32',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 12 мес.',
          id: 'installment_12',
          min: 1,
          max: 1,
          period: 12,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvsets',
      icon: 'i-dp-tv',
      offerId: '1-A0OZEZLZ',
      id: 'tv_43',
      name: 'Телевизор D43',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvsets',
      icon: 'i-dp-tv',
      offerId: '1-A0OZEZN5',
      id: 'tv_50',
      name: 'Телевизор D50',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvsets',
      icon: 'i-dp-tv',
      offerId: '1-A0OZEZN7',
      id: 'tv_55',
      name: 'Телевизор D55',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvsets',
      icon: 'i-dp-tv',
      offerId: '1-A0OZEZN9',
      id: 'tv_65',
      name: 'Телевизор D65',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
  ],
};

export const tvEquipments: EquipmentGroup = {
  groupName: 'ТВ-оборудование',
  type: 'tvequipments',
  equipments: [
    {
      group: 'tvequipments',
      icon: 'i-dp-router',
      id: 'tv-equipment',
      name: 'ТВ-оборудование',
      offerId: '1-ABPB903D',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
        { name: 'Аренда', id: 'rent', min: 1, max: 1, required: false },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvequipments',
      icon: 'i-dp-router',
      id: 'tv-equipment_used',
      name: 'ТВ-оборудование Б.У.',
      offerId: '1-ABPB903D',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvequipments',
      icon: 'i-dp-router',
      id: 'tv-equipment_add',
      name: 'ТВ-оборудование (Дополнительное)',
      offerId: '1-ABPB903D',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 4,
          required: true,
          createOrderId: '1-9QHCX3WE',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 4,
          period: 24,
          required: true,
          createOrderId: '1-9QHCX3WE',
        },
        { name: 'Аренда', id: 'rent', min: 1, max: 4, required: true },
      ],
      options: [
        {
          group: 'equipment',
          id: 'multiroom',
          offerId: '1-9QHCX3XN',
          type: 'string',
          name: 'Мультирум',
          equipmentIds: ['tv_add', 'tv_add_used'],
          usage: { name: 'Опция', id: 'option', required: false },
          price: 0,
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvequipments',
      icon: 'i-dp-router',
      id: 'tv-equipment_add_used',
      name: 'ТВ-оборудование Б.У. (Дополнительное)',
      offerId: '1-ABPB903D',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 4,
          required: true,
          createOrderId: '1-9QHCX3WE',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 4,
          period: 24,
          required: true,
          createOrderId: '1-9QHCX3WE',
        },
      ],
      options: [
        {
          group: 'equipment',
          offerId: '1-9QHCX3XN',
          id: 'multiroom',
          type: 'string',
          name: 'Мультирум',
          equipmentIds: ['tv_add', 'tv_add_used'],
          usage: { name: 'Опция', id: 'option', required: false },
          price: 0,
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvequipments',
      icon: 'i-dp-router',
      offerId: '1-9S4GGBX5',
      id: 'plc-equipment',
      name: 'PLC-адаптер',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 2,
          max: 6,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        { name: 'Аренда', id: 'rent', min: 2, max: 6, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvequipments',
      icon: 'i-dp-router',
      offerId: '1-9S4GGBX5',
      id: 'plc-equipment_used',
      name: 'PLC-адаптер Б.У.',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 2,
          max: 6,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'tvequipments',
      icon: 'i-dp-router',
      offerId: '1-A9MKYQCZ',
      id: 'ott-equipment',
      name: 'ОТТ-приставка',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 5,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 5,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
  ],
};

export const smartDevices: EquipmentGroup = {
  groupName: 'Умные устройства',
  type: 'smartDevices',
  equipments: [
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-A5FHXZL7',
      id: 'home_centr_mts',
      name: 'Домашний центр МТС',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 5, required: true },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 5,
          period: 24,
          required: true,
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-A5FF7O1G',
      id: 'sensor_move',
      name: 'Датчик движения-освещения',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 10,
          required: true,
          availableRegions: regions
            .filter((item) => item !== 'Свердловская')
            .filter((item) => item !== 'Саратовская'),
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9X3Q2HSY',
      id: 'sensor_open_door-window',
      name: 'Датчик открытия окон-дверей',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 10,
          required: true,
          availableRegions: regions
            .filter((item) => item !== 'Свердловская')
            .filter((item) => item !== 'Саратовская'),
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9X3Q2HSL',
      id: 'sensor_water',
      name: 'Датчик протечки воды',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 10,
          required: true,
          availableRegions: regions
            .filter((item) => item !== 'Свердловская')
            .filter((item) => item !== 'Саратовская'),
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9X3Q2HOB',
      id: 'sensor_temp',
      name: 'Датчик температуры-влажности',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 10,
          required: true,
          availableRegions: regions
            .filter((item) => item !== 'Свердловская')
            .filter((item) => item !== 'Саратовская'),
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9X3Q2HLC',
      id: 'sensor_smoke',
      name: 'Датчик дыма',
      usage: [{ name: 'Продажа', id: 'sell', min: 1, max: 10, required: true }],
      price: 0,
      unit: 'шт',
      availableRegions: [
        'Свердловская',
        'Саратовская',
        'Ростовская',
        'Краснодарский',
        'Адыгея',
      ],
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9X3Q2HT1',
      id: 'sensor_vibration',
      name: 'Датчик вибрации',

      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
      availableRegions: [
        'Свердловская',
        'Саратовская',
        'Ростовская',
        'Краснодарский',
        'Адыгея',
      ],
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9X3Q2HEP',
      id: 'smartplug',
      name: 'Умная розетка',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 10,
          required: true,
          availableRegions: regions
            .filter((item) => item !== 'Свердловская')
            .filter((item) => item !== 'Саратовская'),
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9X3Q2HGE',
      id: 'smartlamp',
      name: 'Умная лампочка',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 10,
          required: true,
          availableRegions: regions
            .filter((item) => item !== 'Свердловская')
            .filter((item) => item !== 'Саратовская'),
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9QRBV59N',
      id: 'night_lamp',
      name: 'Лампа ночник',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 10,
          required: true,
          //INFO: временно отключил
          // availableRegions: regions
          //   .filter((item) => item !== 'Свердловская')
          //   .filter((item) => item !== 'Саратовская')
          //   .filter((item) => item !== 'Ростовская')
          //   .filter((item) => item !== 'Краснодарский')
          //   .filter((item) => item !== 'Адыгея'),
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9X4NA6NM',
      id: 'wifi button',
      name: 'Беспроводная кнопка',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        {
          name: 'Аренда',
          id: 'rent',
          min: 1,
          max: 10,
          required: true,
          //INFO: временно отключил
          // availableRegions: regions
          //   .filter((item) => item !== 'Свердловская')
          //   .filter((item) => item !== 'Саратовская')
          //   .filter((item) => item !== 'Ростовская')
          //   .filter((item) => item !== 'Краснодарский')
          //   .filter((item) => item !== 'Адыгея'),
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9QRBV4T9',
      id: 'wifi_switch',
      name: 'Выключатель беспроводной',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
      //INFO: временно отключил
      // availableRegions: regions
      //   .filter((item) => item !== 'Свердловская')
      //   .filter((item) => item !== 'Саратовская')
      //   .filter((item) => item !== 'Ростовская')
      //   .filter((item) => item !== 'Краснодарский')
      //   .filter((item) => item !== 'Адыгея'),
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9QRMT1SF',
      id: 'camera',
      name: 'Камера наблюдения',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
      //INFO: временно отключил
      // availableRegions: regions
      //   .filter((item) => item !== 'Свердловская')
      //   .filter((item) => item !== 'Саратовская')
      //   .filter((item) => item !== 'Ростовская')
      //   .filter((item) => item !== 'Краснодарский')
      //   .filter((item) => item !== 'Адыгея'),
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9SJNW1YC',
      id: 'elviz_outside_camera',
      name: 'Ezviz внешняя камера',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
      //INFO: временно отключил
      // availableRegions: regions
      //   .filter((item) => item !== 'Свердловская')
      //   .filter((item) => item !== 'Саратовская')
      //   .filter((item) => item !== 'Ростовская')
      //   .filter((item) => item !== 'Краснодарский')
      //   .filter((item) => item !== 'Адыгея'),
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9SJNW1YV',
      id: 'elviz_inside_camera',
      name: 'Ezviz внутренняя камера',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
      //INFO: временно отключил
      // availableRegions: regions
      //   .filter((item) => item !== 'Свердловская')
      //   .filter((item) => item !== 'Саратовская')
      //   .filter((item) => item !== 'Ростовская')
      //   .filter((item) => item !== 'Краснодарский')
      //   .filter((item) => item !== 'Адыгея'),
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9QRMT1T1',
      id: 'central_module',
      name: 'Центральный модуль',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9SJ0HMU5',
      id: 'smart_wet',
      name: 'Умный увлажнитель',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9SJ0HMP4',
      id: 'smart_weight_mi_body',
      name: 'Умные весы 1 Mi Body',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9SJ0HMRJ',
      id: 'smart_weight_mi_smart',
      name: 'Умные весы 2 Mi Smart',
      usage: [
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'smartDevices',
      icon: 'i-dp-router',
      offerId: '1-9SJ0HMVQ',
      id: 'soap',
      name: 'Диспенсер для мыла + блок с мылом',
      usage: [
        { name: 'Продажа', id: 'sell', min: 1, max: 10, required: true },
        { name: 'Аренда', id: 'rent', min: 1, max: 10, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    // {
    //   group: 'smartDevices',
    //   icon: 'i-dp-router',
    //   id: 'security',
    //   name: 'Охранная система',
    //   usage: [{ name: 'Продажа', id: 'sell', min: 1, max: 1 }],
    //   price: 0,
    //   unit: 'шт',
    //   availableRegions: [
    //     'Санкт-Петербург',
    //     'Ленинградская',
    //     'Свердловская',
    //     'Краснодарский',
    //   ],
    // },
  ],
};

export const additionalSim: EquipmentGroup = {
  groupName: 'SIM-карта',
  type: 'additionalSim',
  equipments: [
    {
      group: 'additionalSim',
      icon: 'i-dp-router',
      offerId: '1-9QHCX44E',
      id: 'new-sim',
      name: 'Новая SIM',
      usage: [
        {
          id: 'sendsim',
          name: 'Передача Sim-карты',
          min: 1,
          max: 6,
          required: false,
          createOrderId: '1-9YGKQUOB',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'additionalSim',
      icon: 'i-dp-router',
      offerId: '1-9QHCX441',
      id: 'mnp',
      name: 'Переход MNP',
      usage: [
        {
          id: 'mnp',
          name: 'MNP',
          min: 1,
          max: 6,
          required: false,
          createOrderId: '1-9YGKQUOB',
        },
      ],
      price: 0,
      unit: 'шт',
    },
  ],
};

export const service: EquipmentGroup = {
  groupName: 'Сервис и услуги',
  type: 'service',
  equipments: [
    {
      group: 'service',
      icon: 'i-dp-description',
      id: 'paymentSHPD',
      name: 'Плата за подключение',
      usage: [
        { name: 'Платеж', id: 'payment', min: 1, max: 1, required: false },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      id: 'router_setup_not_mts',
      name: 'Настройка роутера НЕ от МТС',
      usage: [
        { name: 'Платеж', id: 'payment', min: 1, max: 1, required: false },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-9QHCX3Z1',
      id: 'high_speed_inet',
      name: 'Переключение на высокоскоростной ТП',
      usage: [
        { name: 'Бесплатно', id: 'free', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-9QHCX43P',
      id: 'cable_set',
      name: 'Платные работы: Прокладка кабеля',
      usage: [
        { name: 'Платеж', id: 'payment', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-9QHCX3ZP',
      id: 'convergent_connect',
      name: 'Объединение в конвергент',
      usage: [
        { name: 'Бесплатно', id: 'free', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-9QHCX40D',
      id: 'kion_setup',
      name: 'Настройка KION',
      usage: [
        { name: 'Бесплатно', id: 'free', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-9QHCX42P',
      id: 'hard_setup_PTV',
      name: 'Сложное подключение ПТВ',
      usage: [
        { name: 'Бесплатно', id: 'free', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-9QHCX401',
      id: 'remove_equipment',
      name: 'Вывоз/Демонтаж оборудования',
      usage: [
        { name: 'Бесплатно', id: 'free', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-A0OZEZNB',
      id: 'setup_tv_set_23-43',
      name: 'Платные работы: Установка Телевизора D24-43',
      usage: [
        { name: 'Платеж', id: 'payment', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-A0OZEZR7',
      id: 'setup_tv_set_50-65',
      name: 'Платные работы: Установка Телевизора D50-65',
      usage: [
        { name: 'Платеж', id: 'payment', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-A9PZXZPP',
      id: 'tech_change',
      name: 'Смена технологии',
      usage: [
        { name: 'Бесплатно', id: 'free', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'service',
      icon: 'i-dp-description',
      offerId: '1-A9PZXZPN',
      id: 'newAddress',
      name: 'Переезд на новый адрес',
      usage: [
        { name: 'Бесплатно', id: 'free', min: 1, max: 1, required: true },
      ],
      price: 0,
      unit: 'шт',
      promoPlaceholder: 'Номер ЛС',
      promoLength: 12,
      promoIcon: 'i-dp-description',
    },
  ],
};

export const conditioner: EquipmentGroup = {
  groupName: 'Кондиционер',
  limit: 1,
  type: 'conditioner',
  equipments: [
    {
      group: 'conditioner',
      icon: 'i-dp-router',
      offerId: '1-A9PZXZNH',
      id: 'conditioner_m20',
      name: 'Кондиционер М20',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'conditioner',
      icon: 'i-dp-router',
      offerId: '1-A9PZXZNJ',
      id: 'conditioner_m25',
      name: 'Кондиционер М25',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'conditioner',
      icon: 'i-dp-router',
      offerId: '1-A9PZXZPJ',
      id: 'conditioner_m35',
      name: 'Кондиционер М35',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
    {
      group: 'conditioner',
      icon: 'i-dp-router',
      offerId: '1-A9PZXZPL',
      id: 'conditioner_m50',
      name: 'Кондиционер М50',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 24 мес.',
          id: 'installment_24',
          min: 1,
          max: 1,
          period: 24,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
  ],
};

export const robot: EquipmentGroup = {
  groupName: 'Пылесос',
  limit: 1,
  type: 'robot',
  equipments: [
    {
      group: 'robot',
      icon: 'i-dp-router',
      offerId: '1-AC057F6Z',
      id: 'robot-blowjob',
      name: 'Робот-пылесос',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 12 мес.',
          id: 'installment_12',
          min: 1,
          max: 1,
          period: 12,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
  ],
};

export const smartphones: EquipmentGroup = {
  groupName: 'Смартфон',
  limit: 1,
  type: 'smartphones',
  equipments: [
    {
      group: 'smartphones',
      icon: 'i-dp-router',
      offerId: '1-AC057F71',
      id: 'smartphone',
      name: 'Смартфон',
      usage: [
        {
          name: 'Продажа',
          id: 'sell',
          min: 1,
          max: 1,
          required: true,
          createOrderId: '1-A69G0QZG',
        },
        {
          name: 'Рассрочка 12 мес.',
          id: 'installment_12',
          min: 1,
          max: 1,
          period: 12,
          required: true,
          createOrderId: '1-A69G0QZI',
        },
      ],
      price: 0,
      unit: 'шт',
    },
  ],
};

export const equipments: EquipmentGroup[] = [
  routers,
  cameras,
  tvEquipments,
  additionalSim,
  tvSets,
  conditioner,
  smartDevices,
  robot,
  smartphones,
  service,
];
