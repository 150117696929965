import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import ReactDOM from 'react-dom';
import { InfoModal, Modal, ProblemModal } from '..';
import { TextAreaDP } from '../../_ui';
import styles from './Rejection.module.scss';
import ButtonMTS from '../../_ui/ButtonMTS/ButtonMTS';
import { useAxios } from '../../../api/useAxios';
import { ApiErrorData } from '../../../types';
import Input, { InputWithMask } from '../../../components/_ui/Input/Input';
import { orderFormToNewRequest } from '../../OrderPipeline/components/utils';
import LoaderWithOverlay from '../../_ui/LoaderWithOverlay/LoaderWithOverlay';
import ErrorText from '../../_ui/ErrorText/ErrorText';
import { Constants } from '../../../constants';
import { capFirstLet } from '../../_ui/Input/Input.helper';
import { storeDP } from '../../../store/store';
import { CreateOrderReducerActions } from '../../../store/reducer/types/createOrderTypes';

type RejectionFields = {
  contactPhone: string;
  contactName: string;
  comment: string;
};

type Props = {
  onCloseClick: () => void;
};

const Rejection = (props: Props): JSX.Element => {
  const userSellPoint = storeDP.getState().userReducer.userSellPoint;

  const organization = storeDP.getState().userReducer.userOrganization;

  const {
    address: addressContext,
    orderForm: orderFormContext,
    orderData,
  } = storeDP.getState().createOrderReducer;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    control,
    watch,
  } = useForm<RejectionFields>({ mode: 'onChange' });

  const [error, setError] = useState<ApiErrorData>();
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const modalroot = document.getElementById('modal-root');

  useEffect(() => {
    if (orderData) {
      setValue('contactName', orderData.fio, {
        shouldDirty: true,
      });
      setValue('contactPhone', orderData.phone, {
        shouldDirty: true,
      });
      setValue('comment', orderData.comment, {
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFormContext]);

  useEffect(() => {
    const fio = watch('contactName')?.split(' ');
    if (orderFormContext?.client && fio) {
      if (fio?.length === 3) {
        orderFormContext.client.secondname = fio[2];
        orderFormContext.client.firstname = fio[1];
        orderFormContext.client.lastname = fio[0];
      } else {
        orderFormContext.client.firstname = fio[0];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('contactName')]);

  useEffect(() => {
    if (
      orderFormContext &&
      !orderFormContext?.address?.flat &&
      !orderFormContext?.isPrivate
    ) {
      const { isPrivate: _, ...isChangePrivate } = orderFormContext;

      storeDP.dispatch({
        type: CreateOrderReducerActions.SET_ORDER_FORM,
        payload: { isPrivate: true, ...isChangePrivate },
      });
    }
  });

  const { send: sendRejection, ...sendRejectionAxios } = useAxios(
    '/requests/actions/RejectRequest',
    'post'
  );

  useEffect(() => {
    if (sendRejectionAxios.fullResponse?.status === 200) {
      setLoader(false);
      setSuccess(true);
    }
    if (sendRejectionAxios.error) {
      setLoader(false);
      setError(sendRejectionAxios.error.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRejectionAxios.error, sendRejectionAxios.fullResponse]);

  const onSubmit = () => {
    if (orderFormContext && userSellPoint) {
      const values = getValues();
      values.contactName = capFirstLet(values.contactName);
      const request = orderFormToNewRequest(
        orderFormContext,
        userSellPoint,
        undefined,
        values,
        organization
      );

      setLoader(true);

      void sendRejection(request);
    }
  };

  const onClose = () => {
    setSuccess(false);
    props.onCloseClick();
    setValue('contactName', '', { shouldDirty: true });
    setValue('contactPhone', '', { shouldDirty: true });
    setValue('comment', '', { shouldDirty: true });
    storeDP.dispatch({
      type: CreateOrderReducerActions.SET_ORDER_DATA,
      payload: { fio: '', phone: '', comment: '' },
    });

    storeDP.dispatch({
      type: CreateOrderReducerActions.SET_ADDRESS,
      payload: undefined,
    });
    history.push('/new-order');
  };

  if (!modalroot) return <div />;

  return ReactDOM.createPortal(
    <>
      <Modal
        onCloseClick={props.onCloseClick}
        modalStyle={styles.modal}
        closeButtonStyle={styles.closeButtonStyle}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
          <h3 className={styles.header}>Зафиксировать отказ абонента</h3>
          <h4 className={styles.subtitle}>
            Внесите данные, если при оформлении заявки нет технической
            возможности подключения или клиент отказался по иной причине
          </h4>

          <Input
            disabled={!addressContext?.house}
            text="Имя или ФИО"
            name="contactName"
            register={register}
            required={{
              required: Constants.MESSAGE_INPUT_REQUIRED,
              pattern: {
                value: /(^[А-Яа-яё -]{1,100}$)/,
                message: 'Используйте кириллицу, дефис, пробелы',
              },
              minLength: {
                value: 2,
                message: 'Минимальное количество символов: 2',
              },
            }}
            testid="rejection-contact-name"
            errors={errors}
            tabiIndex={2}
            labelStyle={styles.label}
            inputStyle={`${styles.inputMask} ${styles.capitalize} ${
              errors.contactName ? styles.inputMask_error : ''
            }`}
          />

          <InputWithMask
            disabled={!addressContext?.house}
            text="Телефон"
            name="contactPhone"
            type="tel"
            mask="+7 999 999-99-99"
            maskPlaceholder=""
            register={register}
            required={{
              required: Constants.MESSAGE_INPUT_REQUIRED,
              pattern: {
                value: /\+7\s\d{3}\s\d{3}-\d{2}-\d{2}$/,
                message: 'Телефон должен быть в формате +7 999 123-45-67',
              },
            }}
            testid="rejection-contact-phone"
            errors={errors}
            placeHolder="+7"
            tabiIndex={2}
            labelStyle={styles.label}
            inputStyle={`${styles.inputMask} ${
              errors.contactPhone ? styles.inputMask_error : ''
            }`}
          />

          <div className={styles.textarea}>
            <label className={styles.textarea__label}>Комментарий *</label>
            <div>
              <Controller
                name="comment"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextAreaDP
                    disabled={!addressContext?.house}
                    textinputStyle={styles.textareaDP__input}
                    textareaStyle={`${styles.textareaDP} ${
                      errors.comment ? styles.textareaDP_error : ''
                    }`}
                    tabindex={3}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    rows={6}
                    testid="rejection-comment"
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
            </div>

            {errors.comment ? (
              <div
                className={styles.textarea__error}
                data-testid="rejection-comment-error"
              >
                <ErrorText>Обязательное поле для заполнения</ErrorText>
              </div>
            ) : (
              <div className={styles.textarea__error} />
            )}
          </div>

          {addressContext?.house ? (
            <div
              className={styles.ptv}
              data-testid="rejection-ptv"
            >
              {addressContext.fullAddress}
            </div>
          ) : (
            <div
              className={`${styles.ptv} ${styles.ptv_error}`}
              data-testid="rejection-ptv-error"
            >
              Необходимо произвести поиск технической возможности по адресу дома
            </div>
          )}

          <ButtonMTS
            disabled={!addressContext?.house}
            testid="rejection-send"
            type="submit"
          >
            Отправить
          </ButtonMTS>
        </form>
      </Modal>

      {loader && <LoaderWithOverlay />}

      {error && (
        <ProblemModal error={error} onCloseClick={() => setError(undefined)} />
      )}

      {success && (
        <InfoModal
          content={{
            header: 'Данные об отказе успешно отправлены',
            iconStyle: `i-dp-done ${styles.infoModal__icon}`,
            buttonText: 'Закрыть',
            buttonStyle: `${styles.infoModal__button}`,
          }}
          onCloseClick={onClose}
          onButtonClick={onClose}
        />
      )}
    </>,
    modalroot
  );
};

export default Rejection;
