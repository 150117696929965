import { Address } from '../../../api/AddressApi';
import { DoubleDate } from '../../../types';

export enum OrderListReducerActions {
  SET_PAGE_INDEX = 'SET_PAGE_INDEX',
  SET_DATES_CREATED = 'SET_DATE_CREATED',
  SET_DATES_CLOSE = 'SET_DATE_CLOSE',
  SET_DATE_EXPECTED_FINISHED = 'SET_DATE_EXPECTED_FINISHED',
  SET_STATUS = 'SET_STATUS',
  SET_SELLPOINT = 'SET_SELLPOINT',
  SET_ADDITIONAL_SELPOINT = 'SET_ADDITIONAL_SELPOINT',
  SET_ORDER_ID = 'SET_ORDER_ID',
  SELECT_USER = 'SELECT_USER',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_COMPLETION_STATE = 'SET_COMPLETION_STATE',
  CHECK_OPEN_SR = 'CHECK_OPEN_SR',
  CHECK_OPEN_CONTRACT = 'CHECK_OPEN_CONTRACT',
  SET_STATE = 'SET_STATE',
  RESET = 'RESET',
}

export type OrderListFilters = {
  pageIndex: number;
  datesCreated: DoubleDate;
  datesClosed: DoubleDate;
  datesExpectedFinished: DoubleDate;
  status?: string[];
  sellPoint: string;
  additionalSellpoint?: string;
  orderId?: string;
  selectedUser?: string;
  address?: Address;
  completionState?: string[];
  openSRCheck?: string[];
  openContractCheck?: string[];
};

interface SetPageIndex {
  type: OrderListReducerActions.SET_PAGE_INDEX;
  payload: number;
}

interface SetDatesCreated {
  type: OrderListReducerActions.SET_DATES_CREATED;
  payload: DoubleDate;
}

interface SetDatesClose {
  type: OrderListReducerActions.SET_DATES_CLOSE;
  payload: DoubleDate;
}

interface SetDatesExpectedFinished {
  type: OrderListReducerActions.SET_DATE_EXPECTED_FINISHED;
  payload: DoubleDate;
}

interface SetStatus {
  type: OrderListReducerActions.SET_STATUS;
  payload: string[];
}

interface SetSellpoint {
  type: OrderListReducerActions.SET_SELLPOINT;
  payload: string;
}

interface SetAdditionalSellpoint {
  type: OrderListReducerActions.SET_ADDITIONAL_SELPOINT;
  payload: string;
}

interface SetOrderId {
  type: OrderListReducerActions.SET_ORDER_ID;
  payload: string;
}

interface SelectUser {
  type: OrderListReducerActions.SELECT_USER;
  payload: string;
}

interface SetAddress {
  type: OrderListReducerActions.SET_ADDRESS;
  payload: Address;
}

interface SetCompletionState {
  type: OrderListReducerActions.SET_COMPLETION_STATE;
  payload: string[];
}

interface SetState {
  type: OrderListReducerActions.SET_STATE;
  payload: OrderListFilters;
}

interface CheckOpenSR {
  type: OrderListReducerActions.CHECK_OPEN_SR;
  payload: string[];
}

interface CheckOpenContract {
  type: OrderListReducerActions.CHECK_OPEN_CONTRACT;
  payload: string[];
}

interface Reset {
  type: OrderListReducerActions.RESET;
  payload: OrderListFilters;
}

export type OrderListActions =
  | SetPageIndex
  | SetDatesCreated
  | SetDatesClose
  | SetDatesExpectedFinished
  | SetStatus
  | SetSellpoint
  | SetAdditionalSellpoint
  | SetOrderId
  | SelectUser
  | SetAddress
  | SetCompletionState
  | SetState
  | CheckOpenSR
  | CheckOpenContract
  | Reset;
