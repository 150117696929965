import React from 'react';
import styles from '../MySalesModal/MySalesModal.module.scss';

const MySalesNoResults = (): JSX.Element => {
  return (
    <div className={styles.noResults}>
      <span className={styles.noResults__text}>
      В настоящее время информация о плане продаж доступна только для пользователей Inventory.
      Доступ выдается при трудоустройстве ряду каналов продаж, в связи с особенностями трудовой деятельности.
      </span>
      <span className={styles.noResults__text}>
      Для отображения плана продаж вам необходимо:
      </span>
      <span className={styles.noResults__text}>
      <ol>
        <li>Быть зарегистрированным в Inventory</li>
        <li>Ввести номер телефона в свою учетную запись в Дилерском портале</li>
        <li>Ввести номер телефона в свою учетную запись в Inventory</li>
        <li>Убедиться, что номера телефонов в Inventory и Дилерском портале совпадают</li>
      </ol>
      </span>
    </div>
  );
};

export default MySalesNoResults;
